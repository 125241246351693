import React, {useState } from "react";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import Pagination from "../../components/Equipment/Pagination";
import AllProducts from "../../components/Products/AllProducts";
import ProductsSideBar from "../../components/Products/ProductsSideBar";


function Products() {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState(10);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none">
        <AllProducts/>
        </div>
      </div>
    </div>
  );
}

export default Products;
