import React, { useState } from "react";
import InputField from "../components/micro_components.js/InputField";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../components/Sidebar";
import { SignedHeader } from "../components/SignedHeader";
import GhanttChart from "../components/micro_components.js/GhanttChart";

function Proposal() {
  const navigate = useNavigate();

  return (
    <div>
      <SignedHeader />
      <div className="page-container flex">
        <Sidebar />
        <div className="page-container flex">{/* <GhanttChart /> */}</div>
      </div>
    </div>
  );
}

export default Proposal;
