import React, { useState ,useContext} from "react";
import ReactDOM from "react-dom";
import SidebarDropdown from "./micro_components.js/SidebarDropdown";
import SidebarItem from "./micro_components.js/SideBarItem";
import { BiArrowToLeft,BiArrowFromLeft } from "react-icons/bi";
import SelectController from "./micro_components.js/SelectController";
import { MyContext } from "../context/MyContext";






const LogoutModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="w-[40%] bg-white p-4 rounded shadow-lg">
        <h2 className="text-lg font-bold mb-4">Confirm Logout</h2>
        <p>Are you sure you want to log out?</p>
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white p-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="bg-red-500 text-white p-2 rounded"
          >
            Logout
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

// Sidebar Component
const Sidebar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { projects,projectUpdate,setProjectUpdate } = useContext(MyContext);
  const [selectedprojectId, setSelectedProjectId] = useState(() => {
    return localStorage.getItem("selectedProjectId") || "";
  });

  const getActiveStatus = (path) => {
    return window.location.pathname === path;
  };

  const handleLogOut = () => {
    setIsModalOpen(true);
  };

  const handleConfirmLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const sidebarItems = [
    {
      icon: "icon-dashboard.svg",
      label: "Dashboard",
      link: "/dashboard",
      isActive: getActiveStatus("/dashboard"),
    },
    {
      icon: "calculator-svgrepo-com.svg",
      label: "Estimate",
      link: "/estimate",
      isActive: getActiveStatus("/estimate"),
    },
    {
      icon: "proposal.svg",
      label: "Proposal",
      link: "/proposal",
      isActive: getActiveStatus("/proposal"),
    },
    {
      icon: "project.svg",
      label: "Schedule",
      link: "/WbsList",
      isActive: getActiveStatus("/WbsList"),
    },
    {
      icon: "project.svg",
      label: "Project",
      link: "/projects",
      isActive: getActiveStatus("/projects"),
    },
    {
      icon: "invoices.svg",
      label: "Invoices",
      link: "/invoices",
      isActive: getActiveStatus("/invoices"),
    },
    {
      icon: "invoices.svg",
      label: "Equipment",
      link: "/equipment",
      isActive: getActiveStatus("/equipment"),
    },
    { icon: "products.svg", label: "Products", link: "/products" },
    { icon: "assets.svg", label: "Assets", link: "/assets" },
    { icon: "headoffice.svg", label: "Head Office", link: "/head-office" },
    { icon: "products.svg", label: "Activities", link: "/activites" },
    {
      icon: "document.svg",
      label: "Document",
      link: "/documents",
      isActive: getActiveStatus("/documents"),
    },
    { icon: "todolist.svg", label: "To-Do List", link: "/to-do-list" },
    { icon: "reports.svg", label: "Reports", link: "/reports" },
    { icon: "logs.svg", label: "Logs", link: "/logs" },
    { icon: "help.svg", label: "Help", link: "/help" },
    {
      icon: "miscallenous.svg",
      label: "Miscellaneous",
      link: "/miscellaneous",
    },

  ];

  return (
    <nav
      className={`bg-white min-h-screen border transition-all duration-700 relative ${
        isCollapsed ? "w-16" : "w-1/5"
      }`}
    >
       {!isCollapsed && (
          <div className="w-[80%] ml-5 mt-3">
          <SelectController
            name="pj_id"
            required
            selectOptions={projects}
            value={selectedprojectId}
            onChange={(e) => {setSelectedProjectId(e.target.value)
              localStorage.setItem("selectedProjectId", e.target.value);
              setProjectUpdate(!projectUpdate);
            }}
          />
          </div>         
        )}
      <div
        className="p-2 border w-9 h-9 mt-4 -right-5 top-2 rounded-full cursor-pointer bg-gray-200 hover:bg-gray-300 flex items-center justify-center absolute border-gray-300"
        onMouseEnter={toggleSidebar}
      >
        {isCollapsed ? (
          <span className="MuiIconButton-label">
           <BiArrowToLeft size={15} />
          </span>
        ) : (
          <span className="MuiIconButton-label">
           <BiArrowFromLeft size={15}/>
          </span>
        )}
      </div>
      {/* {!isCollapsed && <SidebarDropdown />} */}
      <hr className="" />
      <ul>
        {sidebarItems.map((item, index) => (
          <SidebarItem
            key={index}
            icon={item.icon}
            label={!isCollapsed ? item.label : ""}
            link={item.link}
            isActive={item.isActive}
          />
        ))}
        <div>
          <button
            onClick={handleLogOut}
            className="bg-[#9aa8c2] text-white p-2 rounded w-full mt-5 flex items-center justify-center gap-2 m-auto"
          >
            <img src="/icons/logout.svg" alt="logout" />
            {!isCollapsed && "Log Out"}
          </button>
        </div>
      </ul>
      <LogoutModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleConfirmLogout}
      />
    </nav>
  );
};

export default Sidebar;
