import React from "react";
import "../App.css";

export const Footer = () => {
  return (
    <footer className="footer flex">
      <div className="w-7 flex-column">
        <p>Copyright © 2023 | Demo Engineering Company</p>
      </div>
      <button
        onClick={() => {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }}
        id="top"
        title="Top"
      >
        <span className="material-symbols-outlined">expand_less</span>
      </button>
    </footer>
  );
};
