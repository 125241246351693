import React from "react";

function InputField({ label, name, type, placeholder, value, onChange }) {
  return (
    <div className="input-cn">
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

export default InputField;
