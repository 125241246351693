import React, { useState } from "react";
import "../../index.css";
import { Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Sidebar from "../../components/Sidebar";

import { SignedHeader } from "../../components/SignedHeader";

function DashboardPage() {
  return (
    <div>
      <SignedHeader />
      <div className="page-container flex">
        <Sidebar />
        <div className="page-container flex">This is a dashboard page</div>
      </div>
    </div>
  );
}

export default DashboardPage;