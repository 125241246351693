import React, { useState, useEffect, useRef } from "react";
//import { toast } from "react-toastify";
import ImportModal from "../Estimate/ImportModal";
import "../../App.css";
const apiUrl = process.env.REACT_APP_API_URL;
export const Header = (props) => {
  const path = window.location.pathname.split("/")[1];
  //const [showOptions, setShowOptions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setMatrialSidebarOpen = props.setMatrialSidebarOpen;
  const divEl = useRef();
  // useEffect(() => {
  //   const handler = (event) => {
  //     if (!divEl.current) {
  //       return;
  //     }
  //     if (!divEl.current.contains(event.target)) {
  //       setShowOptions(false);
  //     }
  //   };
  //   document.addEventListener("click", handler, true);
  //   return () => {
  //     document.removeEventListener("click", handler);
  //   };
  // }, []);
  return (
    <header className="header" id="home">
      <div className="w-full  m-auto flex space-between p-2">
        <a href="#" className="logo">
          <div className="flex items-center justify-center space-x-3 gap-3">
            <div className="flex justify-center items-center gap-2">
              <div className="text-center text-[32px] font-bold capitalize p-2">
                {path}
              </div>
            </div>
          </div>
        </a>
        <div className="menu flex space-between justify-center items-center gap-2">
          <div className="relative">
            <div
              className="m min-h-[36px] text-center flex justify-center items-center gap-1 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer"
              onClick={() => setMatrialSidebarOpen(true)}
            >
              <div>
                <img
                  className="mb-1"
                  height={15}
                  width={15}
                  src="icons/add.svg"
                  alt=""
                ></img>
              </div>
              <div className="h-[12px] mb-2">Add Equipment Usage</div>
            </div>
          </div>

          <div className="relative bottom-6 right-0">
            {/* {showOptions && (
              <div
                //onClick={() => setMatrialSidebarOpen(true)}
                ref={divEl}
                className=" cursor-pointer absolute top-11 z-[21] right-12 bg-white border shadow-md rounded-md p-3 w-28 h-22"
              >
                <div className="flex items-center gap-1 min-w-full">
                  <span className="text-[12px] mt-1 font-bold hover:bg-blue-500 hover:text-white cursor-pointer ">
                    Add Equipment
                  </span>
                </div>
                <hr className="w-full border border-gray-300"></hr>
              </div>
            )} */}
          </div>
        </div>
      </div>
      <ImportModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </header>
  );
};
