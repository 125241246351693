import React, { useState, useContext, useEffect } from "react";
import "../../index.css";
import { toast } from "react-toastify";
import EntryIcon from "../../assets/icons/entry.svg";
import { MyContext } from "../../context/MyContext";
import { useParams } from "react-router-dom";
import SelectController from "../micro_components.js/SelectController";

const apiUrl = process.env.REACT_APP_API_URL;

const AddEquipmentUsage = ({ setMatrialSidebarOpen, handleCloseModal ,selectedId ,setSelectedId}) => {
  const { projects , setDataUpdated ,dataUpdated ,equipmentsData} = useContext(MyContext);
  const [milestones, setMilestones] = useState([]);

  const [formValues, setFormValues] = useState({
    project_id: "",
    milestone_id: "",
    wbs: "",
    equipment_id: "",
    equipmentsupplier: "",
    startDate: "",
    endDate: "",
    rentalfrequency: "",
    rentalrate: "",
    rentalcost: "",
    description: "",
    notes: "",
    attachments: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

 useEffect(() => {
      const fetchMilstone = async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token");
        const raw = JSON.stringify({
          user_id: user.user_id,
          strict: 1,
          pj_id: formValues.project_id,
        });

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
          body: raw,
          redirect: "follow",
        };

        try {
          const response = await fetch(
            `${apiUrl}/app/panel/milestones/get-milestone`,
            requestOptions
          );
          const result = await response.json();
          if (result.success) {
            const transformData = result.data?.map((data) => ({
              id: data.id,
              name: data.name
            }));
            console.log("++++++++++",result.data)
            console.log(">>>>>>>>>>",transformData)
            setMilestones(transformData);
            setDataUpdated((pre)=>!pre)
          } else {
            // toast.error(result.error);
            console.log(result.error)
          }
        } catch (error) {
          // toast.error("Failed to fetch milestones");
          console.error(error);
        }
      };

      fetchMilstone();
    
  }, [formValues.project_id]);

  useEffect(()=>{
    if(selectedId){
      fetchSinglEquipment()
    }
  },[])

  const fetchSinglEquipment = async () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      user_id: user.user_id,
      strict: 1,
      id: selectedId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/project-tracking/equipment-usage/get-equipment-usage`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch product");
      }

      const result = await response.json();
      if (result.success) {
        setFormValues(result.data[0]);
        console.log("=========", result.data[0]);
      } else {
        toast.error("Failed to retrieve data");
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      toast.error("An error occurred while fetching product");
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const formdata = new FormData();

    formdata.append("user_id", user.user_id);
    formdata.append("pj_id", formValues.project_id);
    formdata.append("milestone_id", formValues.milestone_id);
    formdata.append("wbs_id", formValues.wbs);
    formdata.append("equipment_name", formValues.equipment_id);
    formdata.append("supplier_id", formValues.equipmentsupplier);
    formdata.append("rental_start_date", formValues.startDate);
    formdata.append("rental_end_date", formValues.endDate);
    formdata.append("rental_unit", formValues.rentalfrequency);
    formdata.append("rental_rate", formValues.rentalrate);
    formdata.append("rental_cost", formValues.rentalcost);
    formdata.append("description", formValues.description);
    formdata.append("notes", formValues.notes);
    formdata.append("attachments[]", formValues.attachments);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/project-tracking/equipment-usage/add-equipment-usage`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add equipment usage");
      }
      const result = await response.json();
      if (result.success) {
        toast.success("Equipment usage added successfully");
        setMatrialSidebarOpen(false);
        setSelectedId(null)
        setDataUpdated(!dataUpdated)
        setFormValues({
          project: "",
          milestone: "",
          wbs: "",
          equipment: "",
          equipmentsupplier: "",
          startDate: "",
          endDate: "",
          rentalfrequency: "",
          rentalrate: "",
          rentalcost: "",
          description: "",
          notes: "",
          attachments: null,
        });
      } else {
        toast.error(result.error || "Failed to add equipment usage");
      }
    } catch (error) {
      console.error("Error adding equipment usage:", error);
      toast.error("An error occurred while adding equipment usage");
    }
  };


  return (
    <div id="main" className="flex">
      <div className="bg-white shadow-md overflow-auto h-[100vh] rounded-md p-4 flex-1">
        <div id="header" className="pb-7 flex justify-between items-center">
          <div className="flex items-center gap-1">
            <img src={EntryIcon} alt="Entry Icon" />
            <span className="text-lg font-bold mt-2"> {selectedId ? "Update" :"Add "} Equipment Usage {selectedId}</span>
          </div>
          <div className="flex gap-1">
            <img
              src="icons/menu.svg"
              className="cursor-pointer"
              alt="Menu Icon"
            />
            <img
              src="icons/cross.svg"
              className="cursor-pointer"
              alt="Cross Icon"
              onClick={() =>{ setMatrialSidebarOpen(false)
                setSelectedId(null)
              
              }}
            />
          </div>
        </div>
        <div className="border border-b-gray-300 mb-4"></div>
        <form onSubmit={handleSubmit}>
          {/* <div className="mb-4">
            <label
              htmlFor="project"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Project
            </label>
            <select
              id="project"
              name="project"
              value={formValues.project}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            >
              <option value="" disabled>
                Select a project
              </option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div> */}
          {/* <div className="mb-4">
            <label
              htmlFor="milestone"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Milestone
            </label>
            <select
              id="milestone"
              name="milestone"
              value={formValues.milestone}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              disabled={!formValues.project} // Disable until project is selected
            >
              <option value="" disabled>
                Select a milestone
              </option>
              {milestones.map((milestone) => (
                <option key={milestone.id} value={milestone.id}>
                  {milestone.name}
                </option>
              ))}
            </select>
          </div> */}
          <SelectController className="mb-4"
                      label="Project"
                      name="project_id"
                      required={true}
                      selectOptions={projects}
                      value={formValues.project_id}
                      onChange={handleChange}
           />
          <SelectController className="mb-4"
                      label="Milestone"
                      name="milestone_id"
                      required={true}
                      selectOptions={milestones}
                      value={formValues.milestone_id}
                      onChange={handleChange}
           />

          <div className="mb-4">
            <label
              htmlFor="wbs"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              WBS
            </label>
            <input
              type="number"
              id="wbs"
              name="wbs"
              value={formValues.wbs}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
         
         
          <SelectController className="mb-4"
                      label="Equipment"
                      name="equipment_id"
                      required={true}
                      selectOptions={equipmentsData}
                      value={formValues.equipment_id}
                      onChange={handleChange}
           />
          <div className="mb-4">
            <label
              htmlFor="equipmentsupplier"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Equipment Supplier
            </label>
            <input
              type="number"
              id="equipmentsupplier"
              name="equipmentsupplier"
              value={formValues.equipmentsupplier}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          {/* Remaining fields are unchanged */}

          <div className="mb-4">
            <label
              htmlFor="startDate"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Rental Start Date
            </label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={formValues.startDate}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="endDate"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Rental End Date
            </label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={formValues.endDate}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="rentalfrequency"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Rental Frequency
            </label>
            <select
              id="rentalfrequency"
              name="rentalfrequency"
              value={formValues.rentalfrequency}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            >
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              htmlFor="rentalrate"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Rental Rate
            </label>
            <input
              type="number"
              id="rentalrate"
              name="rentalrate"
              value={formValues.rentalrate}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="rentalcost"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Rental Cost
            </label>
            <input
              type="number"
              id="rentalcost"
              name="rentalcost"
              value={formValues.rentalcost}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Description
            </label>
            <input
              id="description"
              name="description"
              value={formValues.description}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="notes"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Notes
            </label>
            <input
              id="notes"
              name="notes"
              value={formValues.notes}
              onChange={handleChange}
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="attachments"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Attachments
            </label>
            <input
              type="file"
              id="attachments"
              name="attachments"
              onChange={(e) =>
                setFormValues({ ...formValues, attachments: e.target.files[0] })
              }
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <button
            type="submit"
            className="mt-10 mb-6 w-full bg-primary text-white font-medium py-2 px-4 rounded hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            {selectedId ? "Update" :"Add "} Equipment Usage
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEquipmentUsage;
