import React from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      className="flex items-center gap-1 hover:bg-slate-200 px-2 rounded-md cursor-pointer"
    >
      <span {...attributes} {...listeners} className="drag-handle">
        <img src="icons/bar.svg" alt="Drag Handle" />
      </span>
      {children}
    </li>
  );
};

const TableDataReorders = ({
  tableHeaders,
  sensors,
  handleDragEnd,
  handleCheckBox,
}) => {
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={tableHeaders.map((header) => header.value)}>
        <ul className="my-1">
          {tableHeaders.map((header) => (
            <SortableItem key={header.value} id={header.value}>
              <input
                id={header.value}
                type="checkbox"
                checked={header.visible}
                onChange={(e) => handleCheckBox(e, header.value)}
                style={{ pointerEvents: "auto" }}
                className="w-3 h-3 cursor-pointer text-primary bg-gray-100 border-gray-300 focus:ring-blue-500"
              />
              <span className="mt-1 text-[12px] capitalize">{header.name}</span>
            </SortableItem>
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
};

export default TableDataReorders;