// src/components/Banner.js
import React from "react";
import "../App.css";

export const Banner = () => {
  return (
    <section
      className="banner flex"
      id="home"
      style={{ backgroundImage: "url(images/banner.jpg)" }}
    >
      <div className="w-7 banner-content flex-column">
        <h1>Demo Engineering</h1>
        <h3>Construction Company</h3>
        <p>
          Transform your design into reality with our progress, pricing and
          expertise
        </p>
        <button className="btn btn-outline" href="contact">
          Chat on WhatsApp
        </button>
      </div>
    </section>
  );
};
