import React from 'react';
const EmptyProjects= () => {
  return (
    <div className=" bg-gray-100">
      <div className="bg-white shadow-md w-full h-[100vh] flex flex-col">
        <div className=""></div>
        <div className="flex-grow flex flex-col items-center justify-center text-center p-8">
          <img src="icons/mainproject.svg" alt="Project Icon" />
          <h2 className="text-xl font-semibold mb-2">There are no project items created yet</h2>
          <p className="text-gray-600 mb-6">
            Track and manage the progress of your construction project and mark off critical <br/> 
            milestones. View and manage schedules in GANTT or LIST view.
          </p>
          <div className="flex justify-center space-x-4">
            <button className="bg-white border border-blue-700 text-blue-700 py-2 px-4 rounded-md hover:bg-gray-300 flex gap-1">
              <img src='icons/import2.svg' alt=''></img>
              Import</button>
            <button className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 flex gap-1">
              <img src='icons/add.svg' alt='' className='w-[20px]'></img>
              Add Item</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyProjects;