import React, { useState } from "react";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

const Header = ({
  documents,
  setDocuments,
  parentFolderId,
  folderId,
  setFolderId,
  reloadDocuments,
  setReloadDocuments,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [isModalupload, setIsModalupload] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const path = window.location.pathname.split("/")[1];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleUploadModal = () => {
    setIsModalupload(true);
  };

  const handleCloseUploadModal = () => {
    setIsModalupload(false);
  };
  const handleCreateFolder = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=b8446925257e4bbe56440fd1f36bbae5");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      folder_name: folderName,
      parent_id: folderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/create-folder`, requestOptions)
      .then((response) => response.json()) // Assuming the response is in JSON format
      .then((result) => {
        console.log(result);
        if (result.success) {
          toast.success(result.message);
          const newFolder = {
            folder_id: Math.random(), // Use a temporary ID if the API doesn't return one
            folder_name: folderName,
            starred: 0,
          };
          setDocuments((prevDocuments) => [...prevDocuments, newFolder]);
          handleCloseModal();
        } else {
          toast.error(result.error);
        }
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again later.");
        console.error(error);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUploadFile = () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const myHeaders = new Headers();

    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    myHeaders.append("Cookie", "PHPSESSID=3f909ca2b757010f7e99430c91b52b4e");

    const formData = new FormData();
    formData.append("user_id", user.user_id);
    formData.append("files[]", selectedFile);
    formData.append("parent_id", folderId);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/upload-file`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          toast.success("File uploaded successfully!");
          setReloadDocuments(!reloadDocuments);
          handleCloseUploadModal();
        } else {
          toast.error(result.error);
        }
      })
      .catch((error) => {
        toast.error("An error occurred during file upload.");
        console.error(error);
      });
  };

  return (
    <>
      <header>
        <div className="flex justify-between items-center p-2 bg-white shadow capitalize">
          <h1 className="text-center text-2xl font-bold capitalize p-2">
            {path}
          </h1>
          <div className="flex gap-2">
            <button
              className="bg-white text-primary px-2 py-2 rounded-lg mr-2 border border-blue-500 flex items-center gap-1"
              onClick={handleOpenModal}
            >
              <img height={20} width={20} src="icons/add2.svg" alt="Add icon" />
              Create Folder
            </button>
            <button
              className="bg-primary text-white px-3 py-2 rounded-lg flex items-center gap-1 hover:bg-blue-900"
              onClick={handleUploadModal}
            >
              <img src="icons/upload.svg" alt="Upload icon" />
              Upload Files
            </button>
            <img
              className="max-h-[41px] p-2 bg-primary text-white font-bold rounded-lg hover:bg-blue-900 cursor-pointer"
              src="icons/clipboard.svg"
              alt="Clipboard icon"
            />
          </div>
        </div>
      </header>

      <hr></hr>

      {/* Create Folder Modal */}
      {isModalOpen && (
        <div
          className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50 ${
            isModalOpen ? "bg-gray-200" : "bg-white"
          }`}
        >
          <div className="w-[40%] bg-white p-4 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4 w-full border-b-gray-300">
              Create Folder
            </h2>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 font-bold mb-2"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleCreateFolder}
                className="flex gap-1 ml-4 bg-primary hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
              >
                <img src="icons/save.svg" alt=""></img>
                Save
              </button>
              <button
                onClick={handleCloseModal}
                className="flex gap-1 ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Upload Files Modal */}
      {isModalupload && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="w-[40%] bg-white p-4 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Upload Files</h2>
            <input type="file" onChange={handleFileChange} className="mb-4" />
            <div className="flex justify-end">
              <button
                onClick={handleUploadFile}
                className="flex gap-1 ml-4 bg-primary hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
              >
                <img src="icons/save.svg" alt=""></img>
                Upload
              </button>
              <button
                onClick={handleCloseUploadModal}
                className="flex gap-1 ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus-shadow-outline"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
