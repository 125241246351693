import React from 'react';
import InvoicesHeader from './InvoicesHeader';
import InvoicesRow from './InvoicesRow';

const AllInvoices = ({ data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border">
        <InvoicesHeader />
        <tbody>
          {data.map((item, index) => (
            <InvoicesRow key={index} item={item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllInvoices;
