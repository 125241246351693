export const Testimonials = () => {
  return (
    <section className="section grey-bg" id="testimonials">
      <div className="w-7 flex-column">
        <h2>Client Testimonials</h2>
        <div className="cards-container flex space-between">
          {[
            {
              name: "Hisham Ali",
              text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s. when an unknown printer scrambled a galley of type and scrambled it to make a type specimen book. It has survived.",
            },
            {
              name: "Ashar Fahad",
              text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s. when an unknown printer scrambled a galley of type and scrambled it to make a type specimen book. It has survived.",
            },
            {
              name: "Mohamed Ahmed",
              text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s. when an unknown printer scrambled a galley of type and scrambled it to make a type specimen book. It has survived.",
            },
          ].map((testimonial, index) => (
            <div className="card ttm white-bg" key={index}>
              <div className="abs-icon">
                <span className="material-symbols-outlined">format_quote</span>
              </div>
              <h3>{testimonial.name}</h3>
              <p>{testimonial.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
