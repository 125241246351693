// src/components/Projects.js
import React from "react";
import "../App.css";

export const Projects = () => {
  return (
    <section className="section" id="projects">
      <div className="w-7 flex-column">
        <h2>Our Projects</h2>
        <div className="cards-container flex space-between">
          <div className="card imgd">
            <img src="images/p1.png" alt="Project 1" />
          </div>
          <div className="card imgd">
            <img src="images/p2.png" alt="Project 2" />
          </div>
          <div className="card imgd">
            <img src="images/p3.png" alt="Project 3" />
          </div>
        </div>
      </div>
    </section>
  );
};
