import React from "react";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import InvoiceFormComponent from "../../components/Invoices/InvoiceFormComponent";
const InvoicesForm = () => {
  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none">
          <div className="container max-w-full bg-white rounded-lg">
            <InvoiceFormComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesForm;
