// src/components/WhatWeDo.js
import React from "react";
import "../App.css";

export const WhatWeDo = () => {
  return (
    <section className="section">
      <div className="w-7 flex-column">
        <h2>What We Do</h2>
        <div className="cards-container flex space-between">
          <div className="card">
            <span className="material-symbols-outlined">home</span>
            <h3>New Build Homes</h3>
            <p>
              Our extensive experience in building numerous homes further
              reinforces our commitment to delivering quality results.
            </p>
          </div>
          <div className="card">
            <span className="material-symbols-outlined">apartment</span>
            <h3>Commercial Construction</h3>
            <p>
              We are experts in commercial construction, providing turnkey
              solutions that address diverse business requirements.
            </p>
          </div>
          <div className="card">
            <span className="material-symbols-outlined">map</span>
            <h3>Property Development</h3>
            <p>
              We aim to develop exceptional properties that enhance communities,
              provide value to investors and users.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
