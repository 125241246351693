import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import { Header } from "../../components/Equipment/Header";
import SecondHeader from "../../components/Equipment/SecondHeader";
import Pagination from "../../components/Equipment/Pagination";
import OffcanvasSidebar from "../../components/Equipment/offcanvasSideBar";
import AllEquipments from "../../components/Equipment/AllEquipments";


function Equipment() {
  const [data, setData] = useState(null);
  const [materialsidebarOpen, setMatrialSidebarOpen] = useState(false);
  const [selectedId , setSelectedId] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState(10);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none">
          <Header setMatrialSidebarOpen={setMatrialSidebarOpen} />
          <SecondHeader data={data} setData={setData} />
          <AllEquipments setSelectedId={setSelectedId} setMatrialSidebarOpen={setMatrialSidebarOpen}/>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
          <OffcanvasSidebar
            materialsidebarOpen={materialsidebarOpen}
            setMatrialSidebarOpen={setMatrialSidebarOpen}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        </div>
      </div>
    </div>
  );
}

export default Equipment;
