import React, { useState,useRef ,useEffect ,useContext} from "react";
import { toast } from "react-toastify";
import { MyContext } from "../../context/MyContext";


const EquipmentRow = ({ item ,setSelectedId ,setMatrialSidebarOpen}) => {
  const [dropdownVisibleFor, setDropdownVisibleFor] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const {setDataUpdated ,dataUpdated} = useContext(MyContext);


  const divEl = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(event.target)) {
        setDropdownVisibleFor(false);
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);


  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      user_id: user.user_id,
      record_id: id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/project-tracking/equipment-usage/delete-equipment-usage`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Estimate deleted successfully");
        setDataUpdated(!dataUpdated)
        setDropdownVisibleFor(false);
      }

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Error deleting the question:", error);
    }
  };

  const handleDropdownToggle = (projectId) => {
    setDropdownVisibleFor((prev) => (prev === projectId ? null : projectId));
  };

  return (
    <tr className="border-b">
      <td className="px-4 py-3 text-sm">{item.id}</td>
      <td className="px-4 py-3 text-sm">{item.pj_id}</td>
      <td className="px-4 py-3 text-sm">{item.milestone_id}</td>
      <td className="px-4 py-3 text-sm">{item.wbs_id}</td>
      <td className="px-4 py-3 text-sm">{item.equipment_name}</td>
      <td className="px-4 py-3 text-sm">{item.supplier_id}</td>
      <td className="px-4 py-3 text-sm">{item.rental_start_date}</td>
      <td className="px-4 py-3 text-sm">{item.rental_end_date}</td>
      <td className="px-4 py-3 text-sm">{item.chargefrequency}</td>
      <td className="px-4 py-3 text-sm">{item.charge}</td>
      <td className="py-2 px-6 border-b border-gray-200 text-sm">
        <img
          src="icons/menu.svg"
          alt="Actions"
          className="cursor-pointer"
          onClick={() => handleDropdownToggle(item.id)}
        />
        {dropdownVisibleFor === item.id && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded" ref={divEl}>
            <ul>
              <li
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() =>{
                  setSelectedId(item.id);
                  setMatrialSidebarOpen(true)
                }}
              >
                Update
              </li>
              <li
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleDelete(item.id)}
              >
                Delete
              </li>
            </ul>
          </div>
        )}
      </td>
    </tr>
  );
};

export default EquipmentRow;
