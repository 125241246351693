import React, { useEffect, useState ,useContext} from "react";
import "../../index.css";
import EntryIcon from "../../assets/icons/entry.svg";
import InputController from "../micro_components.js/InputController";
import { toast } from "react-toastify";
import { MyContext } from "../../context/MyContext";
import SelectController from "../micro_components.js/SelectController";

function ProductsSideBar({
  productsSideBarIsOpen,
  setProductsSideBarIsOpen,
  selectedProductId,
  setSelectedProductId
}) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const { setDataUpdated ,dataUpdated, productCategoryList,supplierData} = useContext(MyContext);

  const [productValues, setProductValues] = useState({
    name: "",
    description: "",
    category_id: "",
    supplier_id: "",
    unit: "KG",
    price: "",
    attachments: "",
    notes: "",
  });

  const resetProductValues =()=>{
    setProductValues({
    name: "",
    description: "",
    category_id: "",
    supplier_id: "",
    unit: "KG",
    price: "",
    attachments: "",
    notes: "",
   })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductValues({
      ...productValues,
      [name]: value,
    });
  };

  useEffect(() => {
    if (selectedProductId) {
      fetchSingleProduct();
    }
  }, [selectedProductId]);

  const fetchSingleProduct = async () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      user_id: user.user_id,
      strict: 1,
      id: selectedProductId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/app/panel/products/get-product`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch product");
      }

      const result = await response.json();
      console.log(result);

      if (result.success) {
        setProductValues(result.data[0]);
        console.log("=========", result.data[0]);
      } else {
        toast.error("Failed to retrieve data");
      }

      console.log(result);
    } catch (error) {
      console.error("Error fetching product:", error);
      toast.error("An error occurred while fetching product");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data: ", productValues);

    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const formdata = new FormData();

    // Correctly append form data
    formdata.append("user_id", user?.user_id);
    formdata.append("name", productValues.name);
    formdata.append("description", productValues.description);
    formdata.append("category_id", productValues.category_id);
    formdata.append("supplier_id", productValues.supplier_id);
    formdata.append("unit", productValues.unit);
    formdata.append("price", productValues.price);
    formdata.append("notes", productValues.notes);
    if (productValues.attachments) {
      formdata.append("attachments[]", productValues.attachments);
    }

    if(selectedProductId){
      formdata.append("record_id", selectedProductId);
    }

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
      redirect: "follow",
    };

    try {
      let response;

      if (selectedProductId) {
        response = await fetch(
          `${baseUrl}/app/panel/products/update-product`,
          requestOptions
        );
      } else {
        response = await fetch(
          `${baseUrl}/app/panel/products/add-product`,
          requestOptions
        );
      }

      if (!response.ok) {
        throw new Error("Failed to add product");
      }
      const result = await response.json();
      if (result.success) {
        toast.success(`Product ${selectedProductId ? "update" : "added"} successfully`);
        setProductsSideBarIsOpen(false); 
        setSelectedProductId(null)
        setDataUpdated (!dataUpdated)
        resetProductValues();

     
       
      } else {
        toast.error(
          result.message || "An error occurred while adding the product"
        );
      }
    } catch (error) {
      console.error("Error adding product:", error);
      toast.error("An error occurred while adding the product");
    }
  };

  return (
    <div className="relative">
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${
          productsSideBarIsOpen ? "translate-x-0" : "translate-x-full"
        }`}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <div className="relative">
            <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
              <div id="main" className="flex">
                <div className="bg-white shadow-md overflow-auto h-[100vh] rounded-md p-4 flex-1">
                  <div
                    id="header"
                    className="pb-7 flex justify-between items-center"
                  >
                    <div className="flex items-center gap-1">
                      <img src={EntryIcon} alt="Entry Icon" />
                      <span className="text-lg font-bold mt-2">
                        {selectedProductId ? "Update" : "Add"} Product
                      </span>
                    </div>
                    <div className="flex gap-1">
                      <img
                        src="icons/menu.svg"
                        className="cursor-pointer"
                        alt="Menu Icon"
                      />
                      <img
                        src="icons/cross.svg"
                        className="cursor-pointer"
                        alt="Cross Icon"
                        onClick={() => {setProductsSideBarIsOpen(false)
                          setSelectedProductId(null)
                          resetProductValues();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border border-b-gray-300 mb-3"></div>
                  <form className="space-y-4" onSubmit={handleSubmit}>
                    <InputController
                      label="Product Name"
                      name="name"
                      value={productValues.name}
                      onChange={handleChange}
                      required={true}
                    />

                    <InputController
                      label="Description"
                      name="description"
                      value={productValues.description}
                      onChange={handleChange}
                      required={true}
                    />

                    <SelectController 
                    label="Category"
                    name="category_id"
                    required={true}
                    selectOptions={productCategoryList}
                    value={productValues.category_id}
                    onChange={handleChange}
                    />
                    <SelectController 
                      label="Supplier"
                      name="supplier_id"
                      required={true}
                      selectOptions={supplierData}
                      value={productValues.supplier_id}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Price"
                      name="price"
                      type="number"
                      value={productValues.price}
                      onChange={handleChange}
                      required={true}
                    />

                    <div className="">
                      <label
                        htmlFor="attachments"
                        className="block text-sm font-medium text-gray-700 mb-3"
                      >
                        Attachments
                      </label>
                      <input
                        type="file"
                        id="attachments"
                        name="attachments"
                        onChange={(e) =>
                          setProductValues({
                            ...productValues,
                            attachments: e.target.files[0],
                          })
                        }
                        className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <button
                      type="submit"
                      className="mt-10 mb-6 w-full bg-primary text-white font-medium py-2 px-4 rounded hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                      {selectedProductId ? "Update" : "Add"} Product
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsSideBar;
