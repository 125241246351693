// src/components/Header.js
import React, { useState } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { IoMdSettings } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

export const SignedHeader = (props) => {
  const path = window.location.pathname.split("/")[1];
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <header className="header border" id="home">
      <div className="w-[95%] m-auto flex space-between">
        <div className="flex justify-center items-center space-x-3 gap-5">
          <span className="font-bold text-3xl">Agab</span>
          <div className="flex justify-center items-center gap-2 border-b-2 border-primary">
            <div className="text-primary font-bold capitalize">
              {path}
              {/* <div className="font-bold border-b-2 border-primary capitalize mt-3"></div> */}
            </div>
            <IoIosArrowDown size={20} className="text-primary" />
          </div>
        </div>

        <span
          className="material-symbols-outlined"
          id="openSidebar"
          onClick={toggleSidebar}
        >
          menu
        </span>
        <div className="menu flex space-between">
          <div className="border rounded-md m-2 p-1 bg-gray-100">
            {/* <img width={25} src="icons/settings.svg" alt="Notification" /> */}
            <IoMdSettings size={30} />
          </div>
        </div>
      </div>
    </header>
  );
};
