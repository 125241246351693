import React, { useEffect, useState, useContext } from "react";
import "../../index.css";
import EntryIcon from "../../assets/icons/entry.svg";
import InputController from "../micro_components.js/InputController";
import { toast } from "react-toastify";
import { MyContext } from "../../context/MyContext";
import SelectController from "../micro_components.js/SelectController";

function SubcontractorSideBar({
  subcontractorSideBarIsOpen,
  setSubcontractorSideBarIsOpen,
  selectedSubcontractorId,
  setSelectedSubcontractorId,
}) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const {
    setDataUpdated,
    dataUpdated,
    projects,
  } = useContext(MyContext);
  const [milestones, setMilestones] = useState([]);
  
  const [subcontractorValues, setSubcontractorValues] = useState({
    pj_id: "",
    milestone_id: "",
    wbs_id: "",
    cost_code_id: "",
    description: "",
    role: "",
    notes: "",
    hours_worked: "",
    rate_per_hour: "",
    cost_amount: "",
    subcontractor_id: "",
    payment_id: "",
    internal_notes: "",
    attachments: [],
  });

  const resetSubcontractorValues = () => {
    setSubcontractorValues({
      pj_id: "",
      milestone_id: "",
      wbs_id: "",
      cost_code_id: "",
      description: "",
      role: "",
      notes: "",
      hours_worked: "",
      rate_per_hour: "",
      cost_amount: "",
      subcontractor_id: "",
      payment_id: "",
      internal_notes: "",
      attachments: [],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubcontractorValues({
      ...subcontractorValues,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    const updatedAttachments = Array.from(files);
    setSubcontractorValues({
      ...subcontractorValues,
      attachments: updatedAttachments,
    });
  };

  useEffect(() => {
    if (selectedSubcontractorId) {
      fetchSingleSubcontractor();
    }
  }, [selectedSubcontractorId]);

  const fetchSingleSubcontractor = async () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      user_id: user.user_id,
      strict: 1,
      id: selectedSubcontractorId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/app/panel/project-tracking/subcontractor-work-activity/get-subcontractor-work-activity`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch subcontractor");
      }

      const result = await response.json();
      console.log(result);

      if (result.success) {
        setSubcontractorValues(result.data[0]);
      } else {
        toast.error("Failed to retrieve data");
      }

      console.log(result);
    } catch (error) {
      console.error("Error fetching subcontractor:", error);
      toast.error("An error occurred while fetching subcontractor");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const formdata = new FormData();
    formdata.append("user_id", user?.user_id);
    formdata.append("pj_id", subcontractorValues.pj_id);
    formdata.append("milestone_id", subcontractorValues.milestone_id);
    formdata.append("wbs_id", subcontractorValues.wbs_id);
    formdata.append("cost_code_id", subcontractorValues.cost_code_id);
    formdata.append("description", subcontractorValues.description);
    formdata.append("role", subcontractorValues.role);
    formdata.append("notes", subcontractorValues.notes);
    formdata.append("hours_worked", subcontractorValues.hours_worked);
    formdata.append("rate_per_hour", subcontractorValues.rate_per_hour);
    formdata.append("cost_amount", subcontractorValues.cost_amount);
    formdata.append("subcontractor_id", subcontractorValues.subcontractor_id);
    formdata.append("payment_id", subcontractorValues.payment_id);


    if (
      Array.isArray(subcontractorValues.attachments) &&
      subcontractorValues.attachments.length > 0
    ) {
      for (let i = 0; i < subcontractorValues.attachments.length; i++) {
        formdata.append("attachments[1]", subcontractorValues.attachments[i]);
      }
    } else {
      formdata.append("attachments[]", "");
    }
    if (selectedSubcontractorId) {
      formdata.append("record_id", selectedSubcontractorId);
    }

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
      redirect: "follow",
    };

    try {
      let response;

      if (selectedSubcontractorId) {
        response = await fetch(
         `${baseUrl}/app/panel/project-tracking/subcontractor-work-activity/update-subcontractor-work-activity`,
          requestOptions
        );
      } else {
        response = await fetch(
          `${baseUrl}/app/panel/project-tracking/subcontractor-work-activity/add-subcontractor-work-activity`,
          requestOptions
        );
      }

      if (!response.ok) {
        throw new Error("Failed to add subcontractor");
      }
      const result = await response.json();
      if (result.success) {
        toast.success(
          ` Subcontractor ${
            selectedSubcontractorId ? "update" : "added"
          } successfully`
        );
        setSubcontractorSideBarIsOpen(false);
        setSelectedSubcontractorId(null);
        setDataUpdated(!dataUpdated);
        resetSubcontractorValues();
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      console.error("Error adding subcontractor:", error);
      toast.error("An error occurred while adding the subcontractor");
    }
  };
  useEffect(() => {
    const fetchMilstone = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
      const raw = JSON.stringify({
        user_id: user.user_id,
        strict: 1,
        pj_id: subcontractorValues.pj_id,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${baseUrl}/app/panel/milestones/get-milestone`,
          requestOptions
        );
        const result = await response.json();
        if (result.success) {
          const transformData = result.data?.map((data) => ({
            id: data.id,
            name: data.name,
          }));
          setMilestones(transformData);
          setDataUpdated((pre) => !pre);
        } else {
          console.log(result.error);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchMilstone();
  }, [subcontractorValues.pj_id]);

  return (
    <div className="relative">
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${
          subcontractorSideBarIsOpen ? "translate-x-0" : "translate-x-full"
        }`}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <div className="relative">
            <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
              <div id="main" className="flex">
                <div className="bg-white shadow-md overflow-auto h-[100vh] rounded-md p-4 flex-1">
                  <div
                    id="header"
                    className="pb-7 flex justify-between items-center"
                  >
                    <div className="flex items-center gap-1">
                      <img src={EntryIcon} alt="Entry Icon" />
                      <span className="text-lg font-bold mt-2">
                        {selectedSubcontractorId
                          ? "Update Subcontractor"
                          : "Add Subcontractor"}
                      </span>
                    </div>
                    <div className="flex gap-1">
                      <img
                        src="icons/menu.svg"
                        className="cursor-pointer"
                        alt="Menu Icon"
                      />
                      <img
                        src="icons/cross.svg"
                        className="cursor-pointer"
                        alt="Cross Icon"
                        onClick={() => {
                          setSubcontractorSideBarIsOpen(false);
                          setSelectedSubcontractorId(null);
                          resetSubcontractorValues();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border border-b-gray-300 mb-3"></div>
                  <form className="space-y-4" onSubmit={handleSubmit}>
                    <SelectController
                      className="mb-4"
                      label="Project"
                      name="pj_id"
                      required={true}
                      selectOptions={projects}
                      value={subcontractorValues.pj_id}
                      onChange={handleChange}
                    />

                    <SelectController
                      label="Milestone"
                      name="milestone_id"
                      required={true}
                      selectOptions={milestones}
                      value={subcontractorValues.milestone_id}
                      onChange={handleChange}
                    />

                    <InputController
                      label="WBS"
                      name="wbs_id"
                      value={subcontractorValues.wbs_id}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Cost Code"
                      name="cost_code_id"
                      value={subcontractorValues.cost_code_id}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Activity Description"
                      name="description"
                      value={subcontractorValues.description}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Role"
                      name="role"
                      value={subcontractorValues.role}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Notes"
                      name="notes"
                      value={subcontractorValues.notes}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Hours Worked"
                      name="hours_worked"
                      type="number"
                      value={subcontractorValues.hours_worked}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Rate Per Hour"
                      name="rate_per_hour"
                      type="number"
                      value={subcontractorValues.rate_per_hour}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Cost"
                      name="cost_amount"
                      type="number"
                      value={subcontractorValues.cost_amount}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Subcontractor"
                      name="subcontractor_id"
                      value={subcontractorValues?.subcontractor_id}
                      onChange={handleChange}
                    />

                    <InputController
                      label="Account to Pay"
                      name="payment_id"
                      value={subcontractorValues?.payment_id}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Internal note"
                      name="internal_notes"
                      value={subcontractorValues.internal_notes}
                      onChange={handleChange}
                    />

                    <div className="">
                      <label
                        htmlFor="attachments"
                        className="block text-sm font-medium text-gray-700 mb-3"
                      >
                        Attachments
                      </label>
                      <input
                        type="file"
                        id="attachments"
                        name="attachments"
                        onChange={handleFileChange}
                        className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <button
                      type="submit"
                      className="mt-10 mb-6 w-full bg-primary text-white font-medium py-2 px-4 rounded hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                      {selectedSubcontractorId
                        ? "Update Subcontractor"
                        : "Add Subcontractor"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubcontractorSideBar;