import { useState, useEffect } from "react";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

export function MaterialEntry(props) {
  const { estimationId, setMatrialSidebarOpen } = props;
  const [stage, setStage] = useState("");
  const [stageId, setStageId] = useState("");
  const [workId, setWorkId] = useState("");
  const [description, setDescription] = useState("");
  const [materialRate, setMaterialRate] = useState("");
  const [materialAmount, setMaterialAmount] = useState("");
  const [stages, setStages] = useState([]);
  const [workOptions, setWorkOptions] = useState([]);
  const [quantity, setQuantity] = useState("");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=4fdc3ec84499a9c530a672fff975a85f");

    const raw = JSON.stringify({
      user_id: user.user_id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/panel/stages/get-stage-names`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const stagesData = JSON.parse(result.data);
        setStages(stagesData);
      })
      .catch((error) => console.error("Error fetching stages:", error));

    fetch(
      `${apiUrl}/app/panel/materials/get-material-names`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const worksData = JSON.parse(result.data);
        setWorkOptions(worksData);
      })
      .catch((error) => console.error("Error fetching works:", error));
  }, []);

  const handleStageChange = (event) => {
    const selectedStage = stages.find(
      (stage) => stage.stage === event.target.value
    );
    setStage(selectedStage.stage);
    setStageId(selectedStage.id);
  };

  const handleWorkChange = (event) => {
    setWorkId(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!user || !token) {
      console.error("User or token not found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=4fdc3ec84499a9c530a672fff975a85f");

    const payload = JSON.stringify({
      user_id: user.user_id.toString(), // Assuming user_id is a number and needs to be a string
      estimation_id: estimationId, // Assuming this is static or you can replace it with a dynamic value if needed
      stage: stageId,
      work: workId,
      description,
      quantity: quantity, // Update this to capture the quantity if you have it in your form
      material_rate: materialRate,
      material_amount: materialAmount,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: payload,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/app/panel/project-estimations/estimates/material/add-material-estimate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          toast.success(result.message);
          setMatrialSidebarOpen(false);
        } else {
          toast.error(result.error);
        }
        console.log(result);
      })
      .catch((error) => {
        setMatrialSidebarOpen(false);
        toast.error("Error adding material estimate");
        console.error(error);
      });
  };

  return (
    <div id="main" className="flex">
      <div className="bg-white shadow-md h-[100vh] rounded-md p-4 flex-1">
        <div id="header" className="pb-7 flex justify-between items-center">
          <div className="flex items-center gap-1">
            <img src="icons/entry.svg" alt="Entry Icon" />
            <span className="text-lg font-bold mt-2">Material Entry</span>
          </div>
          <div className="flex gap-2">
            <img
              src="icons/menu.svg"
              className="cursor-pointer"
              alt="Menu Icon"
            />
            <img
              src="icons/cross.svg"
              className="cursor-pointer"
              alt="Cross Icon"
              onClick={() => setMatrialSidebarOpen(false)}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="stage"
              className="block text-sm font-medium text-gray-700"
            >
              Stage
            </label>
            <select
              id="stage"
              value={stage}
              onChange={handleStageChange}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="" disabled>
                Select a stage
              </option>
              {stages.map((stage) => (
                <option key={stage.id} value={stage.stage}>
                  {stage.stage}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="workName"
              className="block text-sm font-medium text-gray-700"
            >
              Work Name
            </label>
            <select
              id="workName"
              value={workId}
              onChange={handleWorkChange}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            >
              <option value="" disabled>
                Select a work
              </option>
              {workOptions.map((work) => (
                <option key={work.id} value={work.id}>
                  {work.description}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <input
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mt-1 w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="materialRate"
              className="block text-sm font-medium text-gray-700"
            >
              Material Rate
            </label>
            <input
              type="number"
              id="materialRate"
              value={materialRate}
              onChange={(e) => setMaterialRate(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="materialAmount"
              className="block text-sm font-medium text-gray-700"
            >
              Material Amount
            </label>
            <input
              type="number"
              id="materialAmount"
              value={materialAmount}
              onChange={(e) => setMaterialAmount(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="quantity"
              className="block text-sm font-medium text-gray-700"
            >
              Quantity
            </label>
            <input
              type="number"
              id="quantity"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>

          <div className="flex justify-end mt-20">
            {/* <div className="mt-4">
              <p className="text-sm font-medium text-gray-700">Client Price</p>
              <p className="text-lg font-bold text-gray-900">$2,395.30</p>
            </div> */}
            <button
              type="submit"
              className="bg-primary mt-2 text-white px-4 rounded focus:outline-none focus:shadow-outline flex items-center gap-1 h-[48px]"
            >
              <img src="icons/save.svg" alt="Save Icon" />
              <span className="mt-1">Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
