import React, { useState } from "react";
import AllInvoices from "../../components/Invoices/AllInvoices";
import Pagination from "../../components/Invoices/Pagination";
import StatusBadge from "../../components/Invoices/StatusBadge";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import { Header } from "../../components/Invoices/Header";
import SecondHeader from "../../components/Invoices/SecondHeader";


const data = [
  {
    poId: "SB-25673",
    poName: "Concerter-Sunp-concreter",
    total: "68,920.00",
    paidBills: "34,920.00",
    approvalDeadline: "",
    deliveryDeadline: "",
    status: "Approved",
  },
  {
    poId: "Mt-22873",
    poName: "Concerter-Sunp-concreter",
    total: "68,920.00",
    paidBills: "34,920.00",
    approvalDeadline: "",
    deliveryDeadline: "",
    status: "Approved",
  },
  {
    poId: "SB-25673",
    poName: "Concerter-Sunp-concreter",
    total: "68,920.00",
    paidBills: "55,920.00",
    approvalDeadline: "",
    deliveryDeadline: "",
    status: "Approved",
  },
];

const Invoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState(10);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div>
        <SignedHeader />
        <div
          className="page-container flex"
          style={{ alignItems: "start", justifyContent: "start" }}
        >
          <Sidebar />
          <div className="w-[100%] flex flex-col p-3 justify-none">
            <div className="container max-w-full bg-white rounded-lg">     
                  <Header
                    isModalOpen={isModalOpen}
                    handleOpenModal={handleOpenModal}
                  />
                  <div className="bg-gray-100">
                    <SecondHeader />
                  </div>
                  <AllInvoices data={data} />
                  <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                  <div className="border rounded-sm border-b-gray-300 mt-64 "></div>
                  <div className="flex justify-end space-x-2 m-4 mb-10 ">
                    <StatusBadge status="Draft" />
                    <StatusBadge status="Pending" />
                    <StatusBadge status="Approved" />
                  </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoices;