import React, { useEffect, useRef } from "react";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import gantt from "dhtmlx-gantt";

const data = [
  {
    id: 1,
    pj_id: "Construction Schedule",
    name: "Walls",
    start_date: "2024-08-01",
    end_date: "2024-08-15",
  },
  {
    id: 2,
    pj_id: "Construction Schedule",
    name: "Foundation",
    start_date: "2024-08-05",
    end_date: "2024-08-20",
  },
  {
    id: 3,
    pj_id: "Construction Schedule",
    name: "Walls",
    start_date: "2024-08-17",
    end_date: "2024-08-23",
  },
  {
    id: 4,
    pj_id: "Construction Schedule",
    name: "Foundation",
    start_date: "2024-08-22",
    end_date: "2024-08-28",
  },
  {
    id: 5,
    pj_id: "Construction Schedule",
    name: "Walls",
    start_date: "2024-08-25",
    end_date: "2024-08-30",
  },
  {
    id: 6,
    pj_id: "Construction Schedule",
    name: "Foundation",
    start_date: "2024-08-05",
    end_date: "2024-09-20",
  },
];

const transformData = (data) => {
  return data.map((task) => ({
    id: task.id,
    text: task.name,
    start_date: task.start_date,
    end_date: task.end_date,
    duration:
      (new Date(task.end_date) - new Date(task.start_date)) /
      (1000 * 60 * 60 * 24),
  }));
};

const GanttChart = () => {
  const ganttContainer = useRef(null);

  useEffect(() => {
    gantt.config.date_format = "%Y-%m-%d";
    gantt.config.readonly = true;
    gantt.config.drag_links = false;
    gantt.config.drag_tasks = false;
    gantt.config.editable = false;

    // Initial scale configuration to show months
    gantt.config.scales = [
      { unit: "month", step: 1, format: "%F %Y" }, // Show months initially
    ];

    gantt.init(ganttContainer.current);
    gantt.parse({ data: transformData(data) });

    // Function to show days of a specific month
    const showMonthDates = (date) => {
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      gantt.config.start_date = startDate;
      gantt.config.end_date = endDate;

      gantt.config.scales = [
        { unit: "month", step: 1, format: "%F %Y" }, // Show selected month
        { unit: "day", step: 1, format: "%d %M" }, // Show days within the month
      ];

      gantt.render();
    };

    // Attach event for month header click
    gantt.attachEvent("onScaleClick", function (e, name, element) {
      if (name === "month") {
        const monthYearText = element.textContent;
        const clickedDate = new Date(monthYearText); // Convert text to Date object

        if (!isNaN(clickedDate.getTime())) {
          showMonthDates(clickedDate); // Show dates for the clicked month
        }
      }
    });

    return () => gantt.clearAll();
  }, []);

  return (
    <div className="gantt-chart-container">
      <div ref={ganttContainer} style={{ height: "500px" }}></div>
    </div>
  );
};

export default GanttChart;
