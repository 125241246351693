import React, { useState } from "react";
import SearchInput from "../Estimate/SearchInput";
import { FaAngleDown } from "react-icons/fa6";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";
import { MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import TableDataReorders from "./TableDataReorders";

const FiltertTableHeaderAndSearch = ({
  searchQuery,
  setSearchQuery,
  tableHeaders,
  setTableHeaders,
}) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setTableHeaders((headers) => {
        const oldIndex = headers.findIndex(
          (header) => header.value === active.id
        );
        const newIndex = headers.findIndex(
          (header) => header.value === over.id
        );
        return arrayMove(headers, oldIndex, newIndex);
      });
    }
  };

  const handleCheckBox = (e, value) => {
    e.stopPropagation();

    setTableHeaders((prevHeaders) =>
      prevHeaders?.map((header) =>
        header.value === value
          ? { ...header, visible: !header.visible }
          : header
      )
    );
  };

  return (
    <div>
      <div className="flex items-center space-x-4 p-2 grayscale relative flex-wrap z-20 border">
        <Popover placement="bottom" size="sm">
          <PopoverTrigger>
            <div className="cursor-pointer">
              <div className="flex gap-2 items-center">
                <img src="icons/select.svg" alt="Select" />
                <span>Select</span>
                <FaAngleDown />
              </div>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <TableDataReorders
              tableHeaders={tableHeaders}
              sensors={sensors}
              handleDragEnd={handleDragEnd}
              handleCheckBox={handleCheckBox}
            />
          </PopoverContent>
        </Popover>
        <SearchInput
          placeholder="Search with name"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <SearchInput
          placeholder="Assignees"
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>
    </div>
  );
};

export default FiltertTableHeaderAndSearch;