import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IndexPage from "./pages/IndexPage";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import Proposal from "./pages/Proposal";
import Estimate from "./pages/Estimate/Estimate";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Documents from "./pages/Documents/Documents";
import { ProjectPage } from "./pages/Project/ProjectPage";
import ProtectedRoutes from "./pages/ProtectedRoutes";
import UnProtectedRoutes from "./pages/UnProtectedRoutes";
import Invoices from "./pages/Invoices/Invoices";
import InvoicesForm from "./pages/Invoices/InvoiceForm";
import GetMilestones from "./pages/Project/GetMilestones";
import Equipment from "./pages/Equipment/Equipment";
import Products from "./pages/Products/Products"
import { NextUIProvider } from '@nextui-org/react';
import Subcontractor from "./pages/Subcontractor";
import Wbs from "./pages/Wbs";

function App() {
  return (
    <NextUIProvider>
     <header className="header min-h-[40px]" id="emptyHeader">
      <div className="w-full flex space-between"></div>
    </header>
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={<UnProtectedRoutes element={<IndexPage />} />}
          />
          <Route
            path="/login"
            element={<UnProtectedRoutes element={<LoginPage />} />}
          />
          <Route
            path="/signup"
            element={<UnProtectedRoutes element={<SignUpPage />} />}
          />
          <Route
            path="/forgotPassword"
            element={<UnProtectedRoutes element={<ForgotPasswordPage />} />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoutes element={<Dashboard />} />}
          />
          <Route
            path="/proposal"
            element={<ProtectedRoutes element={<Proposal />} />}
          />
          <Route
            path="/estimate"
            element={<ProtectedRoutes element={<Estimate />} />}
          />
          <Route
            path="/documents"
            element={<ProtectedRoutes element={<Documents />} />}
          />
          <Route
            path="/projects"
            element={<ProtectedRoutes element={<ProjectPage />} />}
          />
          <Route
           path="/projects/:id/milestones"
            element={<ProtectedRoutes element={<GetMilestones />} />}
          />
          <Route
            path="/invoices/add"
            element={<ProtectedRoutes element={<InvoicesForm />} />}
          />
          <Route
            path="/equipment"
            element={<ProtectedRoutes element={<Equipment/>} />}
          />
          <Route
            path="/invoices"
            element={<ProtectedRoutes element={<Invoices />} />}
          />
           <Route
            path="/products"
            element={<ProtectedRoutes element={<Products />} />}
          />
          <Route
            path="/activites"
            element={<ProtectedRoutes element={<Subcontractor />} />}
          />
           <Route
            path="/WbsList"
            element={<ProtectedRoutes element={<Wbs/>} />}
          />
        </Routes>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      </div>
    </Router>
    </NextUIProvider>
  );
}

export default App;
