import AddEuipmentUsage from "./AddEquipmentUsage";
function OffcanvasSidebar(props) {
  const {
    materialsidebarOpen,
    setMatrialSidebarOpen,
    estimationId,
    selectedId,
    setSelectedId
    

  } = props;
  const closeSidebar = (event, name) => {
    if (event.target.closest(".sidebar-content")) {
      return;
    }
    if (name === "equipment") {
      setMatrialSidebarOpen(false);
    } 
  };

  return (
    <div className="relative">
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${materialsidebarOpen ? "translate-x-0" : "translate-x-full"}`}
        onClick={(event) => closeSidebar(event, "equipment")}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <AddEuipmentUsage
            setMatrialSidebarOpen={setMatrialSidebarOpen}
            estimationId={estimationId}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        </div>
      </div>
    </div>
  );
}

export default OffcanvasSidebar;
