import React from 'react';

const InvoicesRow = ({ item }) => {
  return (
    <tr className="border-b">
      <td className="px-4 py-3 text-blue-600 text-sm">{item.poId}</td>
      <td className="px-4 py-3 text-sm">{item.poName}</td>
      <td className="px-4 py-3 text-sm">{item.total}</td>
      <td className="px-4 py-3 text-sm">{item.paidBills}</td>
      <td className="px-4 py-3 text-sm">{item.approvalDeadline}</td>
      <td className="px-4 py-3 text-sm">{item.deliveryDeadline}</td>
      <td className="px-4 py-3 text-sm">
        <span className={`px-2 py-1 text-sm rounded text-white ${item.status === 'Approved' ? 'bg-primary' : item.status === 'Pending' ? 'bg-purple-500' : 'bg-gray-500'}`}>
          {item.status}
        </span>
      </td>
      <td className="px-4 py-3 text-sm"><img src='icons/menu.svg' alt=''></img></td>
    </tr>
  );
};

export default InvoicesRow;
