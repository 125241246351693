import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../../context/MyContext";
import ProductsSideBar from "./ProductsSideBar";
import formatfile from "../../assets/dummyformatfile/productformatfile.csv";
import { BsThreeDots } from "react-icons/bs";
import { toast } from "react-toastify";
import ImportModal from "../Estimate/ImportModal";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
import { RiFileCopyLine } from "react-icons/ri";
import { CiImport, CiExport } from "react-icons/ci";
import { IoCloudDownloadOutline } from "react-icons/io5";
import FiltertTableHeaderAndSearch from "../micro_components.js/FiltertTableHeaderAndSearch"

const AllProducts = () => {
  const { setDataUpdated, dataUpdated } = useContext(MyContext);
  const [productsSideBarIsOpen, setProductsSideBarIsOpen] = useState(false);
  const [productData, setProductData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_API_URL;
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [file, setFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [tableHeaders, setTableHeaders] = useState([
    { name: "Id", value: "id", visible: true },
    { name: "Product", value: "product", visible: true },
    { name: "Description", value: "description", visible: true },
    { name: "Price", value: "price", visible: true },
    { name: "Category", value: "category", visible: true },
    { name: "Attachments", value: "attachments", visible: true },
    { name: "Created By", value: "created_by", visible: true },
    { name: "Action", value: "action", visible: true },
  ]);
  

  const handleImport = async () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    if (!file) {
      toast.error("Please select a file to import");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Cookie", "PHPSESSID=310b506684214d969dd74167dfc96dc1");

    const formdata = new FormData();
    formdata.append("user_id", user.user_id);
    formdata.append("import_file[]", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/app/panel/products/import-products`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to import file");
      }

      const result = await response.text();
      console.log(result);
      toast.success("Import successful");
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Failed to import file");
    }
  };

  useEffect(() => {
    searchProducts();
  }, [searchQuery, dataUpdated]);

  const searchProducts = async () => {
    if (searchQuery.length === 0 || searchQuery.length > 3) {
      const payload = {
        user_id: user.user_id,
        strict: 0,
        name: searchQuery,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${baseUrl}/app/panel/products/get-product`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }

        const result = await response.json();
        console.log(result);

        if (result.success) {
          setProductData(result.data);
          console.log("=========", result.data);
        } else {
          setProductData([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        toast.error("An error occurred while fetching products");
      }
    }
  };

  // const fetchData = async () => {
  //   const payload = {
  //     user_id: user.user_id,
  //   };
  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: JSON.stringify(payload),
  //     redirect: "follow",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${baseUrl}/app/panel/products/all-products`,
  //       requestOptions
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch equipment usage");
  //     }

  //     const result = await response.json();
  //     console.log(result);

  //     if (result.success) {
  //       setProductData(result.data);
  //       console.log("=========", result.data);
  //     } else {
  //       toast.error("Failed to retrieve data");
  //     }

  //     console.log(result);
  //   } catch (error) {
  //     console.error("Error fetching equipment usage:", error);
  //     toast.error("An error occurred while fetching equipment usage");
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, [dataUpdated]);

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      user_id: user.user_id,
      record_id: id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/app/panel/products/delete-product`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Product deleted successfully");
        setDataUpdated(!dataUpdated);
      }

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Error deleting the question:", error);
    }
  };

  return (
    <>
      <div>
        <div className="w-full m-auto flex space-between p-2 bg-white">
          <a href="#" className="logo">
            <div className="flex items-center justify-center space-x-3 gap-3">
              <div className="flex justify-center items-center gap-2">
                <div className="text-center text-[32px] font-bold capitalize p-2">
                  Product List
                </div>
              </div>
            </div>
          </a>
          <div className="menu flex space-between justify-center items-center gap-2">
            <div className="relative">
              <div
                onClick={() => {
                  setProductsSideBarIsOpen(true);
                }}
                className=" min-h-[36px] text-center flex justify-center items-center gap-1 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer"
              >
                <div>
                  <img
                    className="mb-1"
                    height={15}
                    width={15}
                    src="icons/add.svg"
                    alt=""
                  ></img>
                </div>
                <div className="h-[12px] mb-2">Create Product</div>
              </div>
            </div>
            <div>
              <Popover placement="bottom" size="sm">
                <PopoverTrigger>
                  <div className=" min-h-[36px] text-center flex justify-center items-center gap-1 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer">
                    <RiFileCopyLine size={17} />
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="px-3 py-2 space-y-2">
                    <div
                      className="text-small cursor-pointer 2xl:text-[20px] flex items-center gap-1"
                      onClick={() => setIsModalOpen(true)}
                    >
                      <CiImport size={18} />
                      <span> Import </span>
                    </div>
                    <div className="text-small cursor-pointer 2xl:text-[20px] flex items-center gap-1">
                      <CiExport size={18} />
                      <span>Export</span>
                    </div>
                    <a
                      className="text-small cursor-pointer 2xl:text-[20px] flex items-center gap-1"
                      href={formatfile}
                      download="productformatfile.csv"
                    >
                      <IoCloudDownloadOutline size={16} />
                      <span>Download Format File</span>
                    </a>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
        <FiltertTableHeaderAndSearch
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          setTableHeaders={setTableHeaders}
          tableHeaders={tableHeaders}
        />
        <div className="w-full overflow-x-auto h-[65vh]">
          <table className="w-full table-auto bg-white">
            <thead>
              <tr className="border-b">
                {tableHeaders?.map((header) => (
                  <th
                    key={header}
                    className={`px-4 py-2 text-left text-sm ${
                      header.name === "Action" ? "text-center" : ""
                    }`}
                  >
                    { header.visible && header.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {productData && productData.length > 0 ? (
                productData.map((product, index) => (
                  <tr key={product.id} className="border-b">
                    {tableHeaders.map((header) => (
                      <td
                        key={`${product.id}-${header.value}`} // Unique key using product ID and header value
                        className={`px-4 py-3 text-sm ${
                          header.value === "action" ? "text-center" : ""
                        }`}
                      >
                        {header.value === "id" && header.visible  && product.id}
                        {header.value === "product" && header.visible  && product.name}
                        {header.value === "description" && header.visible  && product.description}
                        {header.value === "price" && header.visible  && product.price}
                        {header.value === "category" && header.visible  && product.category_id}
                        {header.value === "attachments" && header.visible  && (
                          <span className="text-blue-600 cursor-pointer">
                            {product.attachments}
                          </span>
                        )}
                        {header.value === "created_by" && header.visible  && product.created_by}
                        {header.value === "action"&& header.visible  &&  (
                          <Popover
                            placement="bottom"
                            showArrow={true}
                            className="rounded-[0px]"
                          >
                            <PopoverTrigger>
                              <Button color="white">
                                <BsThreeDots />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <div className="px-3 py-2 space-y-2">
                                <div
                                  className="text-small cursor-pointer 2xl:text-[20px]"
                                  onClick={() => {
                                    setSelectedProductId(product.id);
                                    setProductsSideBarIsOpen(true);
                                  }}
                                >
                                  Update
                                </div>
                                <div
                                  className="text-small text-red-600 cursor-pointer 2xl:text-[20px]"
                                  onClick={() => {
                                    handleDelete(product.id);
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            </PopoverContent>
                          </Popover>
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableHeaders.length}
                    className="px-4 py-3 text-center text-sm"
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <ProductsSideBar
          productsSideBarIsOpen={productsSideBarIsOpen}
          setProductsSideBarIsOpen={setProductsSideBarIsOpen}
          selectedProductId={selectedProductId}
          setSelectedProductId={setSelectedProductId}
        />
      </div>
      <ImportModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        handleFileChange={handleFileChange}
        handleImport={handleImport}
      />
    </>
  );
};

export default AllProducts;
