// src/components/SearchInput.js

import React from "react";
import { useState } from "react";
import { CiSearch } from "react-icons/ci";

const SearchInput = ({ placeholder, setSearchQuery }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setSearchQuery(value); // Update the search query state
  };

  return (
    <div className="relative flex items-center">
      <input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        className="px-2 py-1 pr-8 border border-gray-300 rounded-md flex-1"
      />
      <span className="absolute right-2 text-gray-500">
      <CiSearch size={22}/>
      </span>
    </div>
  );
};

export default SearchInput;
