import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";

const EstimateRow = ({
  item,
  hidden,
  setEquipmentSidebarOpen,
  setLabourSidebarOpen,
  setSubcontractorSidebarOpen,
  setEstimationId,
  setEstimateID,
  columnFilter,
  setDataUpdate,
  dataUpdate,
  tableHeaders,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const divEl = useRef();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const handler = (event) => {
      if (divEl.current && !divEl.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler, true);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  // Debugging console logs
  useEffect(() => {
    console.log("Item zeroCostTypes:", item.zeroCostTypes);
  }, [item]);

  const renderDeleteApi = (type) => {
    switch (type) {
      case "material":
        return "material/delete-material-estimate";
      case "labour":
        return "labour/delete-labour-estimate";
      case "equipment":
        return "equipment/delete-equipment-estimate";
      case "subcontractor":
        return "subcontractor/delete-subcontractor-estimate";
    }
  };

  const handleDelete = async (id, type) => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      user_id: user.user_id,
      record_id: id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/project-estimations/estimates/${renderDeleteApi(type)}`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Estimate deleted successfully");
        setDataUpdate((prev) => !prev);
      } else {
        toast.error("Failed to delete the question");
      }

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Error deleting the question:", error);
      toast.error("An error occurred while deleting the question");
    }
  };

  return (
    <tr className={`${hidden ? "hidden" : ""} relative`}>
      <td className="p-2 border">
        <input type="checkbox" className="w-4 h-4" />
      </td>
      {tableHeaders.map((header) => (
        <td
          key={`${item.id}-${header.value}`}
          className={`px-4 py-3 text-sm border ${
            header.value === "action" ? "text-center" : ""
          }`}
        >
          {header.value === "stage" && header.visible && item.stage}
          {header.value === "type" && header.visible && item.type}
          {header.value === "work" && header.visible && item.work}
          {header.value === "quantity" && header.visible && item.quantity}
          {header.value[0] === "material_rate" &&
            header.visible &&
            item.material_rate}
          {header.value[1] === "labour_rate" &&
            header.visible &&
            item.labour_rate}
          {header.value[2] === "equipment_rate" &&
            header.visible &&
            item.equipment_rate}
          {header.value[1] === "subcontractor_rate" &&
            header.visible &&
            item.subcontractor_rate}
        </td>
      ))}
      <td className="p-2 border relative" ref={divEl}>
        {
          <>
            <img
              src="icons/menu.svg"
              alt="Menu"
              className="cursor-pointer"
              onClick={toggleMenu}
            />
            {menuOpen && (
              <div className="absolute z-50  border border-[#C3BDBD] p-0 top-8 right-0 bg-white rounded-md w-40">
                {item.type === "material" &&
                  item.zeroCostTypes?.includes("labour") && (
                    <div
                      onClick={() => {
                        setEstimationId(item?.estimation_id);
                        setEstimateID(item?.estimate_id);
                        setLabourSidebarOpen(true);
                      }}
                      className="text-[12px] px-2 flex items-center py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <img
                        className="inline-block mr-1"
                        src="icons/import.svg"
                        alt="Import"
                      />
                      <span>Add Labour</span>
                    </div>
                  )}
                {item.type === "material" &&
                  item.zeroCostTypes?.includes("equipment") && (
                    <div
                      onClick={() => {
                        setEstimationId(item?.estimation_id);
                        setEstimateID(item?.estimate_id);
                        setEquipmentSidebarOpen(true);
                      }}
                      className="text-[12px] px-2 flex items-center py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <img
                        className="inline-block mr-1"
                        src="icons/copy.svg"
                        alt="Copy"
                      />
                      <span>Add Equipment</span>
                    </div>
                  )}
                {item.type === "material" &&
                  item.zeroCostTypes?.includes("subcontractor") && (
                    <div
                      onClick={() => {
                        setEstimationId(item?.estimation_id);
                        setEstimateID(item?.estimate_id);
                        setSubcontractorSidebarOpen(true);
                      }}
                      className="text-[12px] px-2 flex items-center py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <img
                        className="inline-block mr-1"
                        src="icons/copy.svg"
                        alt="Copy"
                      />
                      <span>Add Subcontractor</span>
                    </div>
                  )}
                <div
                  className="text-[12px] gap-1 px-2 flex items-center py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleDelete(item?.estimate_id, item.type)}
                >
                  <img
                    className="inline-block w-[23px] h-[23px] "
                    src="icons/delete.svg"
                    alt="Import"
                  />
                  <span>Delete</span>
                </div>
              </div>
            )}
          </>
        }
      </td>
    </tr>
  );
};

export default EstimateRow;
