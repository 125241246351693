import React, { useEffect, useState, useContext } from "react";
import "../../index.css";
import EntryIcon from "../../assets/icons/entry.svg";
import InputController from "../micro_components.js/InputController";
import { toast } from "react-toastify";
import { MyContext } from "../../context/MyContext";
import SelectController from "../micro_components.js/SelectController";

function AddWbs({
  wbsSideBarIsOpen,
  setWbsSideBarIsOpen,
  selectedWbsId,
  setSelectedWbsId,
  selectedProjectId,
}) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const { setDataUpdated, dataUpdated, projects } = useContext(MyContext);
  const [milestones, setMilestones] = useState([]);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const initialWbsValues = {
    pj_id: "",
    milestone_id: "",
    name: "",
    resource_people: "",
    lead_days: "",
    lag_days: "",
    planned_start_date: "",
    planned_end_date: "",
    duration: "",
    precedence: "",
    status: "",
    priority: "",
    percent_completion: "",
    dependency_type: "",
    description: "",
    notes: "",
    attachments: "",
  };
  const [wbsValues, setWbsValues] = useState(initialWbsValues);
  const resetWbsValues = () => setWbsValues(initialWbsValues);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setWbsValues({
      ...wbsValues,
      [name]: value,
    });
  };
  const handleFileChange = (e) => {
    const files = e.target.files;
    const updatedAttachments = Array.from(files);
    setWbsValues({
      ...wbsValues,
      attachments: updatedAttachments,
    });
  };
  useEffect(() => {
    if (selectedWbsId) {
      fetchSingleWbs();
    }
  }, [selectedWbsId]);
  const fetchSingleWbs = async () => {
    const payload = {
      user_id: user.user_id,
      strict: 1,
      id: selectedWbsId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/app/panel/wbss/get-wbs`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch WBS");
      }

      const result = await response.json();
      if (result.success) {
        setWbsValues(result.data[0]);
      } else {
        toast.error("Failed to retrieve data");
      }
    } catch (error) {
      console.error("Error fetching WBS:", error);
      toast.error("An error occurred while fetching WBS");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("user_id", user?.user_id);
    formdata.append("pj_id", selectedProjectId);
    formdata.append("milestone_id", wbsValues.milestone_id);
    formdata.append("name", wbsValues.name);
    formdata.append("resource_people", wbsValues.resource_people);
    formdata.append("lead_days", wbsValues.lead_days);
    formdata.append("lag_days", wbsValues.lag_days);
    formdata.append("planned_start_date", wbsValues.planned_start_date);
    formdata.append("planned_end_date", wbsValues.planned_end_date);
    formdata.append("duration", wbsValues.duration);
    formdata.append("precedence", wbsValues.precedence);
    formdata.append("status", wbsValues.status);
    formdata.append("priority", wbsValues.priority);
    formdata.append("percent_completion", wbsValues.percent_completion);
    formdata.append("dependency_type", wbsValues.dependency_type);
    formdata.append("description", wbsValues.description);
    formdata.append("notes", wbsValues.notes);
    if (
      Array.isArray(wbsValues.attachments) &&
      wbsValues.attachments.length > 0
    ) {
      for (let i = 0; i < wbsValues.attachments.length; i++) {
        formdata.append("attachments[]", wbsValues.attachments[i]);
      }
    }
    if (selectedWbsId) {
      formdata.append("record_id", selectedWbsId);
    }
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
      redirect: "follow",
    };

    try {
      let response;

      if (selectedWbsId) {
        response = await fetch(
          `${baseUrl}/app/panel/wbss/update-wbs`,
          requestOptions
        );
      } else {
        response = await fetch(
          `${baseUrl}/app/panel/wbss/add-wbs`,
          requestOptions
        );
      }
      if (!response.ok) {
        throw new Error("Failed to add WBS");
      }
      const result = await response.json();
      if (result.success) {
        toast.success(
          `WBS ${selectedWbsId ? "updated" : "added"} successfully`
        );
        setWbsSideBarIsOpen(false);
        setSelectedWbsId(null);
        setDataUpdated(!dataUpdated);
        resetWbsValues();
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      console.error("Error adding WBS:", error);
      toast.error("An error occurred while adding the WBS");
    }
  };
  useEffect(() => {
    const fetchWbs = async () => {
      const raw = JSON.stringify({
        user_id: user.user_id,
        strict: 1,
        pj_id: selectedProjectId,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${baseUrl}/app/panel/milestones/get-milestone`,
          requestOptions
        );
        const result = await response.json();
        if (result.success) {
          if (result.data && result.data.length > 0) {
            const transformData = result.data.map((data) => ({
              id: data.id,
              name: data.name,
            }));
            setMilestones(transformData);
          } else {
            setMilestones(null);
          }
        }
      } catch (error) {
        console.error(error);
        setMilestones(null);
      }
    };

    fetchWbs();
  }, [selectedProjectId]);
  return (
    <div className="relative">
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${
          wbsSideBarIsOpen ? "translate-x-0" : "translate-x-full"
        }`}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <div className="relative">
            <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
              <div id="main" className="flex">
                <div className="bg-white shadow-md overflow-auto h-[100vh] rounded-md p-4 flex-1">
                  <div
                    id="header"
                    className="pb-7 flex justify-between items-center"
                  >
                    <div className="flex items-center gap-1">
                      <img src={EntryIcon} alt="Entry Icon" />
                      <span className="text-lg font-bold mt-2">
                        {selectedWbsId ? `Update WBS` : "Add WBS"}
                      </span>
                    </div>
                    <div className="flex gap-1">
                      <img
                        src="icons/menu.svg"
                        className="cursor-pointer"
                        alt="Menu Icon"
                      />
                      <img
                        src="icons/cross.svg"
                        className="cursor-pointer"
                        alt="Cross Icon"
                        onClick={() => {
                          setWbsSideBarIsOpen(false);
                          setSelectedWbsId(null);
                          resetWbsValues();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border border-b-gray-300 mb-3"></div>
                  <form className="space-y-4" onSubmit={handleSubmit}>
                    <SelectController
                      label="Project"
                      name="pj_id"
                      required={true}
                      selectOptions={projects}
                      disabled={true}
                      value={selectedProjectId}
                      onChange={handleChange}
                    />
                    <SelectController
                      label="Milestone"
                      name="milestone_id"
                      selectOptions={milestones}
                      value={wbsValues.milestone_id}
                      onChange={handleChange}
                    />
                    <InputController
                      label="WBS Title"
                      type="text"
                      name="name"
                      value={wbsValues.name}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Notes"
                      type="text"
                      name="notes"
                      value={wbsValues.notes}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Resource People"
                      type="text"
                      name="resource_people"
                      value={wbsValues.resource_people}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Lead Days"
                      type="text"
                      name="lead_days"
                      value={wbsValues.lead_days}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Lag Days"
                      type="text"
                      name="lag_days"
                      value={wbsValues.lag_days}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Start Date"
                      type="date"
                      name="planned_start_date"
                      value={wbsValues.planned_start_date}
                      onChange={handleChange}
                    />
                    <InputController
                      label="End Date"
                      type="date"
                      name="planned_end_date"
                      value={wbsValues.planned_end_date}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Dependency Type"
                      type="text"
                      name="dependency_type"
                      value={wbsValues.dependency_type}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Status"
                      type="text"
                      name="status"
                      value={wbsValues.status}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Percent Completion"
                      type="text"
                      name="percent_completion"
                      value={wbsValues.percent_completion}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Precedence"
                      type="text"
                      name="precedence"
                      value={wbsValues.precedence}
                      onChange={handleChange}
                    />
                    <div>
                      <InputController
                        label="Attachments"
                        type="file"
                        name="attachments"
                        onChange={handleFileChange}
                        multiple
                      />
                    </div>
                    <button
                      className="w-full bg-primary text-white font-medium py-2 px-4 rounded hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                      type="submit"
                    >
                      {selectedWbsId ? "Update" : "Add"} WBS
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddWbs;
