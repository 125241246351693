import { useState } from "react";
import { MaterialEntry } from "./MaterialEntry";
import { EquipmentEntry } from "./EquipmentEntry";
import { SubcontractorEntry } from "./SubcontractorEntry";
import { LabourEntry } from "./LabourEntry";

function OffcanvasSidebar(props) {
  const {
    setData,
    data,
    materialsidebarOpen,
    setMatrialSidebarOpen,
    equipmentsidebarOpen,
    setEquipmentSidebarOpen,
    laboursidebarOpen,
    setLabourSidebarOpen,
    subcontractorsidebarOpen,
    setSubcontractorSidebarOpen,
    estimationId,
    estimateID,
  } = props;

  const toggleSidebar = () => {
    setMatrialSidebarOpen(!materialsidebarOpen);
    setEquipmentSidebarOpen(false);
    setLabourSidebarOpen(false);
    setSubcontractorSidebarOpen(false);
  };

  // Function to close sidebar when clicking outside of it
  const closeSidebar = (event, name) => {
    // debugger;
    // Prevent the click inside the sidebar from closing it
    if (event.target.closest(".sidebar-content")) {
      return;
    }
    if (name === "material") {
      setMatrialSidebarOpen(false);
    } else if (name === "equipment") {
      setEquipmentSidebarOpen(false);
    } else {
      setLabourSidebarOpen(false);
      setSubcontractorSidebarOpen(false);
    }
  };

  return (
    <div className="relative">
      {/* Off-canvas sidebar */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${materialsidebarOpen ? "translate-x-0" : "translate-x-full"}`}
        onClick={(event) => closeSidebar(event, "material")}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <MaterialEntry
            setMatrialSidebarOpen={setMatrialSidebarOpen}
            estimationId={estimationId}
          />
        </div>
      </div>
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${equipmentsidebarOpen ? "translate-x-0" : "translate-x-full"}`}
        onClick={(event) => closeSidebar(event, "equipment")}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <EquipmentEntry
            setEquipmentSidebarOpen={setEquipmentSidebarOpen}
            estimationId={estimationId}
            estimateID={estimateID}
            setData={setData}
            data={data}
          />
        </div>
      </div>
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${laboursidebarOpen ? "translate-x-0" : "translate-x-full"}`}
        onClick={(event) => closeSidebar(event, "equipment")}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <LabourEntry
            setLabourSidebarOpen={setLabourSidebarOpen}
            estimationId={estimationId}
            estimateID={estimateID}
            setData={setData}
            data={data}
          />
        </div>
      </div>
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${subcontractorsidebarOpen ? "translate-x-0" : "translate-x-full"}`}
        onClick={(event) => closeSidebar(event, "equipment")}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <SubcontractorEntry
            setSubcontractorSidebarOpen={setSubcontractorSidebarOpen}
            estimationId={estimationId}
            estimateID={estimateID}
            setData={setData}
            data={data}
          />
        </div>
      </div>
    </div>
  );
}

export default OffcanvasSidebar;
