import React from 'react';

const StatusBadge = ({ status }) => {
  const statusClass = {
    Draft: 'bg-[#1A2E76]',
    Pending: 'bg-[#69028F]',
    Approved: 'bg-primary',
  };

  return (
    <span className={`px-2 py-1 rounded text-white ${statusClass[status]}`}>
      {status}
    </span>
  );
};

export default StatusBadge;
