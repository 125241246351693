import React, { useState } from "react";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

export const SubcontractorEntry = ({
  setSubcontractorSidebarOpen,
  estimateID,
  data,
  setData,
}) => {
  const [subcontractorRate, setSubcontractorRate] = useState("");
  const [subcontractorAmount, setSubcontractorAmount] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!user || !token) {
      console.error("User or token not found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=96dc75ad0e40105a7d9914e846bae5f6");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      estimate_id: estimateID,
      subcontractor_rate: subcontractorRate,
      subcontractor_amount: subcontractorAmount,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/project-estimations/estimates/subcontractor/add-subcontractor-estimate`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add subcontractor estimate");
      }

      const result = await response.text();
      console.log(result); // Handle success response
      const updatedData = data.map((item) => {
        if (item.subcontractor.estimate_id === estimateID) {
          return {
            ...item,
            subcontractor: {
              ...item.subcontractor,
              subcontractor_rate: `$${subcontractorRate}`,
              subcontractor_amount: subcontractorAmount,
            },
          };
        }
        return item;
      });

      setData(updatedData);
      toast.success("Subcontractor estimate added successfully");
      setSubcontractorSidebarOpen(false);
    } catch (error) {
      toast.error("Error adding subcontractor estimate");
      console.error("Error adding subcontractor estimate:", error.message);
      setSubcontractorSidebarOpen(false);
    }
  };

  return (
    <div id="main" className="flex">
      <div className="bg-white shadow-md h-[100vh] rounded-md p-4 flex-1">
        <div id="header" className="pb-7 flex justify-between items-center">
          <div className="flex items-center gap-1">
            <img src="icons/entry.svg" alt="Entry Icon" />
            <span className="text-lg font-bold mt-2">Subcontractor Entry</span>
          </div>
          <div className="flex gap-2">
            <img
              src="icons/menu.svg"
              className="cursor-pointer"
              alt="Menu Icon"
            />
            <img
              src="icons/cross.svg"
              className="cursor-pointer"
              alt="Cross Icon"
              onClick={() => setSubcontractorSidebarOpen(false)}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="subcontractorRate"
              className="block text-sm font-medium text-gray-700"
            >
              Subcontractor Rate
            </label>
            <input
              type="number"
              id="subcontractorRate"
              value={subcontractorRate}
              onChange={(e) => setSubcontractorRate(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="subcontractorAmount"
              className="block text-sm font-medium text-gray-700"
            >
              Subcontractor Amount
            </label>
            <input
              type="number"
              id="subcontractorAmount"
              value={subcontractorAmount}
              onChange={(e) => setSubcontractorAmount(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="flex justify-between mt-20">
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-700">Client Price</p>
              <p className="text-lg font-bold text-gray-900">$2,395.30</p>
            </div>
            <button
              type="submit"
              className="bg-primary mt-2 text-white px-4 rounded focus:outline-none focus:shadow-outline flex items-center gap-1 h-[48px]"
            >
              <img src="icons/save.svg" alt="Save Icon" />
              <span className="mt-1">Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
