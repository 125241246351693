import React, {useState } from "react";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import AllSubcontractorActivities from "../../components/Subcontractor/AllSubcontractorActivities";


function Subcontractor() {
  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none">
        <AllSubcontractorActivities/>
        </div>
      </div>
    </div>
  );
}

export default Subcontractor;