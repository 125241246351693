import React, { useState } from "react";
import { CreateProject } from "./CreateProject";

export const Header = () => {
  const path = window.location.pathname.split("/")[1];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCreateProject = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <header className="header" id="home">
        <div className="w-full m-auto flex space-between items-center p-2">
          <a href="#" className="logo">
            <div className="flex items-center justify-center space-x-3 gap-3">
              <div className="flex justify-center items-center gap-2">
                <div className="text-center text-2xl font-bold capitalize p-2">
                  {path}
                </div>
              </div>
            </div>
          </a>
          <div className="flex h-10 gap-2">
            <button
              className="bg-white text-primary px-2 py-2 rounded-lg mr-2 border border-blue-500 flex gap-1"
             
            >
              Sync
            </button>
            <button
              className="bg-primary text-white px-2 py-2 rounded-lg flex items-center justify-center gap-0 hover:bg-blue-900"
              onClick={handleCreateProject}
            >
              <img src="icons/create.svg" alt=""></img>
              Create project
            </button>
          </div>
        </div>
      </header>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full h-full">
            <div className="p-6">
              <CreateProject handleCloseModal={handleCloseModal} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
