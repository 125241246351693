import React, { useEffect, useState , useContext } from 'react';
import EquipmentTableHeader from './EquipmentTableHeader';
import EquipmentRow from './EquipmentRow';
import { toast } from "react-toastify"; 

import { MyContext } from "../../context/MyContext";

const AllEquipments = ({setSelectedId , setMatrialSidebarOpen,}) => {
    const [equipmentData, setEquipmentData] = useState([]);
    const { dataUpdated} = useContext(MyContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("token");
            const user = JSON.parse(localStorage.getItem("user"));
            const payload = {
                user_id: user.user_id, 
            };
            const requestOptions = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
                redirect: "follow",
            };

            try {
                const response = await fetch(
                    `${apiUrl}/app/panel/project-tracking/equipment-usage/all-equipment-usages`,
                    requestOptions
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch equipment usage");
                }

                const result = await response.json();
                console.log(result);
                 
                if (result.success) {
                  setEquipmentData(result.data)
                  console.log("=========",result.data) // assuming the API response structure as seen in the image
                } else {
                    toast.error("Failed to retrieve data");
                }

                console.log(result);
            } catch (error) {
                console.error("Error fetching equipment usage:", error);
                toast.error("An error occurred while fetching equipment usage");
            }
        };

        fetchData();
    }, [dataUpdated]);

    return (
        <div className="overflow-x-auto">
            
            <table className="min-w-full bg-white border">
                <EquipmentTableHeader />
                <tbody>
                    {equipmentData.map((item, index) => (
                        <EquipmentRow key={index} item={item} setSelectedId={setSelectedId} setMatrialSidebarOpen={setMatrialSidebarOpen}
                        
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AllEquipments;
