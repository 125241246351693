import React from "react";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();

  return (
    <>
     
        <div className="w-full m-auto flex space-between p-2">
          <a href="#" className="logo">
            <div className="flex items-center justify-center space-x-3 gap-3">
              <div className="flex justify-center items-center gap-2">
                <div className="text-center text-[32px] font-bold capitalize p-2">
                 Invoices
                </div>
              </div>
            </div>
          </a>

          <div className="menu flex space-between justify-center items-center gap-2">
            <div className="relative">
              <div className="min-h-[36px] text-center flex justify-center items-center gap-1 text-sm p-2 px-4 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer mr-4"
                onClick={() => navigate("/invoices/add")}
              >
                <div>
                  <img height={18} width={18} src="icons/add.svg" alt="" />
                </div>
                <div className="h-[12px] mb-2">
                  Create a PO
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};
