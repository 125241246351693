import React, { useState } from "react";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

export const LabourEntry = ({
  setLabourSidebarOpen,
  estimateID,
  data,
  setData,
}) => {
  const [labourRate, setLabourRate] = useState("");
  const [labourAmount, setLabourAmount] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!user || !token) {
      console.error("User or token not found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=96dc75ad0e40105a7d9914e846bae5f6");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      estimate_id: estimateID, // Hardcoded estimate_id
      labour_rate: labourRate,
      labour_amount: labourAmount,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/project-estimations/estimates/labour/add-labour-estimate`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to add labour estimate");
      }

      const result = await response.text();
      console.log(result); // Handle success response
      const updatedData = data.map((item) => {
        if (item.labour.estimate_id === estimateID) {
          return {
            ...item,
            labour: {
              ...item.labour,
              labour_rate: `$${labourRate}`,
              labour_amount: labourAmount,
            },
          };
        }
        return item;
      });

      setData(updatedData);
      toast.success("Labour estimate added successfully");
      setLabourSidebarOpen(false);
    } catch (error) {
      toast.error("Error adding labour estimate");
      console.error("Error adding labour estimate:", error.message);
      setLabourSidebarOpen(false);
    }
  };

  return (
    <div id="main" className="flex">
      <div className="bg-white shadow-md h-[100vh] rounded-md p-4 flex-1">
        <div id="header" className="pb-7 flex justify-between items-center">
          <div className="flex items-center gap-1">
            <img src="icons/entry.svg" alt="Entry Icon" />
            <span className="text-lg font-bold mt-2">Labour Entry</span>
          </div>
          <div className="flex gap-2">
            <img
              src="icons/menu.svg"
              className="cursor-pointer"
              alt="Menu Icon"
            />
            <img
              src="icons/cross.svg"
              className="cursor-pointer"
              alt="Cross Icon"
              onClick={() => setLabourSidebarOpen(false)}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="labourRate"
              className="block text-sm font-medium text-gray-700"
            >
              Labour Rate
            </label>
            <input
              type="number"
              id="labourRate"
              value={labourRate}
              onChange={(e) => setLabourRate(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="labourAmount"
              className="block text-sm font-medium text-gray-700"
            >
              Labour Amount
            </label>
            <input
              type="number"
              id="labourAmount"
              value={labourAmount}
              onChange={(e) => setLabourAmount(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="flex justify-between mt-20">
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-700">Client Price</p>
              <p className="text-lg font-bold text-gray-900">$2,395.30</p>
            </div>
            <button
              type="submit"
              className="bg-primary mt-2 text-white px-4 rounded focus:outline-none focus:shadow-outline flex items-center gap-1 h-[48px]"
            >
              <img src="icons/save.svg" alt="Save Icon" />
              <span className="mt-1">Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
