import React ,{useState} from "react";
import SearchInput from "../Estimate/SearchInput";
const SecondHeader = () => {
  const [searchQuery, setSearchQuery] = useState('');
  return (
    <div>
      <div className="flex items-center space-x-4 p-2 grayscale relative flex-wrap z-20 border">
        <div className="flex gap-2 cursor-pointer">
          <img src="icons/select.svg" alt="" />
          <span>Select</span>
          <img src="icons/dropdown.svg" alt="" />
        </div>
        <div className="flex gap-2 cursor-pointer">
          <img src="icons/collapse.svg" alt="" />
          <span className="text-nowrap">Collapse All</span>
        </div>
        <SearchInput placeholder="Search here" setSearchQuery={setSearchQuery}/>
        <SearchInput placeholder="Asignees" setSearchQuery={setSearchQuery}/>
      </div>
    </div>
  );
};

export default SecondHeader;
