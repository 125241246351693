import React from "react";

const EstimateGroupRow = ({
  item,
  expanded,
  toggleRow,
  columnFilter,
}) => {
  return (
    <tr
      className="bg-[#e8f0fe] cursor-pointer border border-gray-100 hover:bg-gray-200 dark:border-gray-200 dark:hover:bg-gray-200"
    >
      <td className="p-2 border-x-0">
        <input
          type="checkbox"
          className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-primary dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </td>
      <td
        className="flex gap-1 p-2 border-x-0 font-bold"
        onClick={() => toggleRow(item.id, item.type)}
      >
        <img
          width={15}
          src="icons/dropdown.svg"
          className={expanded ? "" : "transform -rotate-90"}
          alt="dropdown-icon"
        />
        {columnFilter.stage && item.stage}
      </td>
      {columnFilter.work && <td className="p-2 border-x-0">{item.work}</td>}
      {columnFilter.quantity && (
        <td className="p-2 border-x-0">{item.quantity}</td>
      )}
      {columnFilter.unit && <td className="p-2 border-x-0">{item.unit}</td>}
      {columnFilter.unit_cost && (
        <td className="p-2 border-x-0">{item.unitCost}</td>
      )}
      {columnFilter.total_cost && (
        <td className="p-2 border-x-0">{item.totalCost}</td>
      )}
      {columnFilter.total_cost && (
        <td className="p-2 border-x-0">{item.totalCost}</td>
      )}
      <td className="p-2 border-x-0">{item.menu}</td>
    </tr>
  );
};

export default EstimateGroupRow;