import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

const DeletedTable = () => {
  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=b8446925257e4bbe56440fd1f36bbae5");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/list-deleted-folders`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const data = JSON.parse(result.data);
        console.log("=======++++++++",data);
        setDocuments(data);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleRestore = (docId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=564881d2041006cac4fee2b541ac8df1");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      id: docId,
      type: "file",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/actions/restore`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        toast.success(result.message);
        console.log(result);
        // Optionally update the document list or state here
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.file_id !== docId)
        );
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        console.error(error);
      });
  };

  return (
    <div className="p-4 bg-white">
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left p-2 border-b border-gray-300">Name</th>
            <th className="text-left p-2 border-b border-gray-300">
              Deleted Date
            </th>
            <th className="text-left p-2 border-b border-gray-300">Restore</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc, index) => (
            <tr key={index} className="border-b border-gray-300">
              <td className="p-2">
                <div className="flex items-center gap-2">
                  <img src="icons/folder.svg" alt="Folder Icon" />
                  <span className="mt-1">{doc.name}</span>
                  <img src="icons/sysfolder.svg" alt="Sys Folder Icon" />
                  {doc.folder_name || doc.file_name}
                </div>
              </td>
              <td className="p-2 flex gap-1">
                <span className="ml-2">{doc.deleted_date}</span>
              </td>
              
                <td className="p-2">
                  <img
                    src="https://img.icons8.com/?size=100&id=50827&format=png&color=000000" // Replace with your restore icon path
                    alt="Restore Icon"
                    className="cursor-pointer"
                    width={25}
                    onClick={() => handleRestore(doc.file_id)}
                  />
                </td>
              
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DeletedTable;
