import React, { useState } from "react";
import ".././App.css";
// import { Header } from './components/Header';
import { Banner } from "../components/Banner";
import { WhatWeDo } from "../components/WhatWeDo";
import { AboutUs } from "../components/AboutUs";
import { Projects } from "../components/Projects";
import { Testimonials } from "../components/Testimonials";
import { Contact } from "../components/Contact";
import { Footer } from "../components/Footer";
// import { ScrollToTopButton } from '../components/ScrollToTopButton';
import { Header } from "../components/Header";

function IndexPage() {
  return (
    <div>
      <Header />
      <Banner />
      <WhatWeDo />
      <AboutUs />
      <Projects />
      <Testimonials />
      <Contact />
      <Footer />
      {/* <ScrollToTopButton /> */}
    </div>
  );
}

export default IndexPage;
