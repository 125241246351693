import React, { useState,useEffect,useRef } from "react";
import SearchInput from "../Estimate/SearchInput";

const SecondHeader = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const divEl = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  return (
    <>
      <div className="flex bg-gray-100 p-2 gap-9 border">
        <div
          className="relative flex items-center gap-1 px-4 cursor-pointer"
          onClick={toggleDropdown}
        >
          <img src="icons/select.svg" alt="" className="w-4" />
          <span className="text-gray-500 mt-1">Select</span>
        </div>
        <SearchInput placeholder="Search here" setSearchQuery={setSearchQuery}/>
      </div>
      {isDropdownOpen && (
        <div ref={divEl} className="absolute bg-white border rounded shadow-md p-2">
          <label className="flex items-center gap-2">
            <input type="checkbox" />
            <span>Name</span>
          </label>
          <label className="flex items-center gap-2">
            <input type="checkbox" />
            <span>Shared With</span>
          </label>
          <label className="flex items-center gap-2">
            <input type="checkbox" />
            <span>Last Updated</span>
          </label>
        </div>
      )}
    </>
  );
};

export default SecondHeader;
