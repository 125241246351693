import React, { useState,useEffect,useRef } from "react";
import { toast } from "react-toastify";
import ImportModal from "./ImportModal";
import "../../App.css";
import { IoDownloadOutline } from "react-icons/io5";
const apiUrl = process.env.REACT_APP_API_URL;

export const Header = (props) => {
  const path = window.location.pathname.split("/")[1];
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setMatrialSidebarOpen = props.setMatrialSidebarOpen;
  const estimationId = props.estimationId;
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
   const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleImport = async () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    if (!file || !title) {
      toast.error("Please provide a title and select a file");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Cookie", "PHPSESSID=310b506684214d969dd74167dfc96dc1");

    const formdata = new FormData();
    formdata.append("user_id", user.user_id);
    formdata.append("pj_id", "4");
    formdata.append("title", title);
    formdata.append("import_file", file);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/project-estimations/import-estimation`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to import estimation");
      }

      const result = await response.text();
      console.log(result);
      toast.success("Import successful");
      setIsModalOpen(false)
    } catch (error) {
      console.error(error);
      toast.error("Failed to import estimation");
    }
  };


  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const divEl = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(event.target)) {
        setShowOptions(false);
        setShowDropdown(false);
      }
    };
    document.addEventListener('click', handler, true);
    return()=>{
      document.removeEventListener('click',handler);
    }
  }, []);



  const handleCopyForm = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!user || !token) {
      console.error("User or token not found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=96dc75ad0e40105a7d9914e846bae5f6");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      estimation_id: estimationId.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/project-estimations/export-estimation`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to export estimation");
      }

      const result = await response.text();
      const jsonString = JSON.stringify(result);
      await navigator.clipboard.writeText(jsonString);
      toast.success("Data copied to clipboard");
    } catch (error) {
      console.error("Error copying form data:", error);
      toast.error("Failed to copy data");
    }
  };
  

  return (
    <header className="header" id="home">
      <div className="w-full p-2 m-auto flex space-between ">
        <a href="#" className="logo">
          <div className="flex items-center justify-center space-x-3 gap-3">
            <div className="flex justify-center items-center gap-2">
              <div className="text-center text-[32px] font-bold capitalize p-2">
                {path}
              </div>
            </div>
          </div>
        </a>
        <span
          className="material-symbols-outlined"
          id="openSidebar"
          onClick={toggleSidebar}
        >
          menu
        </span>
        <div className="menu flex space-between justify-center items-center gap-2">
          <div className="relative">
            <div
              className="min-h-[36px] text-center flex justify-center items-center gap-2 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer"
              onClick={() => setShowOptions(!showOptions)}
            >
              <div>
                <img height={12} width={12} src="icons/add.svg" alt=""></img>
              </div>
              <div className="h-[12px] mb-2">Add item</div>
            </div >
            {showDropdown && (
              <div ref={divEl} className="absolute z-30 pl-2 border border-[#C3BDBD] p-0 top-8 right-[-2.3rem] left-3 mt-0 bg-white rounded-md  ">
                <div
                  className="text-[12px] flex items-center py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => setIsModalOpen(true)}
                >
                  <img
                    className="inline-block mr-1"
                    src="icons/import.svg"
                    alt=""
                  />
                  <span>Import</span>
                </div>
                <div
                  className="text-[12px] gap-1 flex items-center py-2 hover:bg-gray-100 cursor-pointer mt-[-0.6rem]"
                  onClick={handleCopyForm}
                >
                 <IoDownloadOutline  size={20}/>
                  Download
                </div>
              </div>
            )}
          </div>

          <div className="relative">
            <div onClick={toggleDropdown}>
              <img
                className="max-h-[35px] p-2 bg-primary text-white font-bold rounded-lg hover:bg-blue-900 cursor-pointer"
                src="icons/clipboard.svg"
                alt=""
              ></img>
            </div>
            {showOptions && (
              <div onClick={() => setMatrialSidebarOpen(true)} ref={divEl} className=" cursor-pointer absolute top-11 z-[21] right-12 bg-white border shadow-md rounded-md p-3 w-28 h-22">
                <div
                  className="flex items-center gap-1"
                  
                >
                  <span className="text-[12px] mt-1 font-bold hover:bg-blue-500 hover:text-white cursor-pointer">
                    Material
                  </span>
                </div>
                <hr className="w-full border border-gray-300"></hr>
              </div>
            )}
          </div>
        </div>
      </div>
      <ImportModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} handleFileChange={handleFileChange} handleTitleChange={handleTitleChange} handleImport={handleImport} title={title}/>
    </header>
  );
};