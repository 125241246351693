// SidebarItem.js
import React from "react";
import { Link } from "react-router-dom";

const SidebarItem = ({ icon, label, link, isActive }) => {
  const iconSrc = `/icons/${icon}`;

  return (
    <li className={`${isActive ? "bg-[#f3f7fd] font-bold m-2" : "m-2"}`}>
      <Link
        to={link}
        className={` flex gap-3 items-center text-[16px] p-2 rounded hover:bg-gray-300 ${
          isActive ? "text-primary" : "text-black"
        }`}
      >
        <img
          src={iconSrc}
          alt="Demo Logo"
          className={`w-5 h-7 ${isActive ? "text-primary" : ""}`}
        />
        <span>{label}</span>
      </Link>
    </li>
  );
};

export default SidebarItem;
