import React, { useState } from "react";
import EstimateRow from "./EstimateRow";
import EstimateGroupRow from "./EstimateGroupRow";

const EstimateTable = ({
  expandedStages,
  setExpandedStages,
  data,
  setEquipmentSidebarOpen,
  setLabourSidebarOpen,
  setSubcontractorSidebarOpen,
  setEstimationId,
  setEstimateID,
  columnFilter,
  setDataUpdate,
  dataUpdate,
  setAllExpandedRows,
  tableHeaders,
}) => {
  const toggleRow = (id, type) => {
    if (type === "stage") {
      setExpandedStages((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
      setAllExpandedRows(true)
    }
  };
  const transformData = (data) => {
    let transformedData = [];
    let stageGroups = {};

    data &&
      data.forEach((group) => {
        const stageName = Object.values(group)[0]?.stage;
        if (!stageGroups[stageName]) {
          stageGroups[stageName] = [];
        }

        let zeroCostTypes = []; 

        Object.keys(group).forEach((key) => {
          const unitCostKey = `${key}_rate`;
          const unitCost = parseFloat(group[key][unitCostKey].replace("$", ""));
          const newItem = {
            ...group[key],
            id: `${stageName}-${key}`,
            type: key,
            stage: stageName,
            unitCost: group[key][unitCostKey],
            totalCost: group[key].quantity * unitCost,
          };
          if (unitCost === 0) {
            zeroCostTypes.push(key);
          }

          if (unitCost !== 0) {
            stageGroups[stageName].push(newItem);
          }

          if (stageGroups[stageName].length > 0) {
            stageGroups[stageName][stageGroups[stageName].length - 1].zeroCostTypes = zeroCostTypes;
          }
        });

      });

    Object.keys(stageGroups).forEach((stageName, index) => {
      const groupId = `stage-${index}`;

      transformedData.push({
        id: groupId,
        type: "stage",
        stage: stageName,
        children: stageGroups[stageName], // Directly add child items here
      });
    });

    return transformedData;
  };

  const renderRows = () => {
    const transformedData = transformData(data);
    return (
      transformedData &&
      transformedData.map((item) => (
        <React.Fragment key={item.id}>
          <EstimateGroupRow
            item={item}
            expanded={!!expandedStages[item.id]}
            toggleRow={toggleRow}
            columnFilter={columnFilter}
          />
          {expandedStages[item.id] &&
            item.children.map((childItem) => (
              <EstimateRow
                key={childItem.id}
                item={childItem}
                hidden={false} 
                setEquipmentSidebarOpen={setEquipmentSidebarOpen}
                setLabourSidebarOpen={setLabourSidebarOpen}
                setSubcontractorSidebarOpen={setSubcontractorSidebarOpen}
                setEstimationId={setEstimationId}
                setEstimateID={setEstimateID}
                columnFilter={columnFilter}
                setDataUpdate={setDataUpdate}
                dataUpdate={dataUpdate}
                tableHeaders={tableHeaders}
              />
            ))}
        </React.Fragment>
      ))
    );
  };

  return (
    <div className="overflow-x-auto grayscale">
      <table className="rounded-md min-w-full bg-white border-0">
      <thead>
          <tr className="border-b">
            <th className="px-2 py-2 text-left text-sm">
              <input
                type="checkbox"
                className="w-4 h-4 cursor-pointer"
              />
            </th>
            {tableHeaders?.map(
              (header) =>
                header.visible && (
                  <th
                    key={header.value}
                    className={`px-4 py-2 text-left text-sm ${
                      header.name === "Action" ? "text-center" : ""
                    }`}
                  >
                    {header.name}

                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default EstimateTable;
