import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components/Project/Header";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import { SecondHeader } from "../../components/Project/SecondHeader";
import { BsCopy } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { Loader } from "../../components/micro_components.js/loader";
import CanvasSideBar from "../../components/Project/CanvasSideBar";
const apiUrl = process.env.REACT_APP_API_URL;

const GetMilestones = () => {
  const [milestoneSidebarOpen, setMilestoneSidebarOpen] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        setIsLoading(true);
        const user = JSON.parse(localStorage.getItem("user"));
        const token = localStorage.getItem("token");

        const myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", token);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          user_id: user.user_id,
          strict: 1,
          pj_id: id,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        try {
          const response = await fetch(
            `${apiUrl}/app/panel/milestones/get-milestone`,
            requestOptions
          );
          const result = await response.json();
          setIsLoading(false);

          if (result.success) {
            setMilestones(result.data);
          } 
        } catch (error) {
          setIsLoading(false);
          toast.error("Failed to fetch milestones");
          console.error(error);
        }
      };

      fetchData();
    }
  }, [id]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const deleteMilestone = (milestoneId) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_id: user.user_id,
      record_id: milestoneId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/panel/milestones/delete-milestone`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        toast.success("Milestone deleted successfully");
        setMilestones(
          milestones.filter((milestone) => milestone.id !== milestoneId)
        );
      })
      .catch((error) => {
        toast.error("Error deleting milestone");
        console.error(error);
      });
  };

  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none">
          <SecondHeader />
          <div className="bg-gray-100 min-h-screen">
            <div className="bg-white shadow-md w-full mx-auto h-[100vh] ml-0">
              <div className="flex justify-between items-center p-4">
                <button
                  onClick={handleBackClick}
                  className="min-h-[36px] text-center flex justify-center items-center gap-2 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer"
                >
                  Back
                </button>
                <div className="flex items-center space-x-2">
                  <button
                    className="min-h-[36px] text-center flex justify-center items-center gap-2 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer"
                    onClick={() => setMilestoneSidebarOpen(true)}
                  >
                    Add item
                  </button>
                  <div className="max-h-[35px] p-2 bg-primary text-white font-bold rounded-lg hover:bg-blue-900 cursor-pointer">
                    <BsCopy size={18} />
                  </div>
                </div>
              </div>

              <hr />
              {isLoading ? (
                <Loader />
              ) : (
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        <input type="checkbox" className="cursor-pointer" />
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        ID
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        Name
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        Start Date
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        Duration
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        End Date
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        Completion%
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        Dependencies
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        Assignees
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        Labels
                      </th>
                      <th className="py-2 px-6 border-b border-gray-200 text-left text-sm">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {milestones.length === 0 ? (
                      <tr>
                        <td
                          colSpan="16"
                          className="text-center text-gray-500 py-4 text-2xl"
                        >
                          No Data found
                        </td>
                      </tr>
                    ) : (
                      milestones?.map((milestone, index) => (
                        <tr key={index}>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            <input type="checkbox" className="cursor-pointer" />
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            {milestone.id}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-blue-600 underline text-sm">
                            {milestone.name}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            {milestone.startDate}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            {milestone.duration}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            {milestone.endDate}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            {milestone.completion}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            {milestone.dependencies}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            {milestone.asignees}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            {milestone.labels}
                          </td>
                          <td className="py-2 px-6 border-b border-gray-200 text-sm">
                            <div
                              className="cursor-pointer"
                              onClick={() => deleteMilestone(milestone.id)}
                            >
                              <MdDeleteOutline size={20} />
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}
               <CanvasSideBar
            milestoneSidebarOpen={milestoneSidebarOpen}
            setMilestoneSidebarOpen={setMilestoneSidebarOpen}
          projectId={id}
          />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetMilestones;
