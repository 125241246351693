import React from 'react';

const InvoicesHeader = () => {
  return (
    <thead>
      <tr className=" border-b">
        <th className="px-4 py-2 text-left text-sm">PO ID</th>
        <th className="px-4 py-2 text-left text-sm">PO Name</th>
        <th className="px-4 py-2 text-left text-sm">Total</th>
        <th className="px-4 py-2 text-left text-sm">Paid Bills</th>
        <th className="px-4 py-2 text-left text-sm">Approval Deadline</th>
        <th className="px-4 py-2 text-left text-sm">Delivery Deadline</th>
        <th className="px-4 py-2 text-left text-sm">Status</th>
        <th className="px-4 py-2 text-left text-sm">Actions</th>
      </tr>
    </thead>
  );
};

export default InvoicesHeader;
