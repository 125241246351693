import React from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="space-x-2 mt-4 pl-2 flex items-center">
      <button
        className={`px-2 py-1 border rounded ${currentPage === 1 ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-white'}`}
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        {"<"}
      </button>

      {[...Array(totalPages).keys()].map(page => (
        <button
          key={page}
          className={`px-2 py-1 border rounded ${currentPage === page + 1 ? 'bg-blue-500 text-white' : 'bg-white'}`}
          onClick={() => onPageChange(page + 1)}
        >
          {page + 1}
        </button>
      ))}

      <button
        className={`px-2 py-1 border rounded ${currentPage === totalPages ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-white'}`}
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        {">"}
      </button>
    </div>
  );
};

export default Pagination;
