import React, { useState } from "react";
import AllProjects from "./AllProjects";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import { SecondHeader } from "../../components/Project/SecondHeader";
import { Header } from "../../components/Project/Header";
import CanvasSideBar from "../../components/Project/CanvasSideBar";

export const ProjectPage = () => {
  const [milestoneSidebarOpen, setMilestoneSidebarOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [columnFilter, setColumnFilter] = useState({
    all: true,
    projectId: true,
    name: true,
    client: true,
    projectType: true,
    startDate: true,
    endDate: true,
    status: true,
  });

  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none">
          <Header />
          {/* <SecondHeader
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            projects={projects}
            setProjects={setProjects}
          /> */}
          <AllProjects
            columnFilter={columnFilter}
            setMilestoneSidebarOpen={setMilestoneSidebarOpen}
            milestoneSidebarOpen={milestoneSidebarOpen}
            projects={projects}
            setProjects={setProjects}
          />
        </div>
      </div>
    </div>
  );
};
