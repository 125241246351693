import React, { useState } from "react";
import InputField from "../components/micro_components.js/InputField";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

function ForgotPasswordPage() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email) {
      toast.error("Email is required!");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/app/account/forgot-password`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        if (result?.error) {
          toast.error(result.error);
          return;
        } else {
          toast.success(result.success);
        }
        console.log(result); // Handle success response
        // Optionally redirect or display a success message
        toast.success("Email sent successfully!");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Error sending email!");
        // Handle error (e.g., display error message to the user)
      });
  };

  return (
    <div className="page-container flex">
      <form onSubmit={handleSubmit} className="form login">
        <h1>Verify Email</h1>
        <div className="stylish-line"></div>
        <h4>Please Enter Your Email</h4>

        <InputField
          label="Email"
          name="email"
          type="email"
          placeholder="Enter your email..."
          value={formData.email}
          onChange={handleChange}
        />

        <div className="input-cn">
          <button className="btn btn-primary" type="submit" name="check">
            Send Link
          </button>
        </div>

        <div className="formp-holder">
          <Link to="/login">Login</Link>
          <Link to="/">Home</Link>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordPage;
