import React, { useState } from "react";
import InputField from "../components/micro_components.js/InputField";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;
function SignupPage() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirm_password) {
      toast.error("Passwords do not match!");
      return;
    }
    if (formData.password.length < 8) {
      toast.error("Password must be at least 8 characters long!");
      return;
    }
    if (formData.phone.length < 10) {
      toast.error("Phone number must be at least 10 characters long!");
      return;
    }
    if (formData.first_name.length < 2) {
      toast.error("First name must be at least 2 characters long!");
      return;
    }
    if (formData.last_name.length < 2) {
      toast.error("Last name must be at least 2 characters long!");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=6bf309e9bf5dd0afbff1124a50a49326");

    const raw = JSON.stringify({
      first_name: formData.first_name,
      middle_name: formData.middle_name,
      last_name: formData.last_name,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/account/signup`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.error) {
          toast.error(result.error);
        } else {
          console.log(result); // Handle successful login response
          // Optionally redirect or perform actions upon successful login
          toast.success("Signup successful!");
          navigate("/login");
        }
        console.log(result);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="page-container flex">
      <form onSubmit={handleSubmit} className="form signup">
        <h1>Sign Up</h1>
        <div className="stylish-line"></div>
        <h4>Create your account</h4>

        <div className="input-wrap flex">
          <InputField
            label="First Name"
            name="first_name"
            type="text"
            placeholder="Enter your First Name..."
            value={formData.first_name}
            onChange={handleChange}
          />
          <InputField
            label="Middle Name"
            name="middle_name"
            type="text"
            placeholder="Enter your Middle Name..."
            value={formData.middle_name}
            onChange={handleChange}
          />
          <InputField
            label="Last Name"
            name="last_name"
            type="text"
            placeholder="Enter your Last Name..."
            value={formData.last_name}
            onChange={handleChange}
          />
          <InputField
            label="Email"
            name="email"
            type="email"
            placeholder="Enter your email..."
            value={formData.email}
            onChange={handleChange}
          />
          <InputField
            label="Phone Number"
            name="phone"
            type="number"
            placeholder="Enter your phone number..."
            value={formData.phone}
            onChange={handleChange}
          />
          <InputField
            label="Password"
            name="password"
            type="password"
            placeholder="Enter your password..."
            value={formData.password}
            onChange={handleChange}
          />
          <InputField
            label="Confirm Password"
            name="confirm_password"
            type="password"
            placeholder="Confirm your password..."
            value={formData.confirm_password}
            onChange={handleChange}
          />
        </div>

        <div className="input-cn">
          <button className="btn btn-primary" type="submit" name="register">
            Sign up
          </button>
        </div>

        <div className="formp-holder">
          <Link to="/login">Sign In</Link>
        </div>
      </form>
    </div>
  );
}

export default SignupPage;
