import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactSimpleImageViewer from "react-simple-image-viewer";
import EmptyDocuments from "./EmptyDocuments";
import { Loader } from "../micro_components.js/loader";

const apiUrl = process.env.REACT_APP_API_URL;

const AllDocuments = ({
  documents,
  setDocuments,
  folderId,
  setFolderId,
  reloadDocuments,
}) => {
  const [navigationPath, setNavigationPath] = useState([]);
  const [fileBlob, setFileBlob] = useState(null);
  const [fileName, setFileName] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [itemToMove, setItemToMove] = useState(null);
  const [destinationFolders, setDestinationFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=b8446925257e4bbe56440fd1f36bbae5");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      folder_id: folderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/list-folder`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const data = JSON.parse(result.data);
        console.log(data);
        setDocuments(data);
        setLoading(false);
        setDestinationFolders(
          data.filter(
            (item) => item.folder_id && item.folder_id !== itemToMove?.folder_id
          )
        );
      })
      .catch((error) => console.error(error));
  }, [folderId, itemToMove, reloadDocuments]);

  const handleFolderClick = (id) => {
    setNavigationPath((prevPath) => [...prevPath, folderId]);
    setFolderId(id);
  };

  const handleBackClick = () => {
    setNavigationPath((prevPath) => {
      const newPath = [...prevPath];
      const previousFolderId = newPath.pop();
      setFolderId(previousFolderId);
      return newPath;
    });
  };

  const handleFileClick = (fileId, fileName) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Cookie", "PHPSESSID=b8446925257e4bbe56440fd1f36bbae5");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      file_id: fileId.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/view-file`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const fileExtension = fileName.split(".").pop().toLowerCase();
        if (
          ["pdf", "png", "jpg", "jpeg", "gif", "bmp", "svg"].includes(
            fileExtension
          )
        ) {
          window.open(url);
        } else if (fileExtension === "csv") {
          // Option to download or open in a new tab
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to view file");
      });
  };

  const downloadFile = () => {
    if (fileBlob) {
      const url = fileBlob;
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      setFileBlob(null);
    }
  };

  const deleteFolder = (folderId, fileId) => {
    const id = folderId || fileId;
    const type = folderId ? "folder" : "file";
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=564881d2041006cac4fee2b541ac8df1");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      id: id,
      type: type,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/actions/delete`, requestOptions)
  .then((response) => response.json()) // Parse the response as JSON
  .then((result) => {
    console.log(result);
    
    if (result.success) {
      // If deletion was successful
      setDocuments((prevDocuments) => {
        return prevDocuments.filter((doc) => {
          if (type === "file") {
            return doc.file_id !== fileId;
          } else {
            return doc;
          }
        });
      });
      toast.success(result.message); // Show success message from the server
    } else {
      // If deletion failed
      toast.error(`Error: ${result.error}`); // Show error message from the server
    }
  })
      .catch((error) => {
        console.error(error);
        toast.error("Error: Not deleted successfully!");
      });
  };

  const handleStarClick = (docId, isStarred) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=564881d2041006cac4fee2b541ac8df1");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      id: docId,
      type: "folder",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const apiEndpoint = isStarred
      ? `${apiUrl}/app/document-manager/actions/unstar`
      : `${apiUrl}/app/document-manager/actions/star`;

    fetch(apiEndpoint, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        toast.success(result.message);
        setDocuments((prevDocuments) =>
          prevDocuments.map((doc) =>
            doc.folder_id === docId
              ? { ...doc, starred: doc.starred === 1 ? 0 : 1 }
              : doc
          )
        );
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        console.error(error);
      });
  };

  const handleMenuClick = (index) => {
    setDropdownVisible((prev) => (prev === index ? null : index));
  };

  const handleMove = (item) => {
    setItemToMove(item);
    setModalVisible(true);
    setDropdownVisible(null);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setItemToMove(null);
    setSelectedFolder(null);
  };

  const handleFolderSelect = (folderId) => {
    setSelectedFolder(folderId);
  };
  const handleConfirmMove = () => {
    if (!selectedFolder) {
      toast.error("Please select a destination folder.");
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=8c4fbba9fd3fdb7e69a06cf4c36d0954"); // Adjust as needed

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      id: itemToMove.file_id || itemToMove.folder_id, // Use the correct ID based on item type
      type: itemToMove.file_id ? "file" : "folder", // Set type based on item
      move_to_id: selectedFolder,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/actions/move`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        toast.success("Moved successfully!");
        // Update documents state here if needed
        setDocuments((prevDocuments) =>
          prevDocuments.filter(
            (doc) =>
              doc.folder_id !== itemToMove.folder_id &&
              doc.file_id !== itemToMove.file_id
          )
        );
        handleModalClose(); // Close the modal after moving
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to move item");
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4 bg-white">
          {/* Image Viewer */}
          {fileBlob && (
            <ReactSimpleImageViewer
              src={[fileBlob]}
              currentIndex={0}
              onClose={() => setFileBlob(null)}
              disableScroll={true}
            />
          )}

          {navigationPath.length > 0 && (
            <button
              onClick={handleBackClick}
              className="mb-4 px-4 py-2 bg-primary text-white rounded"
            >
              Back
            </button>
          )}

          {documents.length === 0 ? (
            <EmptyDocuments />
          ) : (
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left p-2 border-b border-gray-300">
                    Name
                  </th>
                  <th className="text-left p-2 border-b border-gray-300">
                    Shared With
                  </th>
                  <th className="text-left p-2 border-b border-gray-300">
                    Last Updated
                  </th>
                  <th className="text-left p-2 border-b border-gray-300"></th>
                </tr>
              </thead>
              <tbody>
                {documents.map((doc, index) => (
                  <tr key={index} className="border-b border-gray-300">
                    <td className="p-2">
                      <div className="flex items-center cursor-pointer hover:translate-x-[105]">
                        <div className="flex gap-2 mt-1">
                          <span className="mt-1">{doc.star}</span>
                          <img
                            src={
                              doc.starred === 1
                                ? "icons/yellowstar.svg"
                                : "icons/star.svg"
                            }
                            alt={
                              doc.starred === 1 ? "Yellow Star" : "Folder Icon"
                            }
                            width={25}
                            className="cursor-pointer"
                            onClick={() =>
                              handleStarClick(doc.folder_id, doc.starred === 1)
                            }
                          />
                          <img
                            src="icons/folder.svg"
                            alt="Folder Icon"
                            width={25}
                          />
                          <span className="mt-1">{doc.name}</span>
                          <img
                            src="icons/sysfolder.svg"
                            alt="Sys Folder Icon"
                            width={25}
                          />
                          <span
                            className="text-gray-500"
                            onClick={() =>
                              doc.file_id
                                ? handleFileClick(doc.file_id, doc.file_name)
                                : handleFolderClick(doc.folder_id)
                            }
                          >
                            {doc.folder_name || doc.file_name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="p-2">{doc.sharedWith}</td>
                    <td className="p-2">{doc.lastUpdated}</td>
                    <td className="p-2 flex gap-1 relative">
                      <img
                        src="icons/delete.svg"
                        alt="Delete"
                        className="cursor-pointer"
                        onClick={() => deleteFolder(doc.folder_id, doc.file_id)}
                      />
                      <img
                        src="icons/menu.svg"
                        alt="Menu"
                        className="cursor-pointer"
                        onClick={() => handleMenuClick(index)}
                      />
                      {dropdownVisible === index && (
                        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded">
                          <ul>
                            <li
                              onClick={() => handleMove(doc)}
                              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            >
                              Move
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {/* Move Modal */}
          {modalVisible && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
              <div className="w-[40%] bg-white p-4 rounded shadow-lg">
                <h2 className="text-xl font-bold mb-4">Move Item</h2>
                <p className="mb-4">Select the destination folder:</p>
                <div className="mb-4">
                  <select
                    className="w-full p-2 border-b border-gray-300 rounded"
                    value={selectedFolder || ""}
                    onChange={(e) => handleFolderSelect(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a folder
                    </option>
                    {destinationFolders.map((folder) => (
                      <option key={folder.folder_id} value={folder.folder_id}>
                        {folder.folder_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    onClick={handleModalClose}
                    className="px-4 py-2 bg-gray-300 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleConfirmMove}
                    className="px-4 py-2 bg-primary text-white rounded"
                  >
                    Move
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default AllDocuments;
