import React from 'react';

const EquipmentTableHeader = () => {
  return (
    <thead>
      <tr className=" border-b">
        <th className="px-4 py-2 text-left text-sm">#</th>
        <th className="px-4 py-2 text-left text-sm">Project</th>
        <th className="px-4 py-2 text-left text-sm">Milestone</th>
        <th className="px-4 py-2 text-left text-sm">WBS</th>
        <th className="px-4 py-2 text-left text-sm">Equipment</th>
        <th className="px-4 py-2 text-left text-sm">Supplier</th>
        <th className="px-4 py-2 text-left text-sm">Started On</th>
        <th className="px-4 py-2 text-left text-sm">Ends On</th>
        <th className="px-4 py-2 text-left text-sm">Charge Frequency</th>
        <th className="px-4 py-2 text-left text-sm">Charge</th>
      </tr>
    </thead>
  );
};

export default EquipmentTableHeader;
