import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../../context/MyContext";
import FiltertTableHeaderAndSearch from "../micro_components.js/FiltertTableHeaderAndSearch";
import { BsThreeDots } from "react-icons/bs";
import { toast } from "react-toastify";
import AddMilestone from "./AddMilestone";
import { Loader } from "../micro_components.js/loader";
import AddWbs from "./AddWbs";
import ImportModal from "../Estimate/ImportModal";
import GanttChart from "./GanttChart";
import { TransformData } from "./GanttChart";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { RiFileCopyLine } from "react-icons/ri";
import { CiImport, CiExport } from "react-icons/ci";

const AllWbs = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const [tableHeaders, setTableHeaders] = useState([
    { name: "Id", value: "id", visible: true },
    { name: "Project", value: "pj_id", visible: true },
    { name: "Milestone", value: "milestone_id", visible: true },
    { name: "WBS", value: "name", visible: true },
    { name: "Description", value: "description", visible: true },
    { name: "Notes", value: "notes", visible: true },
    { name: "Resource People", value: "resource_people", visible: true },
    { name: "Lead Days", value: "lead_days", visible: true },
    { name: "Lag Days", value: "lag_days", visible: true },
    { name: "Original End Date", value: "original_end_date", visible: true },
    { name: "Planned Start Date", value: "planned_start_date", visible: true },
    { name: "Planned End Date", value: "planned_end_date", visible: true },
    { name: "Actual Start Date", value: "actual_start_date", visible: true },
    { name: "Actual End Date", value: "actual_end_date", visible: true },
    { name: "Duration", value: "duration", visible: true },
    { name: "Precedence", value: "precedence", visible: true },
    { name: "Status", value: "status", visible: true },
    { name: "Percent Completion", value: "percent_completion", visible: true },
    { name: "Dependency Type", value: "dependency_type", visible: true },
    { name: "Priority", value: "priority", visible: true },
    { name: "Attachement", value: "attachments", visible: true },
    { name: "Created At ", value: "created_at", visible: true },
    { name: "Created By ", value: "created_by", visible: true },
    { name: "Action", value: "action", visible: true },
  ]);
 
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [activeTab, setActiveTab] = useState("wbs");
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");
  const [GanttChartMilestone,setGanttChartMilestone] = useState([]);
  //console.log("========MilestoneValues",GanttChartMilestone.map((milestone)=>milestone.milestone_id));
  const {
    dataUpdated,
    isLoadingData,
    projectUpdate,
    setProjectUpdate,
    setDataUpdated,
  } = useContext(MyContext);
  const [wbsData, setWbsData] = useState([]);
  const [milestoneSideBarIsOpen, setMilestoneSideBarIsOpen] = useState(false);
  const [wbsSideBarIsOpen, setWbsSideBarIsOpen] = useState(false);
  const [selectedWbsId, setSelectedWbsId] = useState(null);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIdMilestone, setSelectedIdMilestone] = useState(false);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState(null);
  const [selectedRawProjectId, setSelectedRawProjectId] = useState(null);


  const fetchData = async () => {
    if (selectedProjectId) {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      const payload = {
        user_id: user.user_id,
        strict: 1,
        pj_id: selectedProjectId,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${baseUrl}/app/panel/wbss/get-wbs`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Failed to fetch WBS");
        }

        const result = await response.json();
        if (result.success) {
          setWbsData(result.data);
          setProjectUpdate(!projectUpdate);
        } else {
          setWbsData([]);
        }
      } catch (error) {
        console.error("Error fetching WBS:", error);
        toast.error("An error occurred while fetching WBS");
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [selectedProjectId, dataUpdated]);


  debugger




  useEffect(() => {
    const updateSelectedProjectId = () => {
      const storedProjectId = localStorage.getItem("selectedProjectId");
      if (storedProjectId) {
        setSelectedProjectId(storedProjectId);
      }
    };
    updateSelectedProjectId();
    window.addEventListener("storage", updateSelectedProjectId);

    return () => {
      window.removeEventListener("storage", updateSelectedProjectId);
    };
  }, [projectUpdate]);
  useEffect(() => {
    if (wbsData && wbsData.length > 0) {
      const updatedMilestones = wbsData.map((Milestone, index) => {
        return { ...Milestone, index }; 
      });
      setGanttChartMilestone(updatedMilestones);
    }
  }, [wbsData]);
  return (
    <>
      <div>
        <div className="w-[100%] flex space-between p-2 bg-white ">
          <div className="flex items-center justify-center space-x-3 gap-3">
            <div className="flex justify-center items-center gap-2">
              <div className="text-center text-[32px] font-bold capitalize p-2">
                WBS List
              </div>
            </div>
          </div>

          <div className="menu flex space-between justify-center items-center gap-2">
            <div className="relative">
              <Popover
                placement="bottom"
                size="sm"
                isOpen={dropdownIsOpen}
                onOpenChange={(open) => setDropdownIsOpen(null)}
              >
                <PopoverTrigger>
                  <div
                    className=" min-h-[36px] text-center flex justify-center items-center gap-1 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer"
                    onClick={() => setDropdownIsOpen(true)}
                  >
                    <img
                      className="mb-1"
                      height={15}
                      width={15}
                      src="icons/add.svg"
                      alt=""
                    ></img>
                    Add item
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="px-3 py-2 space-y-2">
                    <div className="text-small font-bold cursor-pointer 2xl:text-[20px] flex items-center gap-1">
                      <span
                        onClick={() => {
                          setMilestoneSideBarIsOpen(true);
                          setDropdownIsOpen(null);
                        }}
                      >
                        Add Milestone
                      </span>
                    </div>
                    <div className="text-small font-bold cursor-pointer 2xl:text-[20px] flex items-center gap-1">
                      <span
                        onClick={() => {
                          setDropdownIsOpen(null);
                          setWbsSideBarIsOpen(true);
                        }}
                      >
                        Add Wbs{" "}
                      </span>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
            <div>
              <Popover placement="bottom" size="sm">
                <PopoverTrigger>
                  <div className=" min-h-[36px] text-center flex justify-center items-center gap-1 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer">
                    <RiFileCopyLine size={17} />
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="px-3 py-2 space-y-2">
                    <div
                      className="text-small cursor-pointer 2xl:text-[20px] flex items-center gap-1"
                      onClick={() => setIsModalOpen(true)}
                    >
                      <CiImport size={18} />
                      <span> Import </span>
                    </div>
                    <div className="text-small cursor-pointer 2xl:text-[20px] flex items-center gap-1">
                      <CiExport size={18} />
                      <span>Copy Form</span>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
        <hr className=""></hr>
        <div className="px-7 p-4 flex gap-6  bg-white">
          <div
            className={`cursor-pointer font-bold ${
              activeTab === "wbs" ? "text-blue-600" : ""
            }`}
            onClick={() => setActiveTab("wbs")}
          >
            <span>All Wbs List</span>
            {activeTab === "wbs" && (
              <div className="border-b-2 relative top-4 border-b-blue-600"></div>
            )}
          </div>
          <div
            className={`cursor-pointer font-bold ${
              activeTab === "gantt" ? "text-blue-600" : ""
            }`}
            onClick={() => setActiveTab("gantt")}
          >
            <span>Gantt Chart</span>
            {activeTab === "gantt" && (
              <div className="border-b-2 relative top-4 border-b-blue-600"></div>
            )}
          </div>
        </div>
        <FiltertTableHeaderAndSearch
          activeTab={activeTab}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          setTableHeaders={setTableHeaders}
          tableHeaders={tableHeaders}
        />
        {activeTab === "wbs" && (
          <div className="w-full overflow-x-auto h-[78vh]">
            {isLoadingData ? (
              <Loader />
            ) : (
              <table className="w-full table-auto bg-white">
                <thead className="border">
                  <tr className="border-b">
                    {tableHeaders?.map((header) => (
                      <th
                        key={header}
                        className={`px-4 py-2 text-left text-sm whitespace-nowrap ${
                          header.name === "Action" ? "text-center" : ""
                        }`}
                      >
                        {header.visible && header.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {wbsData && wbsData.length > 0 ? (
                    wbsData.map((Wbs, index) => (
                      <tr key={Wbs.id} className="border">
                        {tableHeaders.map((header) => (
                          <td
                            key={`${Wbs.id}-${header.value}`}
                            className={`px-4 py-2 text-sm whitespace-nowrap ${
                              header.value === "action" ? "text-center" : ""
                            }`}
                          >
                            {header.visible &&
                              header.value !== "action" &&
                              Wbs[header.value]}
                            {header.value === "action" && header.visible && (
                              <Popover
                                isOpen={popoverIsOpen === index}
                                onOpenChange={(open) =>
                                  setPopoverIsOpen(open ? index : null)
                                }
                                placement="bottom"
                                className="rounded-[0px]"
                              >
                                <PopoverTrigger>
                                  <Button
                                    color="white"
                                    onClick={() => setPopoverIsOpen(index)}
                                  >
                                    <BsThreeDots />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent>
                                  <div className="px-3 py-2 space-y-2">
                                    <div
                                      className="text-small cursor-pointer 2xl:text-[20px]"
                                      onClick={() => {
                                        setSelectedMilestoneId(
                                          Wbs.raw_milestone_id
                                        );
                                        setSelectedRawProjectId(Wbs.raw_pj_id);
                                        setSelectedWbsId(Wbs.id);
                                        setDataUpdated(!dataUpdated);
                                        setMilestoneSideBarIsOpen(true);
                                        setPopoverIsOpen(null);
                                      }}
                                    >
                                      Update Milestone
                                    </div>
                                    <div
                                      className="text-small cursor-pointer 2xl:text-[20px]"
                                      onClick={() => {
                                        setSelectedWbsId(Wbs.id);
                                        setSelectedIdMilestone(
                                          Wbs.raw_milestone_id
                                        );
                                        setWbsSideBarIsOpen(true);
                                        setPopoverIsOpen(null);
                                      }}
                                    >
                                      Update Wbs
                                    </div>
                                    <div
                                      className="text-small text-red-600 cursor-pointer 2xl:text-[20px]"
                                      // onClick={() => {
                                      //   handleDelete(subcontractor.id);
                                      // }}
                                    >
                                      Delete
                                    </div>
                                  </div>
                                </PopoverContent>
                              </Popover>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={tableHeaders.length}
                        className="px-4 py-3 text-center text-sm"
                      >
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        )}
   {activeTab === "gantt" && (
  <>
    <GanttChart 
     GanttChartMilestone={GanttChartMilestone} 
    />

  </>
)}
        <AddMilestone
          rawMilestoneId={selectedIdMilestone}
          selectedProjectId={selectedProjectId}
          milestoneSideBarIsOpen={milestoneSideBarIsOpen}
          setMilestoneSideBarIsOpen={setMilestoneSideBarIsOpen}
          selectedMilestoneId={selectedMilestoneId}
          setSelectedMilestoneId={setSelectedMilestoneId}
          selectedRawProjectId={selectedRawProjectId}
        />
        <AddWbs
          selectedProjectId={selectedProjectId}
          wbsSideBarIsOpen={wbsSideBarIsOpen}
          setWbsSideBarIsOpen={setWbsSideBarIsOpen}
          selectedWbsId={selectedWbsId}
          setSelectedWbsId={setSelectedWbsId}
        />
      </div>
      <ImportModal
        isOpen={isModalOpen}
        // onClose={() => setIsModalOpen(false)}
        // handleFileChange={handleFileChange}
        // handleImport={handleImport}
      />
    </>
  );
};

export default AllWbs;
