import React, { useState } from "react";
import "../App.css";

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const sendEmail = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message,
      }),
    };

    fetch("https://abdirashids10.sg-host.com/sendEmail", requestOptions)
      .then((response) => {
        if (response.ok) {
          console.log("Email sent!");
          // Optionally reset the form fields
          setFormData({
            name: "",
            email: "",
            message: "",
          });
        } else {
          console.error("Failed to send email");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <section className="section blue-bg flex-column py-0" id="contact">
      <div className="divi">
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form className="contact-module">
            <div className="input-cn">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name..."
                required
              />
            </div>

            <div className="input-cn">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email..."
                required
              />
            </div>

            <div className="input-cn">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message..."
                rows="5"
                required
              ></textarea>
            </div>

            <button
              className="btn btn-outline"
              type="button"
              onClick={sendEmail}
              style={{ width: "100%" }}
            >
              Send Email
            </button>
          </form>
        </div>
        <div className="line white-bg"></div>
        <div className="outcheck">
          {[
            {
              imgSrc: "icons/check.png",
              title: "Experienced Builders",
              text: "With our extensive experience in construction and project management, we confidently handle diverse projects. We prioritize hiring exceptional staff with strong work ethics and advanced skills, ensuring the highest level of quality and professionalism in all our endeavors.",
            },
            {
              imgSrc: "icons/check.png",
              title: "Our Services",
              text: "We specialize in construction building, focusing on turning your ideas into reality. Trust us to create exceptional structures that exceed expectations, ensuring client satisfaction and happiness at every step of the process.",
            },
            {
              imgSrc: "icons/check.png",
              title: "Coverage",
              text: "Although our base is in Garoowe, we are open to undertaking building projects in various cities throughout Somalia, including Mogadishu, Bosaso, and Gaalkacyo.",
            },
          ].map((check, index) => (
            <div className="check-module" key={index}>
              <img src={check.imgSrc} alt="Check Mark" />
              <div className="txt-module">
                <h4>{check.title}</h4>
                <p>{check.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
