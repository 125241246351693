// src/components/AboutUs.js
import React from "react";
import "../App.css";

export const AboutUs = () => {
  return (
    <section className="section grey-bg" id="about">
      <div className="w-7 flex-column">
        <h2>About Us</h2>
        <div className="cards-container flex space-between">
          <div className="card">
            <p
              style={{
                textAlign: "justify",
                wordBreak: "break-word",
                width: "60%",
              }}
            >
              We are dedicated to developing exceptional properties that enhance
              communities and provide value to investors and users. With
              expertise in constructing new build homes, we are committed to
              delivering high-quality results. Our specialization in commercial
              construction allows us to provide turnkey solutions tailored to
              our clients' unique business requirements. From start to finish,
              we ensure a seamless and efficient process, exceeding expectations
              and creating spaces that contribute to the growth and success of
              your community or business. Trust us to bring your vision to life,
              as we strive to make a positive impact through our exceptional
              property developments.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
