import React from "react";
import { HiMiniArrowLeft } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const InvoiceForm = () => {
  const Navigate=useNavigate();
  const handleNavigate=()=>{
    Navigate(-1);
  }

  return (
    <div className="p-7 bg-white shadow-md rounded-md">
      <header className="mb-4 flex items-center gap-2">
         <HiMiniArrowLeft className="cursor-pointer" size={25} onClick={handleNavigate}/>
        <h1 className="font-bold">Create Invoice</h1>
      </header>
      <hr></hr>
      <div className="grid grid-cols-3 gap-4 mb-2 p-7">
        <div>
          <label className="block text-sm font-medium">Project</label>
          <select className="text-gray-500 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white focus:border-blue-500 sm:text-sm">
            <option>None</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium ">Milestone</label>
          <select className="text-gray-500 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md  focus:border-blue-500 sm:text-sm">
            <option>None</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium ">WBS</label>
          <select className="text-gray-500 mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md  focus:border-blue-500 sm:text-sm">
            <option>None</option>
          </select>
        </div>
      </div>

      <div className="pl-7">
        <div className="mb-8 ">
          <div className="flex items-center gap-2">
            <label className="block font-medium text-lg">From</label>
            <select className="text-gray-500 mt-1 block w-[30%] py-1 px-3 border border-gray-300 bg-white  focus:border-blue-500 sm:text-sm mb-2">
              <option>Waaberi Group Company</option>
            </select>
          </div>
          <input
            type="text"
            className="mt-1 block w-[35%] py-1 px-3 border border-gray-300 bg-white    sm:text-sm mb-2"
            placeholder="Abdallah Adan Shire"
          />
          <input
            type="text"
            className="mt-1 block w-[35%]  py-1 px-3 border border-gray-300 bg-white    focus:border-blue-500 sm:text-sm mb-2"
            placeholder="907790122"
          />
          <input
            type="text"
            className="mt-1 block w-[35%]  py-1 px-3 border border-gray-300 bg-white   focus:border-blue-500 sm:text-sm mb-2"
            placeholder="Garowa, Somalia"
          />
        </div>

        <div className="grid grid-cols-5 gap-4 mb-10">
          <div>
            <select className="  w-full py-2 px-3 border border-gray-300 bg-white rounded-md   focus:border-blue-500 sm:text-sm">
              <option>SANITARY</option>
            </select>
          </div>
          <div>
            <select className=" w-full py-2 px-3  border border-gray-300 bg-white rounded-md   focus:border-blue-500 sm:text-sm">
              <option>WC</option>
            </select>
          </div>
          <div>
            <select className="   w-full py-2 px-3 border border-gray-300 bg-white rounded-md   focus:border-blue-500 sm:text-sm">
              <option>Price</option>
            </select>
          </div>
          <div>
            <select className=" w-full py-2 px-3 border border-gray-300 bg-white rounded-md  focus:outline-none  focus:border-blue-500 sm:text-sm">
              <option>Qty</option>
            </select>
          </div>
          <div className="flex items-end">
            <button className="text-sm w-full py-2 px-4 bg-primary text-white font-semibold rounded-md  ">
              Add Item
            </button>
          </div>
        </div>

        <div className="overflow-x-auto mb-10">
          <table className="min-w-full bg-white border">
            <thead>
              <tr className="w-full">
                <td className="pr-4 pl-2 py-1 border">No.</td>
                <td className="pr-96 pl-2 py-1 border">Item Name</td>
                <td className="pr-4 pl-2 py-1 border">Price</td>
                <td className="pr-4 pl-2 py-1 border">Qty</td>
                <td className="pr-4 pl-2 py-1 border">Total</td>
                <td className="pr-4 pl-2 py-1 border">Delete</td>
              </tr>
            </thead>
            <tbody>{/* Map through invoice items and render rows */}</tbody>
          </table>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <select className=" block w-full py-2 px-3 border border-gray-300 bg-white rounded-md  sm:text-sm mb-2">
              <option>Pending</option>
            </select>
            <div className="rounded border-2 ">
              <textarea
                className="mt-1 py-2 px-3 block w-full border border-gray-300 bg-white rounded-md   sm:text-sm mb-2"
                placeholder="None"
                rows="3"
              />
            </div>
            <button className="w-full mt-4 py-3 px-4 bg-primary text-white font-semibold rounded-md ">
              Add Invoice
            </button>
          </div>
          <div className="flex flex-col gap-4 ml-28">
            <div className="flex justify-start items-center gap-2">
              <span className="text-sm font-medium min-w-[25%] text-gray-500 text-right">
                Subtotal:
              </span>
              <div className="border border-gray-200 w-full rounded flex items-center">
                <div className="border bg-gray-200 py-[2px] w-6 flex justify-center">
                  <span className="text-gray-500">$</span>
                </div>
                <span className="text-sm pl-2 text-gray-500"> 0.00</span>
              </div>
            </div>
            <div className="flex justify-start items-center gap-2">
              <span className="text-sm font-medium min-w-[25%] text-gray-500 text-right">
                Tax Rate:
              </span>
              <div className="border border-gray-200 w-full rounded flex items-center">
                <div className="border bg-gray-200  py-[2px] w-6 flex justify-center">
                  <span className="text-gray-500">%</span>
                </div>
                <span className="text-sm pl-2 text-gray-500"> 0</span>
              </div>
            </div>
            <div className="flex justify-start items-center gap-2">
              <span className="text-sm font-medium min-w-[25%] text-gray-500 text-right">
                Tax Amount:
              </span>
              <div className="border border-gray-200 w-full rounded flex items-center">
                <div className="border bg-gray-200  py-[2px] w-6 flex justify-center">
                  <span className="text-gray-500">$</span>
                </div>
                <span className="text-sm pl-2 text-gray-500"> 0</span>
              </div>
            </div>
            <div className="flex justify-start items-center gap-2">
              <span className="text-sm font-medium text-gray-500 min-w-[25%] text-right">
                Total:
              </span>
              <div className="border border-gray-200 w-full rounded flex items-center">
                <div className="border bg-gray-200  py-[2px] w-6 flex justify-center">
                  <span className="text-gray-500">$</span>
                </div>
                <span className="text-sm pl-2 text-gray-500"> 0.00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceForm;