import React, { useState, useEffect, useContext } from "react";
import EmptyProjects from "../../components/Project/EmptyProjects";
import { toast } from "react-toastify";
import { Loader } from "../../components/micro_components.js/loader";
import { MyContext } from "../../context/MyContext";
import { CreateProject } from "../../components/Project/CreateProject";
import { BsThreeDots } from "react-icons/bs";
import FiltertTableHeaderAndSearch from "../../components/micro_components.js/FiltertTableHeaderAndSearch";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from "@nextui-org/react";
const AllProjects = ({projects, setProjects }) => {
  const [loading, setLoading] = useState(true);
  const {dataUpdated,setDataUpdated } = useContext(MyContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const [searchQuery, setSearchQuery] = useState("");
  const [tableHeaders, setTableHeaders] = useState([
    { name: "Project ID", value: "id", visible: true },
    { name: "Name", value: "name", visible: true },
    { name: "Client", value: "client", visible: true },
    { name: "Project Type", value: "projectType", visible: true },
    { name: "Start Date	", value: "start_date", visible: true },
    { name: "End Date", value: "end_date", visible: true },
    { name: "Status", value: "status", visible: true },
    { name: "Action", value: "action", visible: true },
  ]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };
  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };
  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Cookie", "PHPSESSID=8c4fbba9fd3fdb7e69a06cf4c36d0954");
      const raw = JSON.stringify({
        user_id: user.user_id.toString(),
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `${apiUrl}/app/panel/projects/all-projects`,
          requestOptions
        );
        const result = await response.json();
        console.log("API Result:", result);
        if (result.success) {
          setProjects(result.data);
        } else {
          toast.error(result.error);
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch projects");
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, [apiUrl, setProjects,dataUpdated]);
  const handleDelete = async (id) => {
    const payload = {
      user_id: user.user_id,
      record_id: id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/projects/delete-project`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Project deleted successfully");
        setDataUpdated(!dataUpdated);
      }

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Error deleting the project:", error);
    }
  };

  return (
    <div className="bg-gray-100 max-h-85 overflow-y-auto">
      {loading ? (
        <Loader />
      ) : projects.length === 0 ? (
        <EmptyProjects />
      ) : (
        <>
          <FiltertTableHeaderAndSearch
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            setTableHeaders={setTableHeaders}
            tableHeaders={tableHeaders}
          />
          <div className="bg-white shadow-md w-full mx-auto h-[100vh] ml-0 relative">
            <table className="min-w-full bg-white">
              <thead>
                {tableHeaders?.map((header) => (
                  <th
                    key={header}
                    className={`px-4 py-2 text-left text-sm border-b ${
                      header.name === "Action" ? "text-center" : ""
                    }`}
                  >
                    {header.visible && header.name}
                  </th>
                ))}
              </thead>
              <tbody>
                {Array.isArray(projects) &&
                  projects?.map((project) => (
                    <tr key={project.id} className="border-b">
                      {tableHeaders.map((header) => (
                        <td
                          key={`${project.id}-${header.value}`} 
                          className={`px-4 py-2 text-sm ${
                            header.value === "action" ? "text-center" : ""
                          }`}
                        >
                          {header.value === "id" &&
                            header.visible &&
                            project.id}
                          {header.value === "name" &&
                            header.visible &&
                            project.name}
                          {header.value === "client" &&
                            header.visible &&
                            project.client}
                          {header.value === "projectType" &&
                            header.visible &&
                            project.projectType}
                          {header.value === "start_date" &&
                            header.visible &&
                            project.start_date}
                          {header.value === "end_date" &&
                            header.visible &&
                            project.end_date}

                          {header.value === "status" &&
                            header.visible &&
                            project.status}
                          {header.value === "action" && header.visible && (
                            <Popover
                              placement="bottom"
                              showArrow={true}
                              className="rounded-[0px]"
                            >
                              <PopoverTrigger>
                                <Button color="white">
                                  <BsThreeDots />
                                </Button>
                              </PopoverTrigger>
                              <PopoverContent>
                                <div className="px-3 py-2 space-y-2">
                                  <div
                                    className="text-small cursor-pointer 2xl:text-[20px]"
                                    onClick={() => {
                                      handleOpenModal(project);
                                    }}
                                  >
                                    Update
                                  </div>
                                  <div
                                    className="text-small text-red-600 cursor-pointer 2xl:text-[20px]"
                                    onClick={() => {
                                      handleDelete(project.id);
                                    }}
                                  >
                                    Delete
                                  </div>
                                </div>
                              </PopoverContent>
                            </Popover>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
            {isModalOpen && selectedProject && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75"></div>
                <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full h-full">
                  <div className="p-6">
                    <CreateProject
                      handleCloseModal={handleCloseModal}
                      project={selectedProject}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default AllProjects;