import React, { useState ,useContext} from "react";
import EntryHeader from "../micro_components.js/EntryHeader";
import { toast } from "react-toastify";
import SaveIcon from "../../assets/icons/save.svg"
import { MyContext } from "../../context/MyContext";
const apiUrl = process.env.REACT_APP_API_URL;

function AddMilestone({ setMilestoneSidebarOpen,projectId }) {
  const [project, setProject] = useState(projectId);
  const [mile, setMileStone] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const { projects } = useContext(MyContext);
 

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!user || !token) {
      console.error("User or token not found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Cookie", "PHPSESSID=4fdc3ec84499a9c530a672fff975a85f");

    const formData = new FormData();
    formData.append("user_id", user.user_id);
    formData.append("pj_id", project);
    formData.append("name", mile);
    formData.append("description", description);
    formData.append("notes", "");
    if (file) {
      formData.append("attachments[]", file, file.name);
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/panel/milestones/add-milestone`, requestOptions)
      .then((response) => response.json()) 
      .then((result) => {
        console.log(result);
        if (result.success) {
          toast.success(result.message);
          setMilestoneSidebarOpen(false);
        } else {
          toast.error(result.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Optionally handle the error
      });
  };

  return (
    <div>
      <div id="main" className="flex">
        <div className="bg-white shadow-md h-[100vh] rounded-md p-4 flex-1">
          <EntryHeader
            value={"Add Milestone"}
            setMilestoneSidebarOpen={setMilestoneSidebarOpen}
          />
          <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="project"
              className="block text-sm font-medium text-gray-700 mb-3"
            >
              Project
            </label>
            <select
              id="project"
              name="project"
              value={project}
              disabled
              className="block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            >
              <option value="" disabled>
                Select a project 
              </option>
              {projects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
            {/* <div className="mb-4">
              <label
                htmlFor="project"
                className="block text-sm font-medium text-gray-700"
              >
                Project
              </label>
              <input
                placeholder="Construction Schedule"
                id="project"
                value={project}
                onChange={(e) => setProject(e.target.value)}
                className="mt-1 w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:border-blue-500 sm:text-sm"
              />
            </div> */}
            <div className="mb-4">
              <label
                htmlFor="milestoneName"
                className="block text-sm font-medium text-gray-700"
              >
                Milestone Name
              </label>
              <input
                placeholder="Milestone Name"
                type="text"
                id="milestone"
                value={mile}
                onChange={(e) => setMileStone(e.target.value)}
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description{" "}
                <span className="text-sm font-thin">(optional)</span>
              </label>
              <input
                placeholder="Extra Details"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-1 w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="file"
                className="block text-sm font-medium text-gray-700"
              >
                File <span className="text-sm font-thin">(optional)</span>
              </label>
              <input
                type="file"
                name="projectFile"
                onChange={handleFileChange}
                className="mt-1 block w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 cursor-pointer focus:outline-none focus:ring-indigo-500 focus:border-primary p-2"
              />
            </div>
            <button
              type="submit"
              className="bg-primary mt-32 text-white px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center gap-1 h-[48px] w-full"
            >
              <img src={SaveIcon} alt="Save Icon" />
              <span className="mt-1">Add Milestone</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddMilestone;
