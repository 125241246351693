import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import SearchInput from "./SearchInput";
import { BsArrowsExpand } from "react-icons/bs";
import TableDataReorders from "../micro_components.js/TableDataReorders"

const apiUrl = process.env.REACT_APP_API_URL;

const typeOptions = [
  { value: "material", label: "Material" },
  { value: "labour", label: "Labour" },
  { value: "equipment", label: "Equipment" },
  { value: "subcontractor", label: "Subcontractor" },
];
const SecondHeader = ({
  setExpandedStages,
  setEstimationId,
  setTotalItems,
  setData,
  data,
  expandedStages,
  allExpandedRows,
  setAllExpandedRows,
  tableHeaders,
  sensors,
  handleDragEnd,
  handleCheckBox,
}) => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const handleExpandCollapseAll = () => {
    const newExpandedStages = {};
    if (allExpandedRows) {
      Object.keys(expandedStages).forEach((key) => {
        newExpandedStages[key] = false;
      });
    } else {
      data.forEach((group, index) => {
        const stageName = `stage-${index}`;
        newExpandedStages[stageName] = true;
      });
    }
    setExpandedStages(newExpandedStages);
    setAllExpandedRows(!allExpandedRows);
  }
  const handleTypeChange = (selectedOptions) => {
    const types = selectedOptions.map((option) => option.value);
    setSelectedTypes(types);
  };

  const divEl = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(event.target)) {
        setDisplayOptions(false);
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  useEffect(() => {
    const handleSearchEstimation = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");

      if (!user || !token) {
        console.error("User or token not found in localStorage");
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      let searchQuery = "";

      if (selectedTypes.length === 0) {
        searchQuery = typeOptions.map((option) => option.value).join(", ");
      } else {
        searchQuery = selectedTypes.join(", ");
      }

      const raw = JSON.stringify({
        user_id: user.user_id.toString(),
        project: "4",
        search: searchQuery,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${apiUrl}/app/panel/project-estimations/search-estimation`,
          requestOptions
        );

        if (!response.ok) {
          throw new Error("Failed to search estimation");
        }

        const result = await response.json();
        const fetchedData = JSON.parse(result.data); 
        console.log(">>>>>>>>>>>>>>>.",fetchedData);
        setData(fetchedData); 
        setTotalItems(fetchedData.length);
        setEstimationId(fetchedData[0]?.material?.estimation_id); 
      } catch (error) {
        console.error("Error searching estimation:", error);
      }
    };

    handleSearchEstimation();
  }, [selectedTypes]);

  return (
    <div>
      <div className="flex items-center space-x-4 p-2 grayscale relative flex-wrap z-20 border">
        <div
          className="flex gap-2 cursor-pointer"
          onClick={() => setDisplayOptions(!displayOptions)}
        >
          <img src="icons/select.svg" alt="" />
          <span>Select</span>
          {/* <img src="icons/dropdown.svg" alt="" /> */}
        </div>

        <div
          className="flex gap-2 items-center cursor-pointer"
          onClick={handleExpandCollapseAll}
        >
          {allExpandedRows ?<img src="icons/collapse.svg" alt=""/>:<BsArrowsExpand/>}
          <span className="text-nowrap">
            {allExpandedRows ? "Collapse All" : "Expand All"}
          </span>
        </div>
        <SearchInput placeholder="Search here" setSearchQuery={setSearchQuery}/>
        <SearchInput placeholder="Status" setSearchQuery={setSearchQuery}/>
        <div className="relative flex items-center">
          <Select
            isMulti
            options={typeOptions}
            className="basic-multi-select min-w-[228px]"
            classNamePrefix="select"
            onChange={handleTypeChange}
            placeholder="Select Types"
          />
        </div>
      </div>
      <div className="relative z-20" ref={divEl}>
      {displayOptions && (
          <div className="absolute top-[-1rem] z-10 left-[2rem] bg-white border shadow-md rounded-md p-3 w-30">
            <TableDataReorders
              tableHeaders={tableHeaders}
              sensors={sensors}
              handleDragEnd={handleDragEnd}
              handleCheckBox={handleCheckBox}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SecondHeader;














