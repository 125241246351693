import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SelectController from "../micro_components.js/SelectController";




// Roles Refrence for Users
// 1 = 'Admin';
// 2 = 'Manager';
// 3 = 'Engineer';
// 4 = 'Purchaser';
// 5 = 'Employee';
// 6 = 'Client';




export const CreateProject = ({ handleCloseModal, setIsModalOpen ,project}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [roleWiseUser , setRoleWiseUser] = useState({})
  const [formValues, setFormValues] = useState({
    name: "",
    cost: "",
    client: "",
    description: "",
    location: "",
    startDate: "",
    notes: "",
    projectManager: "",
    endDate: "",
    budget: "",
    projectEngineer: "",
    attachments: null,
  });
  

  useEffect(() => {
    if(project){
      setFormValues({
        ...project,
        client:project.raw_client,
        projectManager : project.raw_manager,
        projectEngineer : project.raw_engineer
      })}
    fetchAllUser()
  }, []);

  const fetchAllUser = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");
    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: raw,
      redirect: "follow",
    };
    fetch(`${apiUrl}/app/panel/users/select-users`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          const transformedData = result.data?.map((data) => ({
            id: data.id,
            name: data.first_name + ' ' + data.middle_name + ' ' + data.last_name, 
            role : data.role
          }));

          const groupedByRole = {}
          transformedData?.forEach(obj => {
            const role = obj.role;
            if (!groupedByRole[role]) {
                groupedByRole[role] = [];
            }
            groupedByRole[role].push(obj);
        });
        setRoleWiseUser(groupedByRole)
        } 
      })
      .catch((error) => console.error(error));
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormValues({
      ...formValues,
      attachments: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
  
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");
  
      const myHeaders = new Headers();
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Authorization", token);
      myHeaders.append("Cookie", "PHPSESSID=b8446925257e4bbe56440fd1f36bbae5");
  
      const formdata = new FormData();
      formdata.append("user_id", user.user_id.toString());
      formdata.append("name", formValues.name);
      formdata.append("cost", formValues.cost);
      formdata.append("budget", formValues.budget);
      formdata.append("manager", formValues.projectManager);
      formdata.append("client", formValues.client);
      formdata.append("description", formValues.description);
      formdata.append("notes", formValues.notes);
      formdata.append("location", formValues.location);
      formdata.append("engineer", formValues.projectEngineer);
      formdata.append("attachments[]", formValues.attachments);
  
      if (project) {
        formdata.append("record_id", project.id);
      }
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
  
      const response = await fetch(
        `${apiUrl}/app/panel/projects/${project ? "update-project" : "add-project"}`,
        requestOptions
      );
      const result = await response.json();
  
      if (result.success) {
        toast.success(result.message);
        handleCloseModal();
      } else {
        toast.error(result.error);
      }
  
      console.log(result);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while processing your request.");
    }
  };
  

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100 w-full h-full">
      <div className="bg-white rounded-md shadow-md w-full h-full">
        <div className="p-6 flex items-center">
          <img
            src="icons/leftarrow.svg"
            alt="Back"
            onClick={handleCloseModal}
            className="cursor-pointer"
          />
          <h2 className="text-2xl font-bold mb-1">{project ? "Update" : "Create New"} Project</h2>
        </div>
        <div className="border border-b-gray-200"></div>
        <form className="grid grid-cols-4 gap-6 p-6" onSubmit={handleSubmit}>
          {/* Column 1 */}
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Project Name
            </label>
            <input
              type="text"
              name="name"
              required
              value={formValues.name}
              onChange={handleChange}
              placeholder="Enter project name"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Project Cost (estimate)
            </label>
            <input
              type="number"
              name="cost"
              required
              value={formValues.cost}
              onChange={handleChange}
              placeholder="Will be calculated after assigning WBSs"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div>
        
                <SelectController 
                    label="Client"
                    name="client"
                    required={true}
                    selectOptions={roleWiseUser[6]}
                    value={formValues.client}
                    onChange={handleChange}
                    />

          {/* this is for later user... when User APi is ready */}
          {/* <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Client
            </label>
            <select
              name="client"
              required
              value={formValues.client}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            >
              <option value="">Select a client</option>
              {clients.map((client, index) => (
                <option
                  key={index}
                  value={`${client.first_name} ${client.middle_name} ${client.last_name}`}
                >
                  {`${client.first_name} ${client.middle_name} ${client.last_name}`}
                </option>
              ))}
            </select>
          </div> */}

          {/* Column 2 */}
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Description (optional)
            </label>
            <input
              type="text"
              name="description"
              required
              value={formValues.description}
              onChange={handleChange}
              placeholder="Notes on the project"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Location
            </label>
            <input
              type="text"
              name="location"
              required
              value={formValues.location}
              onChange={handleChange}
              placeholder="Location of the project"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div>
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Project Start Date
            </label>
            <input
              type="date"
              name="startDate"
              required
              value={formValues.startDate}
              onChange={handleChange}
              placeholder="mm/dd/yy"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div>

          {/* Column 3 */}
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Notes (optional)
            </label>
            <input
              type="text"
              name="notes"
              required
              value={formValues.notes}
              onChange={handleChange}
              placeholder="Notes on the project"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div>
          {/* <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Project Manager
            </label>
            <input
              type="text"
              name="projectManager"
              required
              value={formValues.projectManager}
              onChange={handleChange}
              placeholder="None"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div> */}
          <SelectController 
                    label="Project Manager"
                    name="projectManager"
                    required={true}
                    selectOptions={roleWiseUser[2]}
                    value={formValues.projectManager}
                    onChange={handleChange}
                    />
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Estimated End Date
            </label>
            <input
              type="date"
              name="endDate"
              required
              value={formValues.endDate}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div>

          {/* Column 4 */}
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Project Budget
            </label>
            <input
              type="number"
              name="budget"
              required
              value={formValues.budget}
              onChange={handleChange}
              placeholder="Could be left blank if assigning WBSs"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div>
          {/* <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Project Engineer
            </label>
            <input
              type="text"
              name="projectEngineer"
              required
              value={formValues.projectEngineer}
              onChange={handleChange}
              placeholder="None"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-primary sm:text-sm"
            />
          </div> */}
           <SelectController 
                    label="Project Engineer"
                    name="projectEngineer"
                    required={true}
                    selectOptions={roleWiseUser[3]}
                    value={formValues.projectEngineer}
                    onChange={handleChange}
                    />
          <div className="col-span-1">
            <label className="block text-sm font-medium text-gray-700">
              Project File
            </label>
            <input
              type="file"
              name="attachments"
              required
              onChange={handleFileChange}
              className="mt-1 block w-full text-sm text-gray-900 bg-gray-50 rounded-md border border-gray-300 cursor-pointer focus:outline-none focus:ring-indigo-500 focus:border-primary p-2"
            />
          </div>

          <div className="col-span-4">
            <hr className="my-4 border-t border-gray-300" />
          </div>

          {/* Buttons */}
          <div className="col-span-4 flex justify-end space-x-4">
            <button
              type="button"
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded-md flex gap-1"
            >
              <img src="icons/save.svg" alt="Save" /> Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

