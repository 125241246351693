import React from 'react'
import { BsThreeDots } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { PiNotepad } from "react-icons/pi";
import Entry from '../../assets/icons/entry.svg'

const EntryHeader = ({value,setMilestoneSidebarOpen}) => {
  return (
    <div id="header" className="pb-7 flex justify-between items-center">
    <div className="flex items-center gap-1">
      <img src={Entry} width={22} height={22}></img>
      <span className="text-lg font-bold mt-2">{value}</span>
    </div>
    <div className="flex gap-2">
    <BsThreeDots size={25}/>
    <RxCross2 className='cursor-pointer' size={25} onClick={()=>setMilestoneSidebarOpen(false)}/>
    </div>
  </div>
  )
}

export default EntryHeader;