import React, { useEffect, useState } from "react";
import Header from "../../components/documents/Header";
import TabNavigation from "../../components/documents/TabNavigation";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [parentFolderId, setParentFolderId] = useState(1);
  const [folderId, setFolderId] = useState(1);
  const [reloadDocuments, setReloadDocuments] = useState(false);

  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none">
          <Header
            documents={documents}
            setDocuments={setDocuments}
            parentFolderId={parentFolderId}
            folderId={folderId}
            setFolderId={setFolderId}
            reloadDocuments={reloadDocuments}
            setReloadDocuments={setReloadDocuments}
          />

          <TabNavigation
            documents={documents}
            setDocuments={setDocuments}
            parentFolderId={parentFolderId}
            setParentFolderId={setParentFolderId}
            folderId={folderId}
            setFolderId={setFolderId}
            reloadDocuments={reloadDocuments}
          />
        </div>
      </div>
    </div>
  );
};

export default Documents;
