import React, { useEffect, useState,useRef } from "react";
import SearchInput from "../Estimate/SearchInput";

const apiUrl = process.env.REACT_APP_API_URL;

export const SecondHeader = ({
  columnFilter,
  setColumnFilter,
  projects,
  setProjects,
}) => {
  const [displayOptions, setDisplayOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const divEl = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (!divEl.current) {
        return;
      }
      if (!divEl.current.contains(event.target)) {
        setDisplayOptions(false);
      }
    };
    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
  }, []);

  useEffect(() => {
    const handleSearchEstimation = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      const token = localStorage.getItem("token");

      if (!user || !token) {
        console.error("User or token not found in localStorage");
        return;
      }

      const myHeaders = new Headers();
      myHeaders.append("Authorization", token);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        user_id: user.user_id.toString(),
        strict: "0",
        name: searchQuery,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      try {
        const response = await fetch(
          `${apiUrl}/app/panel/projects/get-project`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            setProjects(result.data || []);
          })
          .catch((error) => console.error(error));
      } catch (error) {
        console.log("Error searching estimation:", error);
      }
    };

    handleSearchEstimation();
  }, [searchQuery]);

  return (
    <div>
      <div className="flex items-center space-x-4 p-2 grayscale relative flex-wrap z-20 border">
        <div
          className="flex gap-2 cursor-pointer"
          onClick={() => setDisplayOptions(!displayOptions)}
        >
          <img src="icons/select.svg" alt="" />
          <span>Select</span>
          <img src="icons/dropdown.svg" alt="" />
        </div>
        <div className="flex gap-2">
          <img src="icons/collapse.svg" alt="" />
          <span className="text-nowrap">Collapse All</span>
        </div>
        <SearchInput
          placeholder="Search here"
          setSearchQuery={setSearchQuery}
        />
        <SearchInput placeholder="Asignees" setSearchQuery={setSearchQuery} />
        <SearchInput placeholder="Label" setSearchQuery={setSearchQuery} />
      </div>
      <div className="relative z-20">
        {displayOptions && (
          <div ref={divEl} className="absolute top-[-1rem] z-10 left-[2rem] bg-white border shadow-md rounded-md p-3 w-30">
            <ul>
              <div className="flex items-center gap-1">
                <input
                  type="checkbox"
                  checked={columnFilter.all}
                  onChange={() =>
                    setColumnFilter({
                      all: !columnFilter.all,
                      projectId: !columnFilter.all,
                      name: !columnFilter.all,
                      client: !columnFilter.all,
                      projectType: !columnFilter.all,
                      startDate: !columnFilter.all,
                      endDate: !columnFilter.all,
                      status: !columnFilter.all,
                    })
                  }
                  className="w-3 h-3 cursor-pointer text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-primary"
                />
                <span className="text-[12px] mt-1 font-bold">Deselect All</span>
              </div>
              <hr className="w-full border border-gray-300" />
              <div className="flex items-center gap-1">
                <img src="icons/bar.svg" alt="" />
                <input
                  type="checkbox"
                  checked={columnFilter.projectId}
                  onChange={() =>
                    setColumnFilter({
                      ...columnFilter,
                      projectId: !columnFilter.projectId,
                    })
                  }
                  className="w-3 h-3 cursor-pointer text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-primary"
                />
                <span className="mt-1 text-[12px]">Project ID</span>
              </div>
              <div className="flex items-center gap-1">
                <img src="icons/bar.svg" alt="" />
                <input
                  type="checkbox"
                  onChange={() =>
                    setColumnFilter({
                      ...columnFilter,
                      name: !columnFilter.name,
                    })
                  }
                  checked={columnFilter.name}
                  className="w-3 h-3 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-primary cursor-pointer"
                />
                <span className="mt-1 text-[12px]">Name</span>
              </div>
              <div className="flex items-center gap-1">
                <img src="icons/bar.svg" alt="" />
                <input
                  type="checkbox"
                  onChange={() =>
                    setColumnFilter({
                      ...columnFilter,
                      client: !columnFilter.client,
                    })
                  }
                  checked={columnFilter.client}
                  className="w-3 h-3 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-primary cursor-pointer"
                />
                <span className="mt-1 text-[12px]">Client</span>
              </div>
              <div className="flex items-center gap-1">
                <img src="icons/bar.svg" alt="" />
                <input
                  type="checkbox"
                  onChange={() =>
                    setColumnFilter({
                      ...columnFilter,
                      projectType: !columnFilter.projectType,
                    })
                  }
                  checked={columnFilter.projectType}
                  className="w-3 h-3 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-primary cursor-pointer"
                />
                <span className="mt-1 text-[12px]">Project Type</span>
              </div>
              <div className="flex items-center gap-1">
                <img src="icons/bar.svg" alt="" />
                <input
                  type="checkbox"
                  onChange={() =>
                    setColumnFilter({
                      ...columnFilter,
                      startDate: !columnFilter.startDate,
                    })
                  }
                  checked={columnFilter.startDate}
                  className="w-3 h-3 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-primary cursor-pointer"
                />
                <span className="mt-1 text-[12px]">Start Date</span>
              </div>
              <div className="flex items-center gap-1">
                <img src="icons/bar.svg" alt="" />
                <input
                  type="checkbox"
                  onChange={() =>
                    setColumnFilter({
                      ...columnFilter,
                      endDate: !columnFilter.endDate,
                    })
                  }
                  checked={columnFilter.endDate}
                  className="w-3 h-3 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-primary cursor-pointer"
                />
                <span className="mt-1 text-[12px]">End Date</span>
              </div>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
