import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../../context/MyContext";
import FiltertTableHeaderAndSearch from "../micro_components.js/FiltertTableHeaderAndSearch";
import SubcontractorSideBar from "./SubcontractorSideBar";
// import formatfile from "../../assets/dummyformatfile/productformatfile.csv";
import { BsThreeDots } from "react-icons/bs";
import { toast } from "react-toastify";
import ImportModal from "../Estimate/ImportModal";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { RiFileCopyLine } from "react-icons/ri";
import { CiImport, CiExport } from "react-icons/ci";
import { IoCloudDownloadOutline } from "react-icons/io5";

const AllSubcontractorActivites = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const [tableHeaders, setTableHeaders] = useState([
    { name: "Id", value: "id", visible: true },
    { name: "Project", value: "pj_id", visible: true },
    { name: "Milestone", value: "milestone_id", visible: true },
    { name: "WBS", value: "wbs_id", visible: true },
    { name: "Cost Code", value: "cost_code_id", visible: true },
    { name: "Description", value: "description", visible: true },
    { name: "Role ", value: "role", visible: true },
    { name: "Hours Worked ", value: "hours_worked", visible: true },
    { name: "Action", value: "action", visible: true },
  ]);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");
  const { setDataUpdated, dataUpdated } = useContext(MyContext);
  const [subcontractorData, setSubcontractorData] = useState([]);
  const [subcontractorSideBarIsOpen, setSubcontractorSideBarIsOpen] =
    useState(false);
  const [popoverIsOpen, setPopoverIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubcontractorId, setSelectedSubcontractorId] = useState(null);
  //   const [file, setFile] = useState(null);
  //   const handleFileChange = (e) => {
  //     setFile(e.target.files[0]);
  //   };

  //   const handleImport = async () => {
  //     const token = localStorage.getItem("token");
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     if (!file) {
  //       toast.error("Please select a file to import");
  //       return;
  //     }

  //     const myHeaders = new Headers();
  //     myHeaders.append("Access-Control-Allow-Origin", "*");
  //     myHeaders.append("Authorization", token);
  //     myHeaders.append("Cookie", "PHPSESSID=310b506684214d969dd74167dfc96dc1");

  //     const formdata = new FormData();
  //     formdata.append("user_id", user.user_id);
  //     formdata.append("import_file[]", file);

  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: formdata,
  //       redirect: "follow",
  //     };

  //     try {
  //       const response = await fetch(
  //         ${baseUrl}/app/panel/products/import-products,
  //         requestOptions
  //       );

  //       if (!response.ok) {
  //         throw new Error("Failed to import file");
  //       }

  //       const result = await response.text();
  //       console.log(result);
  //       toast.success("Import successful");
  //       setIsModalOpen(false);
  //     } catch (error) {
  //       console.error(error);
  //       toast.error("Failed to import file");
  //     }
  //   };

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      user_id: user.user_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/app/panel/project-tracking/subcontractor-work-activity/all-subcontractor-work-activities`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch subcontractor data");
      }

      const result = await response.json();
      console.log(result);

      if (result.success) {
        setSubcontractorData(result.data);
      } else {
        toast.error("Failed to retrieve data");
      }

      console.log(result);
    } catch (error) {
      console.error("Error fetching subcontractor list:", error);
      toast.error("An error occurred while fetching subcontractor list");
    }
  };
  useEffect(() => {
    fetchData();
  }, [dataUpdated]);

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const payload = {
      user_id: user.user_id,
      record_id: id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/app/panel/project-tracking/subcontractor-work-activity/delete-subcontractor-work-activity`,
        requestOptions
      );

      if (response.ok) {
        toast.success("Subcontractor deleted successfully");
        setDataUpdated(!dataUpdated);
      }

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error("Error deleting the question:", error);
    }
  };
  return (
    <>
      <div>
        <div className="w-full m-auto flex space-between p-2 bg-white">
          <a href="#" className="logo">
            <div className="flex items-center justify-center space-x-3 gap-3">
              <div className="flex justify-center items-center gap-2">
                <div className="text-center text-[32px] font-bold capitalize p-2">
                  Subcontractor Work Activites
                </div>
              </div>
            </div>
          </a>
          <div className="menu flex space-between justify-center items-center gap-2">
            <div className="relative">
              <div
                onClick={() => {
                  setSubcontractorSideBarIsOpen(true);
                }}
                className=" min-h-[36px] text-center flex justify-center items-center gap-1 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer"
              >
                <div>
                  <img
                    className="mb-1"
                    height={15}
                    width={15}
                    src="icons/add.svg"
                    alt=""
                  ></img>
                </div>
                <div className="h-[12px] mb-2">Add Work Activity</div>
              </div>
            </div>
            <div>
              <Popover placement="bottom" size="sm">
                <PopoverTrigger>
                  <div className=" min-h-[36px] text-center flex justify-center items-center gap-1 text-sm p-2 bg-primary text-white rounded-lg hover:bg-blue-900 cursor-pointer">
                    <RiFileCopyLine size={17} />
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="px-3 py-2 space-y-2">
                    <div
                      className="text-small cursor-pointer 2xl:text-[20px] flex items-center gap-1"
                      onClick={() => setIsModalOpen(true)}
                    >
                      <CiImport size={18} />
                      <span> Import </span>
                    </div>
                    <div className="text-small cursor-pointer 2xl:text-[20px] flex items-center gap-1">
                      <CiExport size={18} />
                      <span>Copy Form</span>
                    </div>
                    {/* <a
                      className="text-small cursor-pointer 2xl:text-[20px] flex items-center gap-1"
                      href={formatfile}
                      download="productformatfile.csv"
                    >
                      <IoCloudDownloadOutline size={16} />
                      <span>Download Format File</span>
                    </a> */}
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </div>
        <FiltertTableHeaderAndSearch
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          setTableHeaders={setTableHeaders}
          tableHeaders={tableHeaders}
        />
        <div className="w-full overflow-x-auto h-[65vh]">
          <table className="w-full table-auto bg-white">
            <thead>
              <tr className="border-b">
                {tableHeaders?.map((header) => (
                  <th
                    key={header}
                    className={`px-4 py-2 text-left text-sm  whitespace-nowrap ${
                      header.name === "Action" ? "text-center" : ""
                    }`}
                  >
                    {header.visible && header.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {subcontractorData && subcontractorData.length > 0 ? (
                subcontractorData.map((subcontractor, index) => (
                  <tr key={subcontractor.id} className="border">
                    {tableHeaders.map((header) => (
                      <td
                        key={`${subcontractor.id}-${header.value}`}
                        className={`px-4 py-2 text-sm   ${
                          header.value === "action" ? "text-center" : ""
                        }`}
                      >
                        {header.value === "id" &&
                          header.visible &&
                          subcontractor.id}
                        {header.value === "pj_id" &&
                          header.visible &&
                          subcontractor.pj_id}
                        {header.value === "milestone_id" &&
                          header.visible &&
                          subcontractor.milestone_id}
                        {header.value === "wbs_id" &&
                          header.visible &&
                          subcontractor.wbs_id}
                        {header.value === "cost_code_id" &&
                          header.visible &&
                          subcontractor.cost_code_id}
                        {header.value === "description" &&
                          header.visible &&
                          subcontractor.description}
                        {header.value === "role" &&
                          header.visible &&
                          subcontractor.role}
                        {header.value === "hours_worked" &&
                          header.visible &&
                          subcontractor.hours_worked}
                        {header.value === "action" && header.visible && (
                          <Popover
                            isOpen={popoverIsOpen === index}
                            onOpenChange={(open) =>
                              setPopoverIsOpen(open ? index : null)
                            }
                            placement="bottom"
                            className="rounded-[0px]"
                          >
                            <PopoverTrigger>
                              <Button
                                color="white"
                                onClick={() => setPopoverIsOpen(index)}
                              >
                                <BsThreeDots />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <div className="px-3 py-2 space-y-2">
                                <div
                                  className="text-small cursor-pointer 2xl:text-[20px]"
                                  onClick={() => {
                                    setSelectedSubcontractorId(
                                      subcontractor.id
                                    );
                                    setSubcontractorSideBarIsOpen(true);
                                    setPopoverIsOpen(null);
                                  }}
                                >
                                  Update
                                </div>
                                <div
                                  className="text-small text-red-600 cursor-pointer 2xl:text-[20px]"
                                  onClick={() => {
                                    handleDelete(subcontractor.id);
                                    setPopoverIsOpen(null);
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            </PopoverContent>
                          </Popover>
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tableHeaders.length}
                    className="px-4 py-3 text-center text-sm"
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <SubcontractorSideBar
          subcontractorSideBarIsOpen={subcontractorSideBarIsOpen}
          setSubcontractorSideBarIsOpen={setSubcontractorSideBarIsOpen}
          selectedSubcontractorId={selectedSubcontractorId}
          setSelectedSubcontractorId={setSelectedSubcontractorId}
        />
      </div>
      <ImportModal
        isOpen={isModalOpen}
        // onClose={() => setIsModalOpen(false)}
        // handleFileChange={handleFileChange}
        // handleImport={handleImport}
      />
    </>
  );
};

export default AllSubcontractorActivites;