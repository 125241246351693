import React, { useEffect, useState, useContext } from "react";
import "../../index.css";
import EntryIcon from "../../assets/icons/entry.svg";
import InputController from "../micro_components.js/InputController";
import { toast } from "react-toastify";
import { MyContext } from "../../context/MyContext";
import SelectController from "../micro_components.js/SelectController";

function AddMilestone({
  selectedRawProjectId,
  milestoneSideBarIsOpen,
  setMilestoneSideBarIsOpen,
  selectedMilestoneId,
  setSelectedMilestoneId,
  selectedProjectId,
}) {
  const baseUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const { setDataUpdated, dataUpdated, projects } = useContext(MyContext);
  const initialMilestoneValues={
    pj_id: "",
    name: "",
    description: "",
    notes: "",
    attachments: [],
  }
  const [milestoneValues, setMilestoneValues] = useState(initialMilestoneValues);
  const resetMilestoneValues = () => {
    setMilestoneValues(initialMilestoneValues);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMilestoneValues({
      ...milestoneValues,
      [name]: value,
    });
  };
  const handleFileChange = (e) => {
    const files = e.target.files;
    setMilestoneValues({
      ...milestoneValues,
      attachments: Array.from(files),
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("user_id", user?.user_id);
    formdata.append("pj_id", selectedProjectId);
    formdata.append("name", milestoneValues.name);
    formdata.append("description", milestoneValues.description);
    formdata.append("notes", milestoneValues.notes);
    if (milestoneValues.attachments.length > 0) {
      for (let i = 0; i < milestoneValues.attachments.length; i++) {
        formdata.append("attachments[1]", milestoneValues.attachments[i]);
      }
    }

    if (selectedMilestoneId) {
      formdata.append("record_id", selectedMilestoneId);
    }

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formdata,
      redirect: "follow",
    };

    try {
      let response;

      if (selectedMilestoneId) {
        response = await fetch(
          `${baseUrl}/app/panel/milestones/update-milestone`,
          requestOptions
        );
      } else {
        response = await fetch(
          `${baseUrl}/app/panel/milestones/add-milestone`,
          requestOptions
        );
      }

      if (!response.ok) {
        throw new Error("Failed to add Milestone");
      }

      const result = await response.json();
      if (result.success) {
        toast.success(
          `Milestone ${selectedMilestoneId ? "updated" : "added"} successfully`
        );
        setMilestoneSideBarIsOpen(false);
        setSelectedMilestoneId(null);
        setDataUpdated((prev) => !prev);
        resetMilestoneValues();
      } else {
        toast.error(result.error);
      }
    } catch (error) {
      console.error("Error adding subcontractor:", error);
      toast.error("An error occurred while adding the subcontractor");
    }
  };

  useEffect(() => {
    if (selectedRawProjectId) {
      fetchSingleWbs();
    }
  }, [selectedRawProjectId, dataUpdated]);

  const fetchSingleWbs = async () => {
    const payload = {
      user_id: user.user_id,
      strict: 1,
      pj_id: selectedRawProjectId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${baseUrl}/app/panel/milestones/get-milestone`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch WBS");
      }

      const result = await response.json();
      if (result.success) {
        setMilestoneValues(result.data[0]);
      } else {
        toast.error("Failed to retrieve data");
      }
    } catch (error) {
      console.error("Error fetching WBS:", error);
      toast.error("An error occurred while fetching WBS");
    }
  };

  return (
    <div className="relative">
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${
          milestoneSideBarIsOpen ? "translate-x-0" : "translate-x-full"
        }`}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <div className="relative">
            <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
              <div id="main" className="flex">
                <div className="bg-white shadow-md overflow-auto h-[100vh] rounded-md p-4 flex-1">
                  <div
                    id="header"
                    className="pb-7 flex justify-between items-center"
                  >
                    <div className="flex items-center gap-1">
                      <img src={EntryIcon} alt="Entry Icon" />
                      <span className="text-lg font-bold mt-2">
                        {selectedRawProjectId
                          ? "Update Milestone"
                          : "Add Milestone"}
                      </span>
                    </div>
                    <div className="flex gap-1">
                      <img
                        src="icons/menu.svg"
                        className="cursor-pointer"
                        alt="Menu Icon"
                      />
                      <img
                        src="icons/cross.svg"
                        className="cursor-pointer"
                        alt="Cross Icon"
                        onClick={() => {
                          setMilestoneSideBarIsOpen(false);
                          setSelectedMilestoneId(null);
                          resetMilestoneValues();
                        }}
                      />
                    </div>
                  </div>
                  <div className="border border-b-gray-300 mb-3"></div>
                  <form className="space-y-4" onSubmit={handleSubmit}>
                    <SelectController
                      label="Project"
                      name="pj_id"
                      required={true}
                      selectOptions={projects}
                      disabled={true}
                      value={selectedProjectId}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Milestone"
                      type="text"
                      name="name"
                      value={milestoneValues.name}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Description"
                      type="text"
                      name="description"
                      value={milestoneValues.description}
                      onChange={handleChange}
                    />
                    <InputController
                      label="Notes"
                      type="text"
                      name="notes"
                      value={milestoneValues.notes}
                      onChange={handleChange}
                    />
                    <div>
                      <InputController
                        label="Attachments"
                        type="file"
                        name="attachments"
                        onChange={handleFileChange}
                        multiple
                      />
                    </div>
                    <button
                      className="w-full bg-primary text-white font-medium py-2 px-4 rounded hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                      type="submit"
                    >
                      {selectedMilestoneId ? "Update" : "Add"} Milestone
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddMilestone;
