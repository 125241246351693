import AddMilestone from "./AddMilestone";
function CanvasSideBar(props) {
  const {
    milestoneSidebarOpen,
    setMilestoneSidebarOpen,
    projectId
  } = props;
  const closeSidebar = (event, name) => {
 
    if (event.target.closest(".sidebar-content")) {
      return;
    }
    if (name === "milestone") {
        setMilestoneSidebarOpen(false);
    }
  };

  return (
    <div className="relative">
   
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-50 transition-transform duration-300 ease-in-out transform ${milestoneSidebarOpen ? "translate-x-0" : "translate-x-full"}`}
        onClick={(event) => closeSidebar(event, "milestone")}
        tabIndex="-1"
      >
        <div className="sidebar-content fixed inset-y-0 right-0 w-[30%] bg-white shadow-md">
          <AddMilestone
            setMilestoneSidebarOpen={setMilestoneSidebarOpen}
            projectId={projectId}
          />
        </div>
      </div>
      
    </div>
  );
}

export default CanvasSideBar;
