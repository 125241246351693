import React, { useState } from "react";
import FavouriteTable from "./FavouriteTable";
import AllDocuments from "./AllDocuments";
import DeletedTable from "./DeletedTable";
import SecondHeader from "./SecondHeader";

const TabNavigation = ({
  documents,
  setDocuments,
  parentFolderId,
  setParentFolderId,
  folderId,
  setFolderId,
  reloadDocuments,
}) => {
  const [activeTab, setActiveTab] = useState("All Document");
  const [columnFilter, setColumnFilter] = useState({
    all: true,
    sharedWith: true,
    lastUpdated: true,
  });

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="bg-gray-100">
      <div className="flex justify-start items-center bg-white p-2  border-gray-200">
        <button
          className={`font-bold px-4 py-2 ${activeTab === "All Document" ? "text-blue-500" : ""}`}
          onClick={() => handleTabClick("All Document")}
        >
          All Document
          {activeTab === "All Document" && (
            <div className="border-b-2 border-b-blue-500 relative top-4 w-[7rem]"></div>
          )}
        </button>
        <button
          className={`font-bold px-4 py-2 ${activeTab === "Favorite" ? "text-blue-500" : ""}`}
          onClick={() => handleTabClick("Favorite")}
        >
          Favorite
          {activeTab === "Favorite" && (
            <div className="border-b-2 border-b-blue-500 relative top-4 w-[7rem]"></div>
          )}
        </button>
        <button
          className={`font-bold px-4 py-2 ${activeTab === "Deleted" ? "text-blue-500" : ""}`}
          onClick={() => handleTabClick("Deleted")}
        >
          Deleted
          {activeTab === "Deleted" && (
            <div className="border-b-2 border-b-blue-500 relative top-4 w-[7rem]"></div>
          )}
        </button>
      </div>
      <SecondHeader
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
      />
      {activeTab === "All Document" && (
        <AllDocuments
          documents={documents}
          setDocuments={setDocuments}
          parentFolderId={parentFolderId}
          setParentFolderId={setParentFolderId}
          folderId={folderId}
          setFolderId={setFolderId}
          columnFilter={columnFilter}
          reloadDocuments={reloadDocuments}
        />
      )}
      {activeTab === "Favorite" && (
        <FavouriteTable columnFilter={columnFilter} />
      )}
      {activeTab === "Deleted" && <DeletedTable columnFilter={columnFilter} />}
    </div>
  );
};

export default TabNavigation;
