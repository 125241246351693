import React from 'react';
const EmptyDocuments = () => {
  return (
    <div className="bg-gray-100">
      <div className="bg-white rounded-md shadow-md w-full h-[100vh] flex flex-col">
        <div className="border border-b-gray-300"></div>
        <div className="flex-grow flex flex-col items-center justify-center text-center">
          <img src="icons/mainproject.svg" alt="Document Icon" />
          <h2 className="text-xl font-semibold mb-2">No Files and folders are uploaded yet</h2>
          
        </div>
      </div>
    </div>
  );
};

export default EmptyDocuments;