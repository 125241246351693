import React, { createContext, useState, useEffect } from 'react';
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

// Create a context
export const MyContext = createContext();

// Create a provider component
export const MyProvider = ({ children }) => {
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [equipmentsData, setEquipmentsData]=useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [projects, setProjects] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");
  const [projectUpdate,setProjectUpdate]=useState(false);

  useEffect(() => {
    fetchProjects();
    productCategory();
    supplierList();
    equipmentsList()
  }, []);

  const fetchProjects = async () => {
    const raw = JSON.stringify({
      user_id: user?.user_id?.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Corrected template literal
      },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/projects/all-projects`, // Corrected template literal
        requestOptions
      );
      const result = await response.json();
      console.log("API Result:", result);
      if (result.success) {
        setProjects(result.data);
      } 
    } catch (error) {
      console.error(error);
      
    }
  };

  const productCategory = async () => {
    const raw = JSON.stringify({
      user_id: user?.user_id?.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Corrected template literal
      },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/shallow/product-categories/all-product-categories`, // Corrected template literal
        requestOptions
      );
      const result = await response.json();
      console.log("API Result:", result);
      if (result.success) {
        const transformData = result.data.map((data) => ({
          id: data.id,
          name: data.name,
        }));

        setProductCategoryList(transformData);
        console.log(">>>>>>>>>>>>", transformData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const supplierList = async () => {
    const raw = JSON.stringify({
      user_id: user?.user_id?.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Corrected template literal
      },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/suppliers/all-suppliers`, // Corrected template literal
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        const transformData = result.data.map((data) => ({
          id: data.id,
          name: data.name,
        }));

        setSupplierData(transformData);
      }
    } catch (error) {
      console.error(error);
     
    }
  };

  const equipmentsList = async () => {

    const raw = JSON.stringify({
      user_id: user?.user_id?.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    },
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${apiUrl}/app/panel/shallow/equipments/all-equipments`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {

        const transformData = result.data.map((data) => ({
          id: data.id,
          name: data.name
        }));
        

        setEquipmentsData(transformData);

      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch Equipments");
    } 
  };

  return (
    <MyContext.Provider value={{ projects, setDataUpdated, dataUpdated, productCategoryList, supplierData  ,equipmentsData ,setProjectUpdate ,projectUpdate}}>
      {children}
    </MyContext.Provider>
  );
};
