import React, { useState } from "react";

const Pagination = ({ totalItems, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`px-3 py-1 rounded-md ${
              currentPage === i
                ? "bg-primary text-white"
                : "bg-white border border-gray-300 text-gray-800"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // Add first two pages
      for (let i = 1; i <= 2; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`px-3 py-1 rounded-md ${
              currentPage === i
                ? "bg-primary text-white"
                : "bg-white border border-gray-300 text-gray-800"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      // Add ellipsis if current page is beyond the third page
      if (currentPage > 3) {
        pageNumbers.push(<span key="ellipsis1">...</span>);
      }

      // Add current page if it's not one of the first two pages or last two pages
      if (currentPage > 2 && currentPage < totalPages - 1) {
        pageNumbers.push(
          <button
            key={currentPage}
            className={`px-3 py-1 rounded-md bg-primary text-white`}
            onClick={() => handlePageChange(currentPage)}
          >
            {currentPage}
          </button>
        );
      }

      // Add ellipsis if current page is before the second last page
      if (currentPage < totalPages - 2) {
        pageNumbers.push(<span key="ellipsis2">...</span>);
      }

      // Add last two pages
      for (let i = totalPages - 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`px-3 py-1 rounded-md ${
              currentPage === i
                ? "bg-primary text-white"
                : "bg-white border border-gray-300 text-gray-800"
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="flex justify-end mt-4 gap-2">
      <div className="border border-gray-300 rounded grayscale rotate-90">
        <button
          className="px-3 py-2 rounded-md bg-white text-gray-800"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <img src="icons/dropdown.svg" alt="Previous" className="w-4" />
        </button>
      </div>
      {renderPageNumbers()}
      <div className="border border-gray-300 rounded grayscale -rotate-90">
        <button
          className="px-3 py-2 rounded-md bg-white text-gray-800"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <img src="icons/dropdown.svg" alt="Next" className="w-4" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
