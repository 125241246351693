import React, { useState } from "react";
import "../App.css";
import { Link } from "react-router-dom";

export const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <header className="header" id="home">
      <div className="w-7 flex space-between">
        <a href="#" className="logo">
          <img src="icons/agabLogo.png" alt="Demo Logo" />
        </a>
        <span
          className="material-symbols-outlined"
          id="openSidebar"
          onClick={toggleSidebar}
        >
          menu
        </span>
        <ul className="menu flex space-between max-sm:hidden ">
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
          <li>
            <Link to="/login">Login</Link>
          </li>
          <li>
            <Link to="/signup">Signup</Link>
          </li>
        </ul>
      </div>
      <div id="mobile-menu" className={sidebarOpen ? "" : "hidden"}>
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#projects">Projects</a>
          </li>
          <li>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
          <li>
            <Link to="/login">Login</Link>
          </li>
          <li>
            <Link to="/signup">Signup</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};
