import React, { useState } from "react";
import InputField from "../components/micro_components.js/InputField";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;
function LoginPage() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/account/login`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        if (result?.error) {
          toast.error(result.error);
        } else {
          console.log(result); // Handle successful login response
          // Optionally redirect or perform actions upon successful login
          toast.success("Login Successful!");
          localStorage.setItem("token", result.data.access_token);
          localStorage.setItem("user", JSON.stringify(result.data.user));
          localStorage.setItem("expire", result.data.expire);
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle error (e.g., display error message to the user)
        toast.error("Error Logging in!" + error);
      });
  };

  return (
    <div className="page-container flex">
      <form onSubmit={handleSubmit} className="form login">
        <h1>Sign In</h1>
        <div className="stylish-line"></div>
        <h4>Welcome back</h4>

        <InputField
          label="Email"
          name="email"
          type="email"
          placeholder="Enter your email..."
          value={formData.email}
          onChange={handleChange}
        />
        <InputField
          label="Password"
          name="password"
          type="password"
          placeholder="Enter your password..."
          value={formData.password}
          onChange={handleChange}
        />

        <div className="input-cn">
          <button className="btn btn-primary" type="submit" name="login">
            Login
          </button>
        </div>

        <div className="formp-holder">
          <Link to="/signup">Sign Up</Link>
          <Link to="/forgotPassword">Forgot Password</Link>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
