import React, { useState } from "react";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

export const EquipmentEntry = ({
  setEquipmentSidebarOpen,
  estimateID,
  data,
  setData,
}) => {
  const [equipmentRate, setEquipmentRate] = useState("");
  const [equipmentAmount, setEquipmentAmount] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if (!user || !token) {
      console.error("User or token not found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=96dc75ad0e40105a7d9914e846bae5f6");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      estimate_id: estimateID, // Hardcoded estimate_id
      equipment_rate: equipmentRate,
      equipment_amount: equipmentAmount,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${apiUrl}/app/panel/project-estimations/estimates/equipment/add-equipment-estimate`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          toast.success(result.message);
          const updatedData = data.map((item) => {
            if (item.equipment.estimate_id === estimateID) {
              return {
                ...item,
                equipment: {
                  ...item.equipment,
                  equipment_rate: `$${equipmentRate}`,
                  equipment_amount: equipmentAmount,
                },
              };
            }
            return item;
          });

          setData(updatedData);
          setEquipmentSidebarOpen(false);
        } else {
          toast.error(result.error);
        }
        console.log(result);
      })
      .catch((error) => {
        // setMatrialSidebarOpen(false);
        toast.error("Error adding material estimate");
        console.error(error);
        setEquipmentSidebarOpen(false);
      });
  };

  return (
    <div id="main" className="flex">
      <div className="bg-white shadow-md h-[100vh] rounded-md p-4 flex-1">
        <div id="header" className="pb-7 flex justify-between items-center">
          <div className="flex items-center gap-1">
            <img src="icons/entry.svg" alt="Entry Icon" />
            <span className="text-lg font-bold mt-2">Equipment Entry</span>
          </div>
          <div className="flex gap-2">
            <img
              src="icons/menu.svg"
              className="cursor-pointer"
              alt="Menu Icon"
            />
            <img
              src="icons/cross.svg"
              className="cursor-pointer"
              alt="Cross Icon"
              onClick={() => setEquipmentSidebarOpen(false)}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="equipmentRate"
              className="block text-sm font-medium text-gray-700"
            >
              Equipment Rate
            </label>
            <input
              type="number"
              id="equipmentRate"
              value={equipmentRate}
              onChange={(e) => setEquipmentRate(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="equipmentAmount"
              className="block text-sm font-medium text-gray-700"
            >
              Equipment Amount
            </label>
            <input
              type="number"
              id="equipmentAmount"
              value={equipmentAmount}
              onChange={(e) => setEquipmentAmount(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              required
            />
          </div>
          <div className="flex justify-between mt-20">
            <div className="mt-4">
              <p className="text-sm font-medium text-gray-700">Client Price</p>
              <p className="text-lg font-bold text-gray-900">$2,395.30</p>
            </div>
            <button
              type="submit"
              className="bg-primary mt-2 text-white px-4 rounded focus:outline-none focus:shadow-outline flex items-center gap-1 h-[48px]"
            >
              <img src="icons/save.svg" alt="Save Icon" />
              <span className="mt-1">Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
