import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

const FavouriteTable = () => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=b8446925257e4bbe56440fd1f36bbae5");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${apiUrl}/app/document-manager/list-starred-folders`, requestOptions)
      .then((response) => response.json()) // Assuming the response is in JSON format
      .then((result) => {
        const data = JSON.parse(result.data);
        console.log(data);
        // debugger;
        setDocuments(data); // Adjust this based on the actual response structure
      })
      .catch((error) => console.error(error));
  }, []);

  const handleStarClick = (docId, isStarred) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "PHPSESSID=564881d2041006cac4fee2b541ac8df1");

    const raw = JSON.stringify({
      user_id: user.user_id.toString(),
      id: docId,
      type: "folder", // Adjust this based on your requirement
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const apiEndpoint = isStarred
      ? `${apiUrl}/app/document-manager/actions/unstar`
      : `${apiUrl}/app/document-manager/actions/star`;

    fetch(apiEndpoint, requestOptions)
      .then((response) => response.json()) // Assuming the response is in JSON format
      .then((result) => {
        console.log(result);
        toast.success(result.message);
        // Optionally update the document's starred state here
        setDocuments((prevDocuments) =>
          prevDocuments.map((doc) =>
            doc.folder_id === docId
              ? { ...doc, starred: doc.starred === 1 ? 0 : 1 }
              : doc
          )
        );
      })
      .catch((error) => {
        toast.error("An error occurred. Please try again.");
        console.error(error);
      });
  };

  return (
    <div className="p-4 bg-white">
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left p-2 border-b border-gray-300">Name</th>
            <th className="text-left p-2 border-b border-gray-300">
              Last Updated
            </th>
            <th className="text-left p-2 border-b border-gray-300"></th>
          </tr>
        </thead>
        <tbody>
          {documents.map((doc, index) => (
            <tr key={index} className="border-b border-gray-300">
              <td className="p-2">
                <div className="flex items-center">
                  <div className="flex gap-2 mt-1">
                    <img
                      src={
                        doc.starred === 1
                          ? "icons/yellowstar.svg"
                          : "icons/star.svg"
                      }
                      alt={doc.starred === 1 ? "Yellow Star" : "Folder Icon"}
                      width={25}
                      className="cursor-pointer"
                      onClick={() =>
                        handleStarClick(doc.folder_id, doc.starred === 1)
                      }
                    />
                    <img src="icons/folder.svg" alt="Folder Icon" width={25} />
                    <span className="mt-1">{doc.name}</span>
                    <img
                      src="icons/sysfolder.svg"
                      alt="Sys Folder Icon"
                      width={25}
                    />
                    <span className="text-gray-500">
                      {doc.folder_name || doc.file_name}
                    </span>
                  </div>
                </div>
              </td>
              <td className="p-2">{doc.sharedWith}</td>
              <td className="p-2">{doc.lastUpdated}</td>
              <td className="p-2">
                <img
                  src="icons/menu.svg"
                  alt="Menu Icon"
                  className="cursor-pointer"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FavouriteTable;
