import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import EstimateTable from "../../components/micro_components.js/EstimateTable";
import { Header } from "../../components/Estimate/Header";
import SecondHeader from "../../components/Estimate/SecondHeader";
import Pagination from "../../components/Estimate/Pagination";
import OffcanvasSidebar from "../../components/Estimate/OffcanvasSideBar";
import { Loader } from "../../components/micro_components.js/loader";
import { arrayMove } from "@dnd-kit/sortable";
import { MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
const apiUrl = process.env.REACT_APP_API_URL;

function Estimate() {
  const [expandedStages, setExpandedStages] = useState(false);
  const [data, setData] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const [estimationId, setEstimationId] = useState(null);
  const [estimateID, setEstimateID] = useState(null);
  const [materialsidebarOpen, setMatrialSidebarOpen] = useState(false);
  const [laboursidebarOpen, setLabourSidebarOpen] = useState(false);
  const [equipmentsidebarOpen, setEquipmentSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataUpdate, setDataUpdate] = useState(false);
  const [allExpandedRows, setAllExpandedRows] = useState(false);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const [columnFilter, setColumnFilter] = useState({
    all: true,
    stage: true,
    work: true,
    quantity: true,
    type: true,
    unit_cost: true,
    total_cost: true,
  });
  const [subcontractorsidebarOpen, setSubcontractorSidebarOpen] =
    useState(false);
  const [columnsOrder, setColumnsOrder] = useState([
    "stage",
    "work",
    "quantity",
    "type",
    "unit_cost",
    "total_cost",
  ]);
  const [tableHeaders, setTableHeaders] = useState([
    { name: "Stage", value: "stage", visible: true },
    { name: "Type", value: "type", visible: true },
    { name: "Work", value: "work", visible: true },
    { name: "Quantity", value: "quantity", visible: true },
    {
      name: "Unit Cost",
      value: [
        "material_rate",
        "labour_rate",
        "equipment_rate",
        "subcontractor_rate",
      ],
      visible: true,
    },
    { name: "TotalCost", value: "attachments", visible: true },
  ]);
  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Authorization", "YOUR_AUTHORIZATION_TOKEN");
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        user_id: "11",
        project: "4",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${apiUrl}/app/panel/project-estimations/view-estimation`,
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        const data = JSON.parse(result.data);
        setData(data);
        setTotalItems(data.length);
        setEstimationId(data[0]?.material?.estimation_id);
        setLoading(false);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, [dataUpdate]);
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setTableHeaders((headers) => {
        const oldIndex = headers.findIndex(
          (header) => header.value === active.id
        );
        const newIndex = headers.findIndex(
          (header) => header.value === over.id
        );
        return arrayMove(headers, oldIndex, newIndex);
      });
    }
  };
  const handleCheckBox = (e, value) => {
    e.stopPropagation();

    setTableHeaders((prevHeaders) =>
      prevHeaders?.map((header) =>
        header.value === value
          ? { ...header, visible: !header.visible }
          : header
      )
    );
  };

  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none">
          <Header
            setMatrialSidebarOpen={setMatrialSidebarOpen}
            estimationId={estimationId}
          />
          <SecondHeader
            tableHeaders={tableHeaders}
            sensors={sensors}
            handleDragEnd={handleDragEnd}
            handleCheckBox={handleCheckBox}
            setExpandedStages={setExpandedStages}
            expandedStages={expandedStages}
            data={data}
            setEstimationId={setEstimationId}
            setTotalItems={setTotalItems}
            setData={setData}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            columnsOrder={columnsOrder}
            setColumnsOrder={setColumnsOrder}
            setAllExpandedRows={setAllExpandedRows}
            allExpandedRows={allExpandedRows}
          />
          {loading ? (
            <Loader />
          ) : (
            <>
              <EstimateTable
                tableHeaders={tableHeaders}
                sensors={sensors}
                handleDragEnd={handleDragEnd}
                handleCheckBox={handleCheckBox}
                setExpandedStages={setExpandedStages}
                expandedStages={expandedStages}
                data={data}
                setEquipmentSidebarOpen={setEquipmentSidebarOpen}
                setLabourSidebarOpen={setLabourSidebarOpen}
                setSubcontractorSidebarOpen={setSubcontractorSidebarOpen}
                setEstimationId={setEstimationId}
                setEstimateID={setEstimateID}
                columnFilter={columnFilter}
                setDataUpdate={setDataUpdate}
                dataUpdate={dataUpdate}
                columnsOrder={columnsOrder}
                setAllExpandedRows={setAllExpandedRows} // Pass the columns order here
              />
              <Pagination totalItems={totalItems} itemsPerPage={itemsPerPage} />
            </>
          )}
          <OffcanvasSidebar
            data={data}
            setData={setData}
            materialsidebarOpen={materialsidebarOpen}
            setMatrialSidebarOpen={setMatrialSidebarOpen}
            equipmentsidebarOpen={equipmentsidebarOpen}
            setEquipmentSidebarOpen={setEquipmentSidebarOpen}
            laboursidebarOpen={laboursidebarOpen}
            setLabourSidebarOpen={setLabourSidebarOpen}
            subcontractorsidebarOpen={subcontractorsidebarOpen}
            setSubcontractorSidebarOpen={setSubcontractorSidebarOpen}
            estimationId={estimationId}
            estimateID={estimateID}
          />
        </div>
      </div>
    </div>
  );
}

export default Estimate;
