import React, {useState } from "react";
import Sidebar from "../../components/Sidebar";
import { SignedHeader } from "../../components/SignedHeader";
import AllWbs from "../../components/Wbs/AllWbs";

function Wbs() {
  return (
    <div>
      <SignedHeader />
      <div
        className="page-container flex"
        style={{ alignItems: "start", justifyContent: "start" }}
      >
        <Sidebar />
        <div className="w-[100%] flex flex-col p-3 justify-none overflow-x-hidden">
        <AllWbs/>
        </div>
      </div>
    </div>
  );
}

export default Wbs;