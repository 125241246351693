import React, { useState } from "react";
import ReactDOM from "react-dom";


const ImportModal = ({ isOpen,onClose,handleFileChange,handleTitleChange,handleImport,title }) => {

  if (!isOpen) return null;
  
  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="w-[40%] bg-white p-4 rounded shadow-lg">
        <h2 className="text-lg font-bold mb-4">Import File</h2>
        <input
          type="text"
          value={title}
          onChange={handleTitleChange}
          placeholder="Title"
          className="w-full p-2 mb-2 border border-gray-300 rounded"
        />
        <input
          type="file"
          onChange={handleFileChange}
          className="w-full mb-4"
        />
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white p-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleImport}
            className="bg-primary text-white p-2 rounded"
          >
            Import
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ImportModal;
